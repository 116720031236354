import classNames from 'classnames';
import { Formik } from 'formik';
import { TFunction } from 'i18next';
import * as React from 'react';
import FormikField from '../../../../common/utils/FormikField';

import { BaseStatefulComponent } from '../../../../components/BaseStatefulComponent';
import { Button, ButtonType } from '../../../../components/Buttons/Button';
import { IconSize, ICONS } from '../../../../components/Icon/Icon';
import RequiredFieldsMessage from '../../../../components/RequiredFieldsMessage/RequiredFieldsMessage';
import { TextInputType } from '../../../../components/TextInput/TextInput';
import { TypeaheadAsyncField, TypeaheadItem } from '../../../../components/Typeahead/TypeaheadAsync';
import { GroupMemberApproverDTO } from '../../../../services/types/ApiTypes';

import './AddApprover.scss';
import { createDataId, DataId } from '../../../../common/utils/dataId';

export interface AddApproverProps {
    className?: string;
    onFormClose?: () => void;
    isFormOpen?: boolean;
    potentialApprovers: (searchString: string) => Promise<Array<TypeaheadItem<GroupMemberApproverDTO>>>;
    t: TFunction;
    onAddApprover: (approver: GroupMemberApproverDTO) => void;
    dataId: DataId;
}

export interface AddApproverState {
    isFormOpen: boolean; // might use it for animations
}

interface AddApproverFields {
    approver: TypeaheadItem<GroupMemberApproverDTO>;
}

export class AddApprover extends BaseStatefulComponent<AddApproverProps, AddApproverState> {
    private initialValues: AddApproverFields = {
        approver: null,
    };

    constructor(props: AddApproverProps) {
        super(props);
        this.state = {
            isFormOpen: props.isFormOpen,
        };
    }

    closeForm = () => {
        if (this.state.isFormOpen) {
            this.setState({
                isFormOpen: false,
            });
            this.props.onFormClose();
        }
    };

    componentDidUpdate(prevProps: Readonly<AddApproverProps>) {
        if (this.props.isFormOpen !== prevProps.isFormOpen) {
            this.setState({
                isFormOpen: this.props.isFormOpen,
            });
        }
    }

    render() {
        const { t, isFormOpen, className, onAddApprover, potentialApprovers, dataId } = this.props;
        const classes = classNames('add-approver', className);
        if (!isFormOpen) {
            return null;
        }
        return (
            <div className={classes} data-id={dataId || 'addApprover'}>
                <h2 className="add-approver__title">{t('view.PurchaseOrders.Workflow.AddApprover.Title')}</h2>
                <Formik
                    initialValues={this.initialValues}
                    onSubmit={(vals) => {
                        onAddApprover(vals.approver.value);
                        this.closeForm();
                    }}
                    enableReinitialize={true}
                    validateOnChange={false}
                    validateOnBlur={true}
                >
                    {(subformik) => (
                        <div>
                            <FormikField
                                component={TypeaheadAsyncField}
                                wrapperClass="add-approver__name"
                                searchOnFocus={true}
                                loadItems={potentialApprovers}
                                validate={(value: any) => {
                                    return value ? '' : t('views.global.FieldIsRequired');
                                }}
                                name={'approver'}
                                inputProps={{
                                    type: TextInputType.BORDERED,
                                    label: t('view.PurchaseOrders.Workflow.AddApprover.Name'),
                                    autofocus: true,
                                }}
                                dataId={createDataId(dataId || 'addApprover', 'field')}
                            />
                            <RequiredFieldsMessage />
                            <hr className="add-approver__separator" />
                            <Button className="add-approver__button" dataId={createDataId(dataId || 'addApprover', 'add')} onClick={() => subformik.submitForm()}>
                                {t('views.global.Add')}
                            </Button>
                        </div>
                    )}
                </Formik>

                <Button
                    dataId={createDataId(dataId, 'addApprover', 'close')}
                    className={'add-approver__close'}
                    iconSize={IconSize.XS}
                    buttonType={ButtonType.ICON}
                    icon={ICONS.CLOSE}
                    onClick={this.closeForm}
                />
            </div>
        );
    }
}
