import React from 'react';
import classNames from 'classnames';

import { createDataId } from '../../common/utils/dataId';
import { useClasses } from './ButtonHelpers';
import { ButtonProps } from './ButtonTypes';

import './ButtonView.scss';

export const ButtonView: React.FC<React.PropsWithChildren<ButtonProps>> = (props) => {
    const {
        children,
        fullWidth,
        size = 'md',
        variant = 'contained',
        color = 'primary',
        className,
        iconButton,
        startIcon,
        endIcon,
        dataId,
        disableInteractions,
        onClick,
        justify = 'center',
        ...htmlButtonProps
    } = props;
    const classes = useClasses({ size, variant, color, justify });

    const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        if (disableInteractions || props.disabled || !onClick) {
            return;
        }
        onClick(e);
    };

    return (
        <button
            data-id={dataId}
            tabIndex={disableInteractions && -1}
            className={classNames(
                classes.root,
                classes.size,
                classes.color,
                {
                    [classes.iconButton]: iconButton,
                    [classes.fullWidth]: fullWidth,
                    [classes.disabled]: props.disabled,
                    [classes.startIcon]: Boolean(!iconButton && startIcon),
                    [classes.endIcon]: Boolean(!iconButton && endIcon),
                    [classes.disableInteractions]: disableInteractions,
                },
                className,
            )}
            onClick={handleClick}
            {...htmlButtonProps}
        >
            {startIcon && !iconButton && (
                <span className={classes.startIcon} data-id={createDataId(dataId, 'startIcon')}>
                    {startIcon}
                </span>
            )}
            <span className={classes.content}>{children}</span>
            {endIcon && !iconButton && (
                <span className={classes.endIcon} data-id={createDataId(dataId, 'endIcon')}>
                    {endIcon}
                </span>
            )}
        </button>
    );
};
