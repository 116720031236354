import { AutoTransactionMatchType, ExtensionField } from '../../../../../services/types/ApiTypes';

export const AmountMatchTypes = [
    AutoTransactionMatchType.Equal,
    AutoTransactionMatchType.NotEqual,
    AutoTransactionMatchType.Greater,
    AutoTransactionMatchType.Less,
    AutoTransactionMatchType.GreaterEqual,
    AutoTransactionMatchType.LessEqual,
];

export const RequiredTextFieldMatchTypes = [AutoTransactionMatchType.Contains, AutoTransactionMatchType.Equal];

export const OptionalTextFieldMatchTypes = [AutoTransactionMatchType.Contains, AutoTransactionMatchType.NotEmpty, AutoTransactionMatchType.Empty, AutoTransactionMatchType.Equal];

export const getTriggerMatchTypes = (extensionField: number): AutoTransactionMatchType[] => {
    switch (extensionField) {
        case ExtensionField.GrandTotal:
        case ExtensionField.NETAmount:
            return AmountMatchTypes;
        case ExtensionField.InvoiceType:
            return [AutoTransactionMatchType.Equal, AutoTransactionMatchType.NotEqual];
        case ExtensionField.FactorContractNumber:
        case ExtensionField.ContractNumber:
        case ExtensionField.DocumentName:
        case ExtensionField.InvoiceNumber:
        case ExtensionField.ContentCode:
        case ExtensionField.ContentText:
        case ExtensionField.UniqueCode:
        case ExtensionField.DepartmentId:
        case ExtensionField.ReferenceNumber:
            return OptionalTextFieldMatchTypes;
        case ExtensionField.ExtensionId:
        case ExtensionField.ExtensionName:
        case ExtensionField.ExtensionContent:
        case ExtensionField.Description:
            return RequiredTextFieldMatchTypes;
        case ExtensionField.Company:
        case ExtensionField.GLN:
        case ExtensionField.ContactPerson:
        case ExtensionField.BeneficiaryName:
            return [AutoTransactionMatchType.Equal];
        default:
            return [];
    }
};

export function getTriggerDefaultMatchType(id: ExtensionField) {
    return id === ExtensionField.Company || id === ExtensionField.ContactPerson || id === ExtensionField.BeneficiaryName || id === ExtensionField.GLN || id === ExtensionField.InvoiceType
        ? AutoTransactionMatchType.Equal
        : null;
}
