import React from 'react';
import { useMenuLockConfirmation } from './useMenuLockConfirmation';
import { WithMenuLockConfirmationProps } from './WithMenuLockConfirmationTypes';

export const WithMenuLockConfirmation: React.FC<WithMenuLockConfirmationProps> = (props) => {
    const { formik, children } = props;
    const isTouched = Boolean(Object.keys(formik.touched).length);

    useMenuLockConfirmation(isTouched);

    return <>{children}</>;
};
