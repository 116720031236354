import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import Assignee, { AssigneeStatus } from '../../../../components/Assignee/Assignee';
import { BaseStatefulComponent } from '../../../../components/BaseStatefulComponent';
import { TypeaheadItem } from '../../../../components/Typeahead/TypeaheadAsync';
import { PurchaseOrderTaskAction, PurchaseOrderTaskDTO, PurchaseOrderTaskStatus, GroupMemberApproverDTO } from '../../../../services/types/ApiTypes';
import { User } from '../../../../services/ApiClient';

import { AddApprover } from './AddApprover';
import { createDataId, DataId } from '../../../../common/utils/dataId';
import withSuspense from '../../../../common/hocs/withSuspense';

export interface Props {
    taskItems: PurchaseOrderTaskDTO[];
    currentUser: User;
    onConfirm: (comment: string) => void;
    onReject: (comment: string) => void;
    confirmErrorText?: string;
    className?: string;
    loading: boolean;
    potentialApprovers: (searchString: string) => Promise<Array<TypeaheadItem<GroupMemberApproverDTO>>>;
    orderNo: number;
    onAddApprover: (approver: GroupMemberApproverDTO, ornerNo: number) => void;
    isApproverAddingAvailable: boolean;
    dataId: DataId;
}

interface TaskGroupState {
    isAddApproverBeforeOpen: boolean;
    isAddApproverAfterOpen: boolean;
}

export type TaskGroupProps = Props & WithTranslation;

class TaskGroup extends BaseStatefulComponent<TaskGroupProps, TaskGroupState> {
    constructor(props: TaskGroupProps) {
        super(props);
        this.state = {
            isAddApproverAfterOpen: false,
            isAddApproverBeforeOpen: false,
        };
    }

    getCreatedTasks() {
        return this.props.taskItems.filter((task) => task.TaskAction === PurchaseOrderTaskAction.Created);
    }

    getConfirmedTasks() {
        return this.props.taskItems.filter((task) => task.TaskAction === PurchaseOrderTaskAction.Confirmed);
    }

    getRejectedTasks() {
        return this.props.taskItems.filter((task) => task.TaskAction === PurchaseOrderTaskAction.Rejected);
    }

    getCurrentTasks() {
        return this.props.taskItems.filter((task) => task.TaskStatus === PurchaseOrderTaskStatus.Current);
    }

    getPendingTasks() {
        return this.props.taskItems.filter((task) => task.TaskStatus === PurchaseOrderTaskStatus.None);
    }

    getAssigneeName(task: PurchaseOrderTaskDTO) {
        let assigneeName = `${task.FirstName} ${task.LastName}`;
        if (task.ToSubstituteName) {
            assigneeName = `${assigneeName} (${task.ToSubstituteName})`;
        }
        return assigneeName;
    }

    getGroupedAssigneeName(tasks: PurchaseOrderTaskDTO[]) {
        return tasks.map((task) => this.getAssigneeName(task));
    }

    getCurrentTaskStatus(task: PurchaseOrderTaskDTO) {
        const isCurrentUser = task.GroupMemberId === this.props.currentUser.GroupMemberId || task.ToSubstituteId === this.props.currentUser.GroupMemberId;
        return isCurrentUser ? AssigneeStatus.WAITING_ACTION : AssigneeStatus.PENDING;
    }

    handleAddApproverBefore = () => {
        this.setState({
            isAddApproverBeforeOpen: true,
            isAddApproverAfterOpen: false,
        });
    };

    handleAddApproverAfter = () => {
        this.setState({
            isAddApproverAfterOpen: true,
            isAddApproverBeforeOpen: false,
        });
    };

    closeAddApprover = () => {
        this.setState({
            isAddApproverBeforeOpen: false,
            isAddApproverAfterOpen: false,
        });
    };

    handleAddApproverBeforeSubmit = (approver: GroupMemberApproverDTO) => {
        this.props.onAddApprover(approver, this.props.orderNo);
    };

    handleAddApproverAfterSubmit = (approver: GroupMemberApproverDTO) => {
        this.props.onAddApprover(approver, this.props.orderNo + 1);
    };

    render() {
        const { t, dataId } = this.props;
        const pendingTasks = this.getPendingTasks();
        return (
            <div className={this.props.className}>
                {this.props.isApproverAddingAvailable && (
                    <AddApprover
                        onAddApprover={this.handleAddApproverBeforeSubmit}
                        isFormOpen={this.state.isAddApproverBeforeOpen}
                        onFormClose={this.closeAddApprover}
                        t={t}
                        potentialApprovers={this.props.potentialApprovers}
                        dataId={createDataId(dataId || 'taskGroup', 'addApprover')}
                    />
                )}

                {this.getCreatedTasks().map((task, i) => (
                    <Assignee
                        key={i}
                        onReject={this.props.onReject}
                        onConfirm={this.props.onConfirm}
                        name={this.getAssigneeName(task)}
                        date={task.ModifiedDate}
                        status={AssigneeStatus.ASSIGNER}
                        comment={task.Comment}
                        dataId={createDataId(dataId || 'taskGroup', 'created', i)}
                    />
                ))}
                {this.getConfirmedTasks().map((task, i) => (
                    <Assignee
                        key={task.Id}
                        onReject={this.props.onReject}
                        onConfirm={this.props.onConfirm}
                        name={this.getAssigneeName(task)}
                        date={task.ModifiedDate}
                        status={AssigneeStatus.APPROVED}
                        comment={task.Comment}
                        dataId={createDataId(dataId || 'taskGroup', 'confirmed', i)}
                    />
                ))}
                {this.getRejectedTasks().map((task, i) => (
                    <Assignee
                        key={task.Id}
                        onReject={this.props.onReject}
                        onConfirm={this.props.onConfirm}
                        name={this.getAssigneeName(task)}
                        date={task.ModifiedDate}
                        status={AssigneeStatus.REJECTED}
                        comment={task.Comment}
                        dataId={createDataId(dataId || 'taskGroup', 'rejected', i)}
                    />
                ))}
                {this.getCurrentTasks().map((task, i) => (
                    <Assignee
                        key={task.Id}
                        onReject={this.props.onReject}
                        onConfirm={this.props.onConfirm}
                        name={this.getAssigneeName(task)}
                        date={task.ModifiedDate}
                        status={this.getCurrentTaskStatus(task)}
                        comment={task.Comment}
                        commentPlaceholder={t('view.PurchaseOrders.Workflow.AddOrRejectComment')}
                        confirmText={t('view.PurchaseOrders.Workflow.Confirm')}
                        rejectText={t('view.PurchaseOrders.Workflow.Reject')}
                        noCommentText={t('view.PurchaseOrders.Workflow.RejectCommentRequired')}
                        onAddApproverBefore={this.handleAddApproverBefore}
                        confirmErrorText={this.props.confirmErrorText}
                        onAddApproverAfter={this.handleAddApproverAfter}
                        isApproverAddingAvailable={this.props.isApproverAddingAvailable}
                        addBeforeText={t('view.PurchaseOrders.Workflow.AddApproverBefore')}
                        addAfterText={t('view.PurchaseOrders.Workflow.AddApproverAfter')}
                        dataId={createDataId(dataId || 'taskGroup', 'current', i)}
                    />
                ))}
                {/*display pending approvers as one item*/}
                {pendingTasks.length > 0 && (
                    <Assignee
                        key="pending-tasks"
                        onReject={this.props.onReject}
                        onConfirm={this.props.onConfirm}
                        name={this.getGroupedAssigneeName(pendingTasks)}
                        status={AssigneeStatus.PENDING}
                        onAddApproverBefore={this.handleAddApproverBefore}
                        onAddApproverAfter={this.handleAddApproverAfter}
                        isApproverAddingAvailable={this.props.isApproverAddingAvailable}
                        addBeforeText={t('view.PurchaseOrders.Workflow.AddApproverBefore')}
                        addAfterText={t('view.PurchaseOrders.Workflow.AddApproverAfter')}
                        dataId={createDataId(dataId || 'taskGroup', 'pending')}
                    />
                )}
                {this.props.isApproverAddingAvailable && (
                    <AddApprover
                        onAddApprover={this.handleAddApproverAfterSubmit}
                        isFormOpen={this.state.isAddApproverAfterOpen}
                        onFormClose={this.closeAddApprover}
                        t={t}
                        potentialApprovers={this.props.potentialApprovers}
                        dataId={createDataId(dataId || 'taskGroup', 'addApprover')}
                    />
                )}
            </div>
        );
    }
}

export default withSuspense(withTranslation()(TaskGroup));
