import * as React from 'react';

import { addDataId, createDataId } from '../../../../common/utils/dataId';
import { formatNumber } from '../../../../common/utils/formatters';
import { BaseComponent } from '../../../../components/BaseComponent';
import { Button, ButtonType } from '../../../../components/Buttons/Button';
import { DropdownMenu, DropdownMenuItem } from '../../../../components/DropdownMenu/DropdownMenu';
import Icon, { IconSize, ICONS } from '../../../../components/Icon/Icon';

import './UnpostedAmountWarning.scss';

export interface WarningProps {
    amount: number;
    t: any;
    hasAllocations: boolean;
    addAsNew: any;
    addToLargest: any;
    fieldNamePrefix?: string;
}

export enum UnpostedAmountActionType {
    ADD_AS_NEW = 'ADD_AS_NEW',
    ADD_TO_LARGEST = 'ADD_TO_LARGEST',
}

export class UnpostedAmountWarning extends BaseComponent<WarningProps> {
    unpostedAmountActions = {
        [UnpostedAmountActionType.ADD_AS_NEW]: 'component.AutoTransaction.VatRates.Allocations.Unposted.Add.New',
        [UnpostedAmountActionType.ADD_TO_LARGEST]: 'component.AutoTransaction.VatRates.Allocations.Unposted.Add.Largest',
    };
    getUnpostedAmountActionLabel(actionType: UnpostedAmountActionType, t: any) {
        return t(this.unpostedAmountActions[actionType]);
    }

    render() {
        const { amount, t, hasAllocations, addAsNew, addToLargest, fieldNamePrefix } = this.props;
        return (
            <div className={'unposted-warning'}>
                <div className={'unposted-warning__icon'}>
                    <Icon name={ICONS.INFO} size={IconSize.SM} />
                </div>
                <span className={'unposted-warning__text'}>{t('component.AutoTransaction.Confirmation.UnPostedAmount')}</span>
                <span className={'unposted-warning__amount'}>{amount < 0.01 ? amount : formatNumber(amount)}</span>
                <DropdownMenu
                    dataId={addDataId(`${fieldNamePrefix}.unpostedAmountOptionsDropdown`, '')}
                    items={Object.keys(this.unpostedAmountActions).map((key: UnpostedAmountActionType, index) => {
                        return (
                            <DropdownMenuItem
                                key={index}
                                isDisabled={!hasAllocations && key === UnpostedAmountActionType.ADD_TO_LARGEST}
                                onClick={() => {
                                    if (key === UnpostedAmountActionType.ADD_TO_LARGEST) {
                                        addToLargest(amount);
                                    } else {
                                        addAsNew(amount);
                                    }
                                }}
                                dataId={createDataId('option', index)}
                            >
                                {this.getUnpostedAmountActionLabel(key, t)}
                            </DropdownMenuItem>
                        );
                    })}
                >
                    <Button
                        dataId={addDataId(`${fieldNamePrefix}.unpostedAmountOptionsDropdown`, '.trigger')}
                        className={'unposted-warning__actions'}
                        buttonType={ButtonType.ICON_SQUARE}
                        icon={ICONS.DOT_MENU}
                        iconSize={IconSize.SM}
                        iconRotation={90}
                    />
                </DropdownMenu>
            </div>
        );
    }
}

export default UnpostedAmountWarning;
