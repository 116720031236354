import React from 'react';
import ConfirmationTooltip from '../../../../components/ConfirmationTooltip/ConfirmationTooltip';
import { ConditionalConfirmationTooltipProps } from './ConditionalConfirmationTooltipTypes';

export const ConditionalConfirmationTooltip: React.FC<React.PropsWithChildren<ConditionalConfirmationTooltipProps>> = (props) => {
    const { children, shouldShowConfirmation, ...confirmationTooltipProps } = props;
    if (!shouldShowConfirmation) {
        return <>{children}</>;
    }
    return <ConfirmationTooltip {...confirmationTooltipProps}>{children}</ConfirmationTooltip>;
};
