import classNames from 'classnames';
import { size } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { createDataId } from '../../../../../../common/utils/dataId';
import { Button, ButtonIconPlacement, ButtonType } from '../../../../../../components/Buttons/Button';
import { ICONS } from '../../../../../../components/Icon/Icon';
import { Props as TagSelectProps, TagSelect, TagSelectType } from '../../../../../../components/TagSelect/TagSelect';

import './FilterItemSelect.scss';

export type FilterItemValue = { text: string; value: number };

export type FilterItemSelectProps = Pick<TagSelectProps<any>, 'dataId' | 'values' | 'loadItems' | 'items'> & {
    label: string;
    columnName: string;
    columnExtraName?: string;
    onChange: (params: { columnName: string; items: FilterItemValue[]; columnExtraName?: string }) => void;
};

export const FilterItemSelect: React.FC<FilterItemSelectProps> = (props) => {
    const { t } = useTranslation();

    const handleChange = (items: FilterItemValue[]) => {
        props.onChange({ items, columnName: props.columnName, columnExtraName: props.columnExtraName });
    };

    return (
        <TagSelect
            items={props.items}
            clearText={t('component.TableFilterItemSelect.ClearSelection')}
            dataId={props.dataId}
            type={TagSelectType.DEFAULT}
            values={props.values}
            loadItems={props.loadItems}
            onChange={handleChange}
            placeholder={t('view.global.filters.any')}
            searchOnFocus
            hideSelectedItemsSection
            render={(renderProps) => {
                return (
                    <Button
                        itemRef={(renderProps.ref as unknown) as string}
                        onClick={renderProps.onClick}
                        buttonType={ButtonType.ICON_TEXT}
                        icon={ICONS.CHEVRON_DOWN_24}
                        className={classNames('po-filter-item', {
                            'po-filter-item__open': renderProps.isOpen,
                            'po-filter-item__active': Boolean(renderProps.selectedItems.length),
                            'po-filter-item__active__open': Boolean(renderProps.selectedItems.length) && renderProps.isOpen,
                        })}
                        iconPlacement={ButtonIconPlacement.RIGHT}
                        dataId={createDataId(props.dataId, 'button')}
                    >
                        {size(renderProps.selectedItems) > 0 && <span className="po-filter-item__counter">{renderProps.selectedItems.length}</span>}
                        {props.label}
                    </Button>
                );
            }}
        />
    );
};
