import { Suspense } from 'react';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { toast } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';

import { fixConsole } from './common/utils/consoleFix';
import './i18n';
import './scss/styles.scss';
import storeAndPersistor, { routerHistory } from './storeConfig';
import Root, { AppProps } from './Root';

fixConsole();

const rootElement = document.getElementById('root');
/*
    In dev environment make App hot reloadable
 */
function initDev() {
    const AppContainer = require('react-hot-loader').AppContainer;
    const NextRoot: React.ComponentType<AppProps> = require('./Root').default;

    ReactDOM.render(
        <AppContainer warnings={false}>
            <Provider store={storeAndPersistor.store}>
                <PersistGate persistor={storeAndPersistor.persistor}>
                    <NextRoot history={routerHistory} />
                </PersistGate>
            </Provider>
        </AppContainer>,
        rootElement,
    );
}

function init() {
    if (process.env.NODE_ENV === 'development') {
        initDev();

        if ((module as any).hot) {
            // on Root(and it's dependencies change) reload
            (module as any).hot.accept('./Root', () => {
                initDev();
            });
        }
    } else {
        ReactDOM.render(
            <Suspense fallback={null}>
                <Provider store={storeAndPersistor.store}>
                    <PersistGate persistor={storeAndPersistor.persistor}>
                        <Root history={routerHistory} />
                    </PersistGate>
                </Provider>
            </Suspense>,
            rootElement,
        );
    }
    toast.configure();
}

(function bootstrap() {
    init();
})();
