import React, { createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import _ from 'lodash-es';

export type TableColumnsConfigurableItem = { id: string; isVisible: boolean; isFrozen: boolean; width?: number; minWidth?: number };

export type TableColumnsConfigurableContextValue = {
    columns: TableColumnsConfigurableItem[];
    updateSettings: (newColumns: TableColumnsConfigurableItem[]) => Promise<void>;
    checkIsVisible: (columnId: string) => boolean;
    getColumnWidth: (columnId: string) => number;
};
export type TableColumnsConfigurableContextProps = {
    children: React.ReactNode;
    defaultConfig: TableColumnsConfigurableItem[];
    savedConfig: TableColumnsConfigurableItem[] | undefined;
    onSettingsUpdate: (newColumns: TableColumnsConfigurableItem[]) => void;
};

export const tableColumnsConfigurableInitialState: TableColumnsConfigurableContextValue = { columns: [], updateSettings: void 0, checkIsVisible: void 0, getColumnWidth: void 0 };

export const TableColumnsConfigurableContext = createContext<TableColumnsConfigurableContextValue>(tableColumnsConfigurableInitialState);

export const TableColumnsConfigurableProvider: React.FC<TableColumnsConfigurableContextProps> = ({ children, defaultConfig, onSettingsUpdate, savedConfig }) => {
    const currentConfig: TableColumnsConfigurableItem[] = useMemo(() => {
        return savedConfig || defaultConfig;
    }, [savedConfig]);

    const updateSettings: TableColumnsConfigurableContextValue['updateSettings'] = async (newColumns) => {
        if (!Array.isArray(newColumns)) {
            return;
        }
        onSettingsUpdate(newColumns);
    };

    const checkIsVisible: TableColumnsConfigurableContextValue['checkIsVisible'] = useCallback(
        (columnId) => {
            const foundColumn = currentConfig.find((col) => col.id === columnId);
            return Boolean(foundColumn?.isVisible);
        },
        [currentConfig],
    );

    const getColumnWidth: TableColumnsConfigurableContextValue['getColumnWidth'] = useCallback(
        (columnId) => {
            const columnsConfig = currentConfig || defaultConfig;
            const columnWidth = columnsConfig?.find((col) => col.id === columnId)?.width;
            return columnWidth;
        },
        [currentConfig],
    );

    useEffect(() => {
        // sync columns with user settings if user made some changes and saved on servers
        if (!savedConfig) {
            return;
        }
        const addedColumns = _.differenceBy(defaultConfig, currentConfig, 'id');
        const removedColumns = _.differenceBy(currentConfig, defaultConfig, 'id');

        if (!addedColumns.length && !removedColumns.length) {
            return;
        }
        const filteredColumns = currentConfig.filter((col) => !removedColumns.some((r) => r.id === col.id));
        const configToSync = [...filteredColumns, ...addedColumns];

        updateSettings(configToSync);
    }, [savedConfig]);

    return <TableColumnsConfigurableContext.Provider value={{ columns: currentConfig, updateSettings, checkIsVisible, getColumnWidth }}>{children}</TableColumnsConfigurableContext.Provider>;
};

export const useColumnsSettingsContext = () => useContext(TableColumnsConfigurableContext);
