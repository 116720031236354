import classNames from 'classnames';
import { ButtonProps } from './ButtonTypes';

export const rootClassName = 'ui-button';

export const useClasses = (props: Pick<ButtonProps, 'variant' | 'size' | 'color' | 'justify'>) => {
    const { variant, color, size, justify } = props;
    return {
        root: rootClassName,
        fullWidth: `${rootClassName}--fullWidth`,
        disabled: `${rootClassName}--disabled-${variant}`,
        iconButton: `${rootClassName}--iconButton-${size}`,
        size: `${rootClassName}--size-${variant !== 'text' ? size : `${size}-text`}`,
        color: `${rootClassName}--variant-${variant}--color-${color}`,
        startIcon: `${rootClassName}__startIcon`,
        endIcon: `${rootClassName}__endIcon`,
        disableInteractions: `${rootClassName}--disableInteractions`,
        content: classNames(`${rootClassName}__content`, `${rootClassName}__content--justify-${justify}`),
    };
};
