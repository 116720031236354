import { isNumber, isObject, isString } from 'lodash-es';
import { TestContext } from 'yup';

import { DECIMAL_DP2_REGEXP, DECIMAL_DP4_REGEXP } from '../../../common/utils/validators';
import { TypeaheadItem } from '../../../components/Typeahead/Typeahead';
import i18nInstance from '../../../i18n';
import { AutoTransactionMatchType, ExtensionField, InvoiceType } from '../../../services/types/ApiTypes';
import { getMatchType } from '../AutoTransactionsAddViewHelper';

import { getExtensionFields } from './Triggers/helpers/extensionFieldHelpers';
import { getExtensionTypeLabel } from './Triggers/helpers/extensionTypeHelpers';

export function triggerValidation(this: TestContext, triggerValue: string | number | TypeaheadItem<number>) {
    /*eslint @typescript-eslint/no-this-alias:0*/
    const self = this;
    const {
        parent: { extensionType, extensionField, matchType },
    } = self;
    if (
        [
            AutoTransactionMatchType.Contains,
            AutoTransactionMatchType.Equal,
            AutoTransactionMatchType.NotEqual,
            AutoTransactionMatchType.Greater,
            AutoTransactionMatchType.GreaterEqual,
            AutoTransactionMatchType.Less,
            AutoTransactionMatchType.LessEqual,
        ].includes(matchType)
    ) {
        switch (extensionField) {
            case ExtensionField.GLN:
                if (isNumber(Number(triggerValue)) && /^([\d]{1,13})$/.test(String(triggerValue))) {
                    return true;
                }
                return self.createError({
                    path: self.path,
                    message: i18nInstance.t('component.AutoTransaction.Error.Empty.Equal.Trigger', {
                        triggerType: i18nInstance.t(getExtensionTypeLabel(extensionType)),
                        conditionType: i18nInstance.t(getMatchType(matchType)),
                    }),
                });
            case ExtensionField.ReferenceNumber:
                if (/^([^\r\n\t]{1,50})$/.test(String(triggerValue))) {
                    return true;
                }
                return self.createError({
                    path: self.path,
                    message: i18nInstance.t('component.AutoTransaction.Error.Empty.Equal.Trigger', {
                        triggerType: i18nInstance.t(getExtensionFields(extensionType, extensionField)),
                        conditionType: i18nInstance.t(getMatchType(matchType)),
                    }),
                });
            case ExtensionField.ContentCode:
            case ExtensionField.ContentText:
            case ExtensionField.ContractNumber:
            case ExtensionField.ContactPerson:
            case ExtensionField.DepartmentId:
            case ExtensionField.Description:
            case ExtensionField.DocumentName:
            case ExtensionField.ExtensionContent:
            case ExtensionField.ExtensionId:
            case ExtensionField.ExtensionName:
            case ExtensionField.FactorContractNumber:
            case ExtensionField.InvoiceNumber:
            case ExtensionField.UniqueCode:
                if (isString(triggerValue) && triggerValue !== '') {
                    return true;
                }
                return self.createError({
                    path: self.path,
                    message: i18nInstance.t('component.AutoTransaction.Error.Empty.Equal.Trigger', {
                        triggerType: i18nInstance.t(getExtensionTypeLabel(extensionType)),
                        conditionType: i18nInstance.t(getMatchType(matchType)),
                    }),
                });
            case ExtensionField.GrandTotal:
                if (isNumber(Number(triggerValue)) && DECIMAL_DP2_REGEXP.test(String(triggerValue))) {
                    return true;
                }
                return self.createError({
                    path: self.path,
                    message: i18nInstance.t('component.AutoTransaction.Error.Empty.Equal.Trigger', {}),
                });
            case ExtensionField.NETAmount:
                if (isNumber(Number(triggerValue)) && DECIMAL_DP4_REGEXP.test(String(triggerValue))) {
                    return true;
                }
                return self.createError({
                    path: self.path,
                    message: i18nInstance.t('component.AutoTransaction.Error.Empty.Equal.Trigger', {}),
                });
            case ExtensionField.InvoiceType:
                if (isNumber(Number(triggerValue)) && [InvoiceType.PurchaseInvoice, InvoiceType.Expense].includes(Number(triggerValue))) {
                    return true;
                }
                return self.createError({
                    path: self.path,
                    message: i18nInstance.t('component.AutoTransaction.Error.Empty.Equal.Trigger', {
                        triggerType: i18nInstance.t(getExtensionTypeLabel(extensionType)),
                        conditionType: i18nInstance.t(getMatchType(matchType)),
                    }),
                });
            case ExtensionField.BeneficiaryName:
            case ExtensionField.Company:
                if (isObject(triggerValue) && triggerValue.hasOwnProperty('value') && !!triggerValue.value) {
                    return true;
                }
                return self.createError({
                    path: self.path,
                    message: i18nInstance.t('component.AutoTransaction.Error.Empty.Equal.Trigger', {
                        triggerType: i18nInstance.t(getExtensionTypeLabel(extensionType)),
                        conditionType: i18nInstance.t(getMatchType(matchType)),
                    }),
                });
            default:
                return false;
        }
    }
    return true;
}
