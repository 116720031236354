import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Collapsible from 'react-collapsible';
import { Dispatchable2 } from 'redux-dispatchers';
import classNames from 'classnames';
import { debounce } from 'lodash-es';

import { ContentBlock } from '../../../../components/ContentBlock/ContentBlock';
import { ContentBlockHeader } from '../../../../components/ContentBlock/ContentBlockHeader';
import { Switch } from '../../../../components/Switch/Switch';
import { Button, ButtonIconPlacement, ButtonType } from '../../../../components/Buttons/Button';
import Icon, { ICONS } from '../../../../components/Icon/Icon';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import { isSystemSetting } from '../../../../common/user/userPermissionUtil';
import { createDataId } from '../../../../common/utils/dataId';
import { DEFAULT_CURRENCY_PLACEHOLDER } from '../../../../common/constants/appConstants';
import { formatMoneyAndCurrency } from '../../../../common/utils/formatters';
import { getPurchaseOrderExtraStatusOptions } from '../../../../views/purchase-orders/PurchaseOrdersViewHelper';
import { PurchaseOrderExtraStatus, PurchaseOrdersDTO } from '../../../../services/types/ApiTypes';
import { translateExtraStatuses } from '../../PurchaseOrdersAddViewHelper';
import ProgressBar, { ProgressBarProps } from '../../../../components/ProgressBar/ProgressBar';
import { getProgressbarProps } from '../../../../views/purchase-orders/components/PoFulfilment/PoFulfilmentHelpers';
import { canUserAdjustFulfilment, dataId } from './FulfilmentHelper';

import './Fulfilment.scss';

type Props = {
    purchaseOrder: PurchaseOrdersDTO;
    loading: boolean;
    updateExtraStatus: Dispatchable2<PurchaseOrderExtraStatus, boolean>;
};

export const Fulfilment: React.FC<Props> = ({ loading, purchaseOrder, updateExtraStatus }) => {
    const [isCollapsed, setIsCollapsed] = React.useState<boolean>(false);

    const { t } = useTranslation();

    const progressBarProps: ProgressBarProps = useMemo(() => {
        const invoicesSum = purchaseOrder.LinkedInvoicesSum || 0;
        const grandTotal = purchaseOrder.Total || 0;
        return getProgressbarProps(invoicesSum, grandTotal);
    }, [purchaseOrder.LinkedInvoicesSum, purchaseOrder.Total]);

    const isReadOnly = !canUserAdjustFulfilment(purchaseOrder.CreatedById);
    const isDataError = purchaseOrder.LinkedInvoicesCount > 0 && purchaseOrder.LinkedInvoicesSum === null;
    const isInvoiceRegisterModulActive = isSystemSetting('isInvoiceRegisterModulActive');
    const isManualView = purchaseOrder.IsManualFulfilment || !isInvoiceRegisterModulActive;
    const autoStatusTitle = isDataError ? t('view.PurchaseOrders.FulfilmentDataErrorMessage') : t(`view.PurchaseOrders.ExtraStatus.${PurchaseOrderExtraStatus[purchaseOrder.OrderExtraStatus]}`);
    const autoFulfilmentTooltip = isInvoiceRegisterModulActive ? t('view.PurchaseOrders.Fulfilment.AutoFulfilment.Tooltip') : t('view.PurchaseOrders.Fulfilment.AutoFulfilment.Disabled.Tooltip');
    const extraStatusList = getPurchaseOrderExtraStatusOptions();

    const debounceUpdateExtraStatus = debounce((status: PurchaseOrderExtraStatus) => {
        if (status !== purchaseOrder.OrderExtraStatus) {
            updateExtraStatus(status, true);
            if (isCollapsed) {
                setIsCollapsed(false);
            }
        }
    }, 300);

    const handleStatusClick = (status: PurchaseOrderExtraStatus) => () => debounceUpdateExtraStatus(status);

    const toggleAutoFulfillment = () => {
        updateExtraStatus(purchaseOrder.OrderExtraStatus, !purchaseOrder.IsManualFulfilment);
        if (isCollapsed) {
            setIsCollapsed(false);
        }
    };

    const debounceToggleAutoFulfillment = debounce(() => {
        toggleAutoFulfillment();
    }, 300);

    const toggleCollapsed = () => {
        setIsCollapsed((prev) => !prev);
    };

    return (
        <ContentBlock className={'po-fulfilment'} loading={loading}>
            <ContentBlockHeader>
                <div className="po-fulfilment__title">
                    <Tooltip content={autoFulfilmentTooltip}>
                        <span>{t('view.PurchaseOrders.Fulfilment.AutoFulfilment')}</span>
                    </Tooltip>
                    <span className="po-fulfilment__header-buttons">
                        <Switch
                            value={!purchaseOrder.IsManualFulfilment && isInvoiceRegisterModulActive}
                            onChange={debounceToggleAutoFulfillment}
                            dataId={createDataId(dataId, 'auto-toggler')}
                            name={'autoFulfilment'}
                            disabled={!isInvoiceRegisterModulActive || isReadOnly}
                        />
                    </span>
                </div>
            </ContentBlockHeader>
            <div className="po-fulfilment__collapse-header">
                {isManualView ? (
                    <Button
                        buttonType={ButtonType.ICON_TEXT}
                        dataId={createDataId(dataId, 'collapse', 'extraStatus')}
                        disabled={isReadOnly}
                        onClick={toggleCollapsed}
                        iconPlacement={ButtonIconPlacement.RIGHT}
                        icon={ICONS.ARROW_DOWN_SMALL}
                        className={classNames('po-fulfilment__collapse-button', {
                            'po-fulfilment__collapse-button--open': isCollapsed,
                        })}
                    >
                        {t(`view.PurchaseOrders.ExtraStatus.${PurchaseOrderExtraStatus[purchaseOrder.OrderExtraStatus]}`)}
                    </Button>
                ) : (
                    <>
                        <div className="po-fulfilment__status-title">
                            <span data-id={createDataId(dataId, 'auto', 'status')}>
                                {(isDataError || progressBarProps.color === 'alarm') && <Icon iconName={ICONS.NOTIFICATION_WARNING_FILLED} />}
                                {autoStatusTitle}
                            </span>
                            <span data-id={createDataId(dataId, 'auto', 'status', 'value')}>{isDataError ? DEFAULT_CURRENCY_PLACEHOLDER : `${progressBarProps.value}%`}</span>
                        </div>
                        <ProgressBar {...progressBarProps} hidePercentage />
                        <div className="po-fulfilment__total-item">
                            <span data-id={createDataId(dataId, 'auto', 'remainingSum')}>{t('view.PurchaseOrders.Fulfilment.RemainingSUM')}</span>
                            <span data-id={createDataId(dataId, 'auto', 'remainingSum', 'value')}>
                                {isDataError
                                    ? DEFAULT_CURRENCY_PLACEHOLDER
                                    : `${formatMoneyAndCurrency(purchaseOrder.Total - purchaseOrder.LinkedInvoicesSum, purchaseOrder.Currency || DEFAULT_CURRENCY_PLACEHOLDER)}`}
                            </span>
                        </div>
                        <div className="po-fulfilment__total-item">
                            <span data-id={createDataId(dataId, 'auto', 'linkedInvoices')}>{t('view.PurchaseOrders.LinkedInvoices')}</span>
                            <span data-id={createDataId(dataId, 'auto', 'linkedInvoices', 'value')}>{isDataError ? DEFAULT_CURRENCY_PLACEHOLDER : purchaseOrder.LinkedInvoicesCount || 0}</span>
                        </div>
                        <div className="po-fulfilment__total-item">
                            <span data-id={createDataId(dataId, 'auto', 'linkedGrandTotal')}>{t('view.PurchaseOrders.Invoices.Totals.LinkedGrandTotal')}</span>
                            <span data-id={createDataId(dataId, 'auto', 'linkedGrandTotal', 'value')}>
                                {isDataError ? DEFAULT_CURRENCY_PLACEHOLDER : `${formatMoneyAndCurrency(purchaseOrder.LinkedInvoicesSum || 0, purchaseOrder.Currency || DEFAULT_CURRENCY_PLACEHOLDER)}`}
                            </span>
                        </div>
                    </>
                )}
            </div>
            {isManualView && (
                <Collapsible easing="ease-in-out" open={isCollapsed} triggerDisabled transitionTime={300} trigger={null}>
                    {translateExtraStatuses(extraStatusList, t).map((status, index) => (
                        <Button
                            buttonType={ButtonType.ICON_TEXT}
                            onClick={handleStatusClick(status.value)}
                            dataId={createDataId(dataId, 'menu', index)}
                            className={classNames('po-fulfilment__status-button', {
                                active: status.value === purchaseOrder.OrderExtraStatus,
                            })}
                            key={index}
                            disabled={isReadOnly}
                        >
                            {status.text}
                        </Button>
                    ))}
                </Collapsible>
            )}
        </ContentBlock>
    );
};
