import React from 'react';
import { DropdownMenu, DropdownMenuItem } from '../../../../../components/DropdownMenu/DropdownMenu';
import { addDataId, createDataId } from '../../../../../common/utils/dataId';
import { AutoTransactionMatchType } from '../../../../../services/types/ApiTypes';
import { getMatchType } from '../../../AutoTransactionsAddViewHelper';
import { Button, ButtonType } from '../../../../../components/Buttons/Button';
import { AutomationStepFields, ConditionFields } from '../../../autoTransactionAddViewFields';
import { TFunction } from 'i18next';

export interface CombineMatchTypeSelectionProps {
    automationStep: AutomationStepFields;
    condition: ConditionFields;
    t: TFunction;
    callback(matchType: AutoTransactionMatchType): void;
    fieldNamePrefix: string;
}

export const CombineMatchTypeSelection: React.FC<CombineMatchTypeSelectionProps> = ({ fieldNamePrefix, automationStep, condition, t, callback }) => {
    const { combineMatchType: matchType } = condition;
    const matchTypeEmptyValues = [null, undefined, -1, 0];
    return (
        <DropdownMenu
            key={`${fieldNamePrefix}.${automationStep.id}.${condition.orderNo}.matchType`}
            dataId={addDataId(`${fieldNamePrefix}.matchTypeDropdown`, '')}
            items={[AutoTransactionMatchType.Equal, AutoTransactionMatchType.Contains].map((automationMatchType, index) => {
                if (automationMatchType !== null) {
                    return (
                        <DropdownMenuItem key={index} onClick={() => callback(automationMatchType)} dataId={createDataId('option', index)}>
                            {t(getMatchType(automationMatchType))}
                        </DropdownMenuItem>
                    );
                } else {
                    return null;
                }
            })}
        >
            <Button
                className={`automation-step__item${matchTypeEmptyValues.includes(matchType) ? ' automation-step__item--placeholder' : ''}`}
                dataId={addDataId(`${fieldNamePrefix}.matchTypeDropdown`, '.trigger')}
                buttonType={ButtonType.TEXT}
            >
                {!matchTypeEmptyValues.includes(matchType) ? t(getMatchType(matchType)) : t('component.AutoTransaction.PlaceHolder.ClickToSet')}
            </Button>
        </DropdownMenu>
    );
};

export default React.memo(CombineMatchTypeSelection);
