import { yupToFormErrors, Form, Formik, FormikErrors, FormikProps } from 'formik';
import { debounce, includes, isEmpty, memoize } from 'lodash-es';
import * as React from 'react';
import { WithTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { Dispatchable0, Dispatchable1, Dispatchable2 } from 'redux-dispatchers';
import * as Yup from 'yup';

import { BaseStatefulComponent } from '../../components/BaseStatefulComponent';
import { ContentBlock } from '../../components/ContentBlock/ContentBlock';
import { ContentBlockBody } from '../../components/ContentBlock/ContentBlockBody';
import { ContentBlockFooter, ContentBlockFooterType } from '../../components/ContentBlock/ContentBlockFooter';
import { MainPage, MainPageContent, MainPageType } from '../../components/MainPage/MainPage';
import i18nInstance from '../../i18n';
import {
    AutoTransactionMatchType,
    AutoTransactionUiObjectDTO,
    CombinationOptionType,
    CostObjectiveType,
    ExtensionField,
    ExtensionType,
    CustomCostObjectiveFullDTO,
    VatCodeDTO,
} from '../../services/types/ApiTypes';
import { User } from '../../services/ApiClient';
import api from '../../services/ApiServices';

import fieldNames, { AllocationFields, AutoTransactionsAddViewFields } from './autoTransactionAddViewFields';
import { triggerValidation } from './components/triggerValidation';
import AutomationSteps from './components/AutomationSteps/AutomationSteps';
import Header from './components/Header/Header';
import MainTabList from './components/MainTabList/MainTabList';
import Triggers from './components/Triggers/Triggers';
import { createAutomationRuleApiObject } from './AutoTransactionsAddViewHelper';
import { AutoTransactionAddViewState } from './AutoTransactionsAddViewReducer';
import InvoiceCustomFields from './components/InvoiceCustomFields/InvoiceCustomFields';

import './AutoTransactionsAddView.scss';

export interface Props extends Pick<AutoTransactionAddViewState, 'retrieveATLoadable'> {
    initialValues: AutoTransactionsAddViewFields;
    currentUser: User;
    userActiveVatCodes: VatCodeDTO[];
    companyVatRates: number[];
}

export enum Tabs {
    Triggers = 1,
    AutomationSteps = 2,
    InvoiceCustomFields = 3,
}

export interface State {
    activeTab: Tabs;
    customCostObjectives: CustomCostObjectiveFullDTO[];
    validating: boolean;
    // disable validation when dragging for performance improvement, since we don't actually change any data
    disableValidation: boolean;
    isSnapshot: boolean;
}

export interface DispatchProps {
    addOrUpdateAT: Dispatchable1<AutoTransactionUiObjectDTO>;
    retrieveAT: Dispatchable2<string | undefined, boolean | undefined>;
    getUserVATCodes: Dispatchable0;
    getCompanyVatRates: Dispatchable0;
}

export interface RouteParams {
    id: string;
}

export type AutoTransactionsAddViewProps = Props & DispatchProps & WithTranslation & RouteComponentProps<RouteParams>;

export const triggerSchema = Yup.object().shape({
    // all fields that are referenced in .when must be defined in the schema, otherwise validation is not triggered! https://github.com/jquense/yup/issues/365
    extensionField: Yup.number(),
    extensionType: Yup.number(),
    matchType: Yup.number()
        .when(['extensionType', 'extensionField'], {
            is: (extensionType: ExtensionType, extensionField: ExtensionField) => extensionField === ExtensionField.ReferenceNumber,
            then: Yup.number().required(
                i18nInstance.t('component.AutoTransaction.Error.Empty.MatchType', {
                    combineField: i18nInstance.t('component.AutoTransaction.Parameter.Description'), // TODO: correct error code
                }),
            ),
            otherwise: Yup.number().notRequired(),
        })
        .nullable(true),
    value: Yup.mixed()
        .test('isTriggerValueValid', 'value is not valid', triggerValidation)
        .nullable(true),
});

class AutoTransactionsAddView extends BaseStatefulComponent<AutoTransactionsAddViewProps, State> {
    private validationSchema: any;

    constructor(props: AutoTransactionsAddViewProps) {
        super(props);
        this.state = {
            activeTab: Tabs.Triggers,
            customCostObjectives: [],
            validating: false,
            disableValidation: false,
            isSnapshot: /add\/:id/.test(this.props.match.path),
        };
        this.createValidationSchema();
    }

    createValidationSchema() {
        const { t } = this.props;
        const workflowSchema = Yup.object().shape({
            enabled: Yup.boolean(),
            assignee: Yup.object().when('enabled', {
                is: (enabled) => enabled,
                then: Yup.object()
                    .shape({
                        value: Yup.number(),
                    })
                    .nullable(true)
                    .required(t('component.AutoTransaction.Error.Empty.Workflow', { workflowType: 'workflow' })),
                otherwise: Yup.object()
                    .notRequired()
                    .nullable(true),
            }),
        });
        const triggersViewSchema = Yup.object().shape({
            triggers: Yup.array(triggerSchema),
        });
        const dimensionSchema = Yup.object().shape({
            type: Yup.number(),
            customCostObjective: Yup.object()
                .when('type', {
                    is: (type) => type === CostObjectiveType.CustomCostObjective,
                    then: Yup.object()
                        .shape({
                            value: Yup.number()
                                .required(t('component.AutoTransaction.Error.Empty.Dimension.Type'))
                                .nullable(true),
                            text: Yup.string()
                                .notRequired()
                                .nullable(true),
                        })
                        .required(t('component.AutoTransaction.Error.Empty.Dimension.Type')),
                    otherwise: Yup.object().shape({
                        value: Yup.number()
                            .notRequired()
                            .nullable(true),
                        text: Yup.string()
                            .notRequired()
                            .nullable(true),
                    }),
                })
                .nullable(true),
            dimension: Yup.object()
                .when('type', {
                    is: (type) => type === CostObjectiveType.CustomCostObjective,
                    then: Yup.object()
                        .shape({
                            value: Yup.number()
                                .required(t('component.AutoTransaction.Error.Empty.Dimension.Value'))
                                .nullable(true),
                            text: Yup.string()
                                .notRequired()
                                .nullable(true),
                        })
                        .required(t('component.AutoTransaction.Error.Empty.Dimension.Value')),
                    otherwise: Yup.object().shape({
                        value: Yup.number()
                            .notRequired()
                            .nullable(true),
                        text: Yup.string()
                            .notRequired()
                            .nullable(true),
                    }),
                })
                .nullable(true),
        });
        const allocationSchema = Yup.object().shape({
            dimensions: Yup.array(dimensionSchema),
            amount: Yup.string().required(t('component.AutoTransaction.Error.Empty.AllocationAmount')),
        });
        const vatRateSchema = Yup.object().shape({
            unPostedAmount: Yup.number().oneOf([0], t('component.AutoTransaction.Confirmation.UnPostedAmount')),
            allocations: Yup.array(allocationSchema)
                .min(1, t('component.AutoTransaction.Error.Empty.Allocations'))
                .nullable(true),
        });
        const conditionSchema = Yup.object().shape({
            combineMatchType: Yup.number()
                .when('combinationOption', {
                    is: (combinationOption) => includes([CombinationOptionType.CombineSearch, CombinationOptionType.ApplyToAllWhere], combinationOption),
                    then: Yup.number().required(t('component.AutoTransaction.Error.Empty.CombineMatchType')),
                    otherwise: Yup.number().notRequired(),
                })
                .nullable(true),
            rowSearchString: Yup.string()
                .when('combineMatchType', {
                    is: (combineMatchType) => includes([AutoTransactionMatchType.Contains, AutoTransactionMatchType.Equal], combineMatchType),
                    then: Yup.string().required(t('component.AutoTransaction.Error.Empty.CombineOptionValue')),
                    otherwise: Yup.string()
                        .notRequired()
                        .nullable(true),
                })
                .nullable(true),
        });
        const automationStepSchema = Yup.object().shape({
            vatRates: Yup.array().when('combinationOption', {
                is: (combinationOption) => combinationOption === CombinationOptionType.CombineBy,
                then: Yup.array()
                    .notRequired()
                    .nullable(true),
                otherwise: Yup.array(vatRateSchema).min(1, t('component.AutoTransaction.Error.Empty.VatRates')),
            }),
            combinationOption: Yup.number().required(t('component.AutoTransaction.Error.Empty.CombineOption')),
            conditions: Yup.array(conditionSchema).min(1, t('component.AutoTransaction.Error.Empty.Conditions')),
        });
        const automationStepsSchema = Yup.array(automationStepSchema);

        this.validationSchema = Yup.object().shape({
            ruleName: Yup.string()
                .required(t('component.AutoTransaction.Error.Empty.New.RuleName'))
                .nullable(true)
                .min(1, t('component.AutoTransaction.Error.Empty.Deleted.RuleName'))
                .max(128, t('component.AutoTransaction.Error.TooLong.RuleName'))
                .test('unique-ruleName', t('component.AutoTransaction.Error.Exists.RuleName'), function(value) {
                    return api.autoTransaction.checkAutoTransactionNameForDuplicates(value, this.parent.id).then((r) => {
                        return !(r.data && r.data !== value);
                    });
                }),
            ruleDescription: Yup.string()
                .nullable(true)
                .max(256, t('component.AutoTransaction.Error.TooLong.RuleDescription')),
            triggersView: triggersViewSchema,
            autoTransactionsRows: automationStepsSchema,
            workflow: workflowSchema,
        });
    }

    componentDidMount() {
        if (this.props.match) {
            const atId = this.props.match.params.id;
            this.props.retrieveAT(atId, this.state.isSnapshot);
            this.getCustomCostObjectives('').then((response: CustomCostObjectiveFullDTO[]) => {
                this.setState({
                    customCostObjectives: response,
                });
            });
        }
        this.props.getUserVATCodes();
        this.props.getCompanyVatRates();
    }

    componentDidUpdate(prevProps: Readonly<AutoTransactionsAddViewProps>): void {
        // if translation function changes, re-generate validationSchema because translations may be still loading when component is created
        if (prevProps.t !== this.props.t) {
            this.createValidationSchema();
        }
    }

    handleSubmit = (formik: FormikProps<AutoTransactionsAddViewFields>) => {
        this.setState({
            validating: true,
        });
        setTimeout(() => {
            formik.submitForm();
            this.setState({
                validating: false,
            });
        }, 0);
    };
    debounceHandleSubmit = debounce(this.handleSubmit, 300);

    getCustomCostObjectives = (name: string): Promise<CustomCostObjectiveFullDTO[]> => {
        return api.customCostObjective.getByDescriptionPart(name, false).then((response) => {
            return response.data;
        });
    };

    isReadOnly() {
        return false;
    }

    handleSplitAllocation = (atRowIndex: number, vatRateIndex: number, allocationIndex: number, formik: FormikProps<AutoTransactionsAddViewFields>, count = 2) => {
        const allocations = formik.values.autoTransactionsRows[atRowIndex].vatRates[vatRateIndex].allocations;
        const splitAllocations: AllocationFields[] = [];
        const testAllocations: AllocationFields[] = allocations.slice();
        const allocation: AllocationFields = testAllocations.splice(allocationIndex, 1)[0];
        if (typeof allocation.amount === 'string') {
            allocation.amount = parseFloat(allocation.amount);
        }
        const otherAllocationsSum = testAllocations.reduce((prev, curr) => prev + curr.amount, 0);

        let splitSum: number = parseFloat((allocation.amount / count).toFixed(2));

        if ((splitSum * count + otherAllocationsSum).toFixed(2) !== (100).toFixed(2)) {
            splitSum = allocation.amount / count;
        }
        for (let i = 0; i < count; i++) {
            // const allocation = cloneDeep(allocations[allocationIndex]);

            const newAllocation = {
                ...allocation,
                amount: splitSum,
            };
            if (isEmpty(newAllocation.dimensions)) {
                newAllocation.dimensions = [
                    {
                        type: CostObjectiveType.Account,
                        readOnly: true,
                        customCostObjective: null,
                        dimension: null,
                        isNew: true,
                        automationStepId: 0,
                    },
                    {
                        type: CostObjectiveType.VatCode,
                        readOnly: true,
                        customCostObjective: null,
                        dimension: null,
                        isNew: true,
                        automationStepId: 0,
                    },
                ];
            }
            splitAllocations.push(newAllocation);
        }
        const newAllocations = allocations.slice();
        newAllocations.splice(allocationIndex, 1, ...splitAllocations);
        formik.setFieldValue(`${fieldNames.autoTransactionsRows}[${atRowIndex}].vatRates[${vatRateIndex}].allocations`, newAllocations);
    };

    /**
     * TODO @tonister: rewrite this into a functional component
     */
    getTabContents = (t: any, formik: FormikProps<AutoTransactionsAddViewFields>) => {
        const contents = {
            [Tabs.Triggers]: <Triggers formik={formik} fieldNamePrefix={`${fieldNames.triggersView}`} triggersView={formik.values.triggersView} t={t} />,
            [Tabs.AutomationSteps]: (
                <AutomationSteps
                    formik={formik}
                    fieldNamePrefix={`${fieldNames.autoTransactionsRows}`}
                    customCostObjectives={this.state.customCostObjectives}
                    handleSplitAllocation={this.handleSplitAllocation}
                    automationSteps={formik.values.autoTransactionsRows}
                    userVatCodes={this.props.userActiveVatCodes}
                    companyVatRates={this.props.companyVatRates}
                    t={t}
                    handleStepDragging={this.handleStepDragging}
                />
            ),
            [Tabs.InvoiceCustomFields]: <InvoiceCustomFields formik={formik} t={t} fieldNamePrefix={`${fieldNames.autoTransactionsCustomFields}`} />,
        };
        return contents[this.state.activeTab];
    };

    handleStepDragging = (isDragging: boolean) => {
        this.setState({
            disableValidation: isDragging,
        });
    };

    validate = async (values: AutoTransactionsAddViewFields): Promise<FormikErrors<AutoTransactionsAddViewFields>> => {
        try {
            await this.validationSchema.validate(values, { abortEarly: false });
            return {};
        } catch (e) {
            console.error(e);
            throw yupToFormErrors(e);
        }
    };

    memoizeValidate = memoize(this.validate, (...args) => JSON.stringify(args));

    render(): JSX.Element {
        const { t, retrieveATLoadable, initialValues, currentUser } = this.props;
        const at = retrieveATLoadable.payload || null;
        return (
            !isEmpty(at) &&
            !isEmpty(initialValues) && (
                <MainPage className={'automation-rule-add'} type={MainPageType.HAS_SIDEBAR}>
                    <MainPageContent>
                        <Formik
                            key={at ? at.Id : 'new'} // use key to reinitialize formik once data is loaded
                            initialValues={initialValues}
                            enableReinitialize={true}
                            onSubmit={(values) => {
                                this.debounceAddOrUpdateAT(createAutomationRuleApiObject(values, at, currentUser.GroupMemberId, t));
                            }}
                            validate={this.state.disableValidation ? undefined : this.memoizeValidate}
                            validateOnBlur={true}
                            validateOnChange={true}
                        >
                            {(formik: FormikProps<AutoTransactionsAddViewFields>) => (
                                <Form>
                                    <ContentBlock loading={retrieveATLoadable.loading || this.state.validating}>
                                        <Header
                                            formik={formik}
                                            t={t}
                                            isSnapshot={this.state.isSnapshot}
                                            errors={formik.errors}
                                            isFormValid={formik.isValid}
                                            isNew={formik.values.isNew}
                                            isReadOnly={this.isReadOnly()}
                                            ruleName={formik.values.ruleName}
                                            ruleDescription={formik.values.ruleDescription}
                                            onSubmit={() => {
                                                this.debounceHandleSubmit(formik);
                                            }}
                                        />
                                        <ContentBlockBody dataId="contentBlock.automationAddView">
                                            <MainTabList tabs={formik.values} activeTab={this.state.activeTab} handleClick={(tab: Tabs) => this.setState({ activeTab: tab })} t={t} />
                                        </ContentBlockBody>
                                        <ContentBlockFooter type={ContentBlockFooterType.NO_PADDING} noSeparator={true}>
                                            {this.getTabContents(t, formik)}
                                        </ContentBlockFooter>
                                    </ContentBlock>
                                </Form>
                            )}
                        </Formik>
                    </MainPageContent>
                </MainPage>
            )
        );
    }

    debounceAddOrUpdateAT = debounce(this.props.addOrUpdateAT, 50);
}

export default AutoTransactionsAddView;
