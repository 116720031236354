import { ConnectedRouter } from 'connected-react-router';
import { History } from 'history';
import * as React from 'react';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

import RoutesWrapper from './common/router/RoutesWrapper';
import { BaseComponent } from './components/BaseComponent';

export interface AppProps {
    history: History;
}

class Root extends BaseComponent<AppProps> {
    public render() {
        const { history } = this.props;

        return (
            <ConnectedRouter history={history}>
                <RoutesWrapper />
            </ConnectedRouter>
        );
    }
}

export default DragDropContext(HTML5Backend)(Root);
