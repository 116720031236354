import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getCurrentUser } from '../../common/user/UserSelectors';
import { GlobalState } from '../../rootReducer';

import UserListView, { DispatchProps, Props } from './UserListView';
import {
    filterUserList,
    getActiveUsersCount,
    getUserList,
    saveNewUser,
    searchUserList,
    setUserListPagingOptions,
    sortUserList,
    updateUserState,
    exportUsersToCsv,
    exportUsersToXls,
} from './UserListViewActions';
import { getCurrentCompanySettings } from '../../common/company/CompanyActions';
import { selectIsCurrentCompanySettingsLoading, selectCurrentCompanySettings } from '../../common/company/CompanySelectors';
import { selectActiveUsersCountLoadable, selectUserListLoadable, selectUserListSearchParams } from './UserListViewReducer';

const mapStateToProps = (
    state: GlobalState,
): Pick<Props, 'activeUsersCountLoadable' | 'usersListLoadable' | 'userData' | 'searchParams' | 'currentCompanySettingsLoading' | 'currentCompanySettings'> => {
    return {
        activeUsersCountLoadable: selectActiveUsersCountLoadable(state),
        usersListLoadable: selectUserListLoadable(state),
        userData: getCurrentUser(state),
        searchParams: selectUserListSearchParams(state),
        currentCompanySettingsLoading: selectIsCurrentCompanySettingsLoading(state),
        currentCompanySettings: selectCurrentCompanySettings(state),
    };
};

const mapDispatchToProps: DispatchProps = {
    saveNewUser,
    getActiveUsersCount,
    getCurrentCompanySettings,
    changeUserStatus: updateUserState,
    filterUserList,
    sortUserList,
    getUserList,
    searchUserList,
    setUserListPagingOptions,
    exportUsersToCsv,
    exportUsersToXls,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UserListView));
