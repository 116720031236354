import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { GlobalState } from '../../rootReducer';
import { getCurrentUser, getCurrenUserGroupMemberCommonLoadable } from '../../common/user/UserSelectors';
import { selectIsMainMenuOpen } from '../../components/MainMenu/MainMenuReducer';
import PurchaseOrdersView, { DispatchProps, Props } from './PurchaseOrdersView';
import {
    deletePurchaseOrder,
    exportPurchaseOrdersToXLSX,
    filterPurchaseOrders,
    getPurchaseOrdersList,
    searchPurchaseOrders,
    setPurchaseOrdersPagingOptions,
    setUserTouchedPOListStatusFilter,
    sortPurchaseOrders,
    exportPurchaseOrderToPdf,
    duplicatePurchaseOrder,
    setTableColumnsConfigurable,
} from './PurchaseOrdersViewActions';
import { selectDeletePurchaseOrderLoadable, selectPurchaseOrdersLoadable, selectSearchParams, selectUserTouchedStatusFilter } from './PurchaseOrdersViewReducer';

const mapStateToProps = (state: GlobalState): Props => {
    return {
        userTouchedStatusFilter: selectUserTouchedStatusFilter(state),
        purchaseOrdersLoadable: selectPurchaseOrdersLoadable(state),
        deletePurchaseOrderLoadable: selectDeletePurchaseOrderLoadable(state),
        userData: getCurrentUser(state),
        searchParams: selectSearchParams(state),
        groupMember: getCurrenUserGroupMemberCommonLoadable(state),
        sideMenuIsOpen: selectIsMainMenuOpen(state),
    };
};

const mapDispatchToProps: DispatchProps = {
    filterPurchaseOrders,
    setUserTouchedPOListStatusFilter,
    setPurchaseOrdersPagingOptions,
    sortPurchaseOrders,
    searchPurchaseOrders,
    getPurchaseOrdersList,
    deletePurchaseOrder,
    exportPurchaseOrdersToXLSX,
    exportPurchaseOrderToPdf,
    duplicatePurchaseOrder,
    setTableColumnsConfigurable,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PurchaseOrdersView));
