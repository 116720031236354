import * as React from 'react';
import { debounce } from 'lodash-es';
import { WithTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { Dispatchable0, Dispatchable1, Dispatchable2 } from 'redux-dispatchers';

import { getEmptyUser } from '../../common/user/userUtils';
import { createDataId } from '../../common/utils/dataId';
import { formatDate } from '../../common/utils/formatters';
import { Button, ButtonType } from '../../components/Buttons/Button';
import Checkbox from '../../components/Checkbox/Checkbox';
import { ContentBlock } from '../../components/ContentBlock/ContentBlock';
import { ContentBlockBody } from '../../components/ContentBlock/ContentBlockBody';
import { ContentBlockFooter, ContentBlockFooterType } from '../../components/ContentBlock/ContentBlockFooter';
import { ContentBlockHeader, ContentBlockHeaderType } from '../../components/ContentBlock/ContentBlockHeader';
import ContentBlockHeading, { ContentBlockHeadingType } from '../../components/ContentBlock/ContentBlockHeading';
import { DropdownMenu, DropdownMenuItem } from '../../components/DropdownMenu/DropdownMenu';
import { ICONS } from '../../components/Icon/Icon';
import { MainPage, MainPageAside, MainPageContent, MainPageType } from '../../components/MainPage/MainPage';
import Pager from '../../components/Pager/Pager';
import Table from '../../components/Table/Table';
import TableBody from '../../components/Table/TableBody';
import TableCell from '../../components/Table/TableCell';
import TableHead from '../../components/Table/TableHead';
import { DataTableHeader } from '../../components/Table/TableHeader';
import TableRow from '../../components/Table/TableRow';
import { TypeaheadItem } from '../../components/Typeahead/TypeaheadAsync';
import { Typography } from '../../components/Typography';
import {
    AccountDTO,
    CostObjectiveItemsCountForUserDTO,
    //Currency, //this type is not supported by api now
    DimensionDTO,
    GroupMemberDTO,
    SubstituteDTO,
    VatCodeDTO,
} from '../../services/types/ApiTypes';
import UserDetailsCard from './UserDetailsCard';
import NewUserModalWrapper from '../user-list/components/EditUserModal/EditUserModalWrapper';
import { DEFAULT_RESTRICTION, UserAddViewState } from './UserAddViewReducer';
import { BaseStatefulComponent } from '../../components/BaseStatefulComponent';
import OutOfOffice from '../out-of-office/OutOfOffice';

import './UserAddView.scss';

export interface Props extends Pick<UserAddViewState, 'saveUserLoadable' | 'groupMemberLoadable' | 'costObjectivesLoadable' | 'dimensionsLoadable' | 'activeCostObjective' | 'searchParams'> {
    substitutes: Array<TypeaheadItem<GroupMemberDTO>>;
}

export interface DispatchProps {
    getUserData: Dispatchable1<string>;
    save: Dispatchable2<GroupMemberDTO, boolean | undefined>;
    getCompanyGroupMembers: Dispatchable0;
    deleteSubstitute: Dispatchable1<number>;
    getCostObjectives: Dispatchable1<string>;
    setActiveCostObjective: Dispatchable1<CostObjectiveItemsCountForUserDTO>;
    searchDimensions: Dispatchable0;
    filterDimensions: Dispatchable1<string>;
    setPagingOptions: Dispatchable2<number, number>;
    toggleCostObjectiveItemForUser: Dispatchable1<number>;
    sortDimensions: Dispatchable1<string>;
    setAllCostObjectiveItemsForUser: Dispatchable1<boolean>;
    setAllCostObjectiveItemsOnPageForUser: Dispatchable1<boolean>;
}

interface RouteParams {
    id?: string;
}

export type UserManagementAddViewProps = Props & DispatchProps & WithTranslation & RouteComponentProps<RouteParams>;

interface State {
    isUserCardEdit: boolean;
}

export class UserAddView extends BaseStatefulComponent<UserManagementAddViewProps, State> {
    constructor(props: UserManagementAddViewProps) {
        super(props);

        this.state = {
            isUserCardEdit: false,
        };
    }

    toggleUserCardEdit = () =>
        this.setState((prev) => ({
            isUserCardEdit: !prev.isUserCardEdit,
        }));

    componentDidMount(): void {
        if (this.props.match) {
            this.props.getUserData(this.props.match.params.id.toString());
            this.props.getCostObjectives(this.props.match.params.id.toString());
        }
        this.props.getCompanyGroupMembers();
    }

    componentDidUpdate(prevProps: Readonly<UserManagementAddViewProps>) {
        if (prevProps.costObjectivesLoadable.payload !== this.props.costObjectivesLoadable.payload && this.props.costObjectivesLoadable.payload) {
            if (!this.props.activeCostObjective) {
                this.setActiveCostObjective(this.props.costObjectivesLoadable.payload[0]);
            }
        }
        if (prevProps.activeCostObjective !== this.props.activeCostObjective && this.props.activeCostObjective) {
            this.props.filterDimensions('');
        }
    }

    setActiveCostObjective = (co: CostObjectiveItemsCountForUserDTO) => {
        this.props.setActiveCostObjective(co);
    };

    getUser(): GroupMemberDTO {
        const { groupMemberLoadable } = this.props;
        if (groupMemberLoadable.payload) {
            return groupMemberLoadable.payload;
        }
        return getEmptyUser();
    }

    debounceDoSearch = debounce(this.props.filterDimensions, 400);

    handleSubstitutesUpdate = (values: SubstituteDTO[]) => {
        const user = { ...this.getUser(), Substitutes: values };
        this.props.save(user, true);
    };

    handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.debounceDoSearch(e.target.value);
    };

    getSearchValue() {
        const searchRestriction = this.props.searchParams.Restrictions.find((r) => r.Field === DEFAULT_RESTRICTION);
        return searchRestriction.Value;
    }

    isAllOnPageSelected = (): boolean | undefined => {
        const { dimensionsLoadable } = this.props;
        if (dimensionsLoadable.payload && dimensionsLoadable.payload.Items) {
            const selectedDimensions = dimensionsLoadable.payload.Items.filter((dimension) => dimension.AssignedCurrentToUser === true);
            if (selectedDimensions.length === dimensionsLoadable.payload.Items.length) {
                return true;
            }
            if (selectedDimensions.length === 0) {
                return false;
            }
            return undefined;
        }
        return true;
    };

    render() {
        const { groupMemberLoadable, costObjectivesLoadable, dimensionsLoadable, activeCostObjective, t } = this.props;
        const user = this.getUser();

        return (
            <MainPage className="user-details" type={MainPageType.HAS_SIDEBAR}>
                <MainPageContent>
                    <ContentBlock loading={groupMemberLoadable.loading || costObjectivesLoadable.loading}>
                        <div className="user-details__cost-objectives-container">
                            <div className="user-details__cost-objectives py-7 pr-3">
                                <ContentBlockHeader hasBackButton type={ContentBlockHeaderType.COMPACT} className="dimensions-header pl-5">
                                    <ContentBlockHeading dataId="blockHeading.userAdd.title.container">
                                        <Typography variant="h2" dataId="blockHeading.userAdd.title" element="span">
                                            {t('view.Accounting.Dimensions')}
                                        </Typography>
                                    </ContentBlockHeading>
                                </ContentBlockHeader>
                                <ul className="pl-5">
                                    {costObjectivesLoadable.payload &&
                                        costObjectivesLoadable.payload.map((co, i) => (
                                            <li key={i}>
                                                <button
                                                    onClick={() => {
                                                        this.setActiveCostObjective(co);
                                                    }}
                                                    data-id={createDataId('costObjective', co.Name)}
                                                    className={`user-details__cost-objective pl-5 pr-3 ${
                                                        !!activeCostObjective && co.ObjectType === activeCostObjective.ObjectType && co.Id === activeCostObjective.Id
                                                            ? 'user-details__cost-objective--is-active'
                                                            : ''
                                                    }`}
                                                >
                                                    {co.Name}
                                                    <span className="user-details__cost-objective-dimension-count">{co.Count}</span>
                                                </button>
                                            </li>
                                        ))}
                                </ul>
                            </div>

                            <div className="user-details__dimensions">
                                <ContentBlockHeader hasSearch searchOptions={{ dataId: createDataId('dimensionSearch'), value: this.getSearchValue(), onChange: this.handleChange }}>
                                    {activeCostObjective && (
                                        <ContentBlockHeading dataId="userDetails.dimensions.heading" type={ContentBlockHeadingType.BOLD}>
                                            <Typography variant="h2" element="span">
                                                {activeCostObjective.Name}
                                            </Typography>
                                        </ContentBlockHeading>
                                    )}
                                </ContentBlockHeader>
                                <ContentBlockBody loading={dimensionsLoadable.loading} dataId="contentBlock.userDetail">
                                    <Table fixed>
                                        <DataTableHeader data-id={createDataId('userDetails', 'dimensions', 'Table', 'Header')}>
                                            <TableRow>
                                                <TableHead style={{ width: '72px' }}>
                                                    <Checkbox
                                                        name={createDataId('dimension', 'toggleCheckbox')}
                                                        partiallyChecked={this.isAllOnPageSelected() === undefined}
                                                        value={this.isAllOnPageSelected() || this.isAllOnPageSelected() === undefined}
                                                        onChange={(e) => {
                                                            this.debounceSetAllCostObjectiveItemsOnPageForUser(e.target.checked);
                                                        }}
                                                    />
                                                    {dimensionsLoadable.payload && (
                                                        <DropdownMenu
                                                            dataId={createDataId('dimension', 'toggleAllMenu')}
                                                            items={
                                                                <>
                                                                    <DropdownMenuItem
                                                                        dataId={createDataId('dimension', 'grantAll')}
                                                                        onClick={() => {
                                                                            this.props.setAllCostObjectiveItemsForUser(true);
                                                                        }}
                                                                    >
                                                                        {t('view.Accounting.GrantAllItemsForUser', { count: dimensionsLoadable.payload.TotalCount })}
                                                                    </DropdownMenuItem>
                                                                    <DropdownMenuItem
                                                                        dataId={createDataId('dimension', 'revokeAll')}
                                                                        onClick={() => {
                                                                            this.props.setAllCostObjectiveItemsForUser(false);
                                                                        }}
                                                                    >
                                                                        {t('view.Accounting.RevokeAllItemsForUser', { count: dimensionsLoadable.payload.TotalCount })}
                                                                    </DropdownMenuItem>
                                                                </>
                                                            }
                                                        >
                                                            <Button buttonType={ButtonType.ICON} icon={ICONS.ARROW_DOWN_SMALL} />
                                                        </DropdownMenu>
                                                    )}
                                                </TableHead>
                                                <TableHead
                                                    sortable
                                                    searchParams={dimensionsLoadable.request}
                                                    columnName={'Code'}
                                                    onClick={this.props.sortDimensions}
                                                    dataId={createDataId('dimension', 'Code')}
                                                >
                                                    {t('view.Accounting.Code')}
                                                </TableHead>
                                                <TableHead
                                                    // TODO: remove sorting until we have same column names for all CostObjectives
                                                    // sortable
                                                    // searchParams={dimensionsLoadable.request}
                                                    // columnName={
                                                    //     this.props.activeCostObjective && this.props.activeCostObjective.ObjectType === CostObjectiveType.VatCode ? 'Name' : 'Number'
                                                    // }
                                                    // onClick={this.props.sortDimensions}
                                                    dataId={createDataId('dimension', 'Name')}
                                                    style={{
                                                        width: '30%',
                                                    }}
                                                >
                                                    {t('view.Accounting.Description')}
                                                </TableHead>
                                                <TableHead
                                                    sortable
                                                    searchParams={dimensionsLoadable.request}
                                                    columnName={'StartDate'}
                                                    onClick={this.props.sortDimensions}
                                                    dataId={createDataId('dimension', 'StartDate')}
                                                >
                                                    {t('view.Accounting.StartDate')}
                                                </TableHead>
                                                <TableHead
                                                    sortable
                                                    searchParams={dimensionsLoadable.request}
                                                    columnName={'EndDate'}
                                                    onClick={this.props.sortDimensions}
                                                    dataId={createDataId('dimension', 'EndDate')}
                                                >
                                                    {t('view.Accounting.EndDate')}
                                                </TableHead>
                                            </TableRow>
                                        </DataTableHeader>
                                        <TableBody>
                                            {dimensionsLoadable.payload &&
                                                dimensionsLoadable.payload.Items &&
                                                dimensionsLoadable.payload.Items.map((r, i) => (
                                                    <TableRow key={r.Id} isRowSelected={r.AssignedCurrentToUser}>
                                                        <TableCell dataId={createDataId('Cell', i, 'Checkbox')}>
                                                            <Checkbox
                                                                name={`dimension.${i}.checkbox`}
                                                                value={r.AssignedCurrentToUser}
                                                                onChange={() => {
                                                                    this.props.toggleCostObjectiveItemForUser(r.Id);
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell hideOverflow dataId={createDataId('Cell', i, 'Code')}>
                                                            {r.Code}
                                                        </TableCell>
                                                        <TableCell hideOverflow dataId={createDataId('Cell', i, 'Description')}>
                                                            {(r as AccountDTO | VatCodeDTO).Description || (r as DimensionDTO).Description}
                                                        </TableCell>
                                                        <TableCell hideOverflow dataId={createDataId('Cell', i, 'StartDate')}>
                                                            {formatDate(r.StartDate)}
                                                        </TableCell>
                                                        <TableCell hideOverflow dataId={createDataId('Cell', i, 'EndDate')}>
                                                            {formatDate(r.EndDate)}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                </ContentBlockBody>
                                <ContentBlockFooter type={ContentBlockFooterType.PAGER} noSeparator>
                                    <Pager pages={dimensionsLoadable.payload} onPageChange={this.props.setPagingOptions} />
                                </ContentBlockFooter>
                            </div>
                        </div>
                    </ContentBlock>
                </MainPageContent>
                <MainPageAside>
                    <UserDetailsCard groupMemberLoadable={groupMemberLoadable} enableEditting={this.toggleUserCardEdit} />
                    <OutOfOffice groupMemberCommonLoadable={groupMemberLoadable} updateSubstitutes={this.handleSubstitutesUpdate} isUserDetails />
                    <NewUserModalWrapper isModalOpen={this.state.isUserCardEdit} onClose={this.toggleUserCardEdit} userToEdit={user} />
                </MainPageAside>
            </MainPage>
        );
    }
    debounceSetAllCostObjectiveItemsOnPageForUser = debounce(this.props.setAllCostObjectiveItemsOnPageForUser, 400);
}

export default UserAddView;
