import { connect, FormikProps, FormikState } from 'formik';
import { isEqual } from 'lodash-es';
import * as React from 'react';

export interface IEffectProps<TValues = {}> {
    onChange(currentState: FormikState<TValues>, prevState: FormikState<TValues>): void;

    formik: FormikProps<TValues>;
}

const Effect = class FormikEffect<TValues = {}> extends React.Component<IEffectProps<TValues>, {}> {
    componentDidUpdate(prevProps: IEffectProps<TValues>) {
        const { values, touched, errors, isSubmitting, isValidating, isValid, setFieldTouched } = this.props.formik;
        const { values: prevValues, touched: prevTouched, errors: prevErrors, isSubmitting: prevIsSubmitting, isValidating: prevIsValidating, isValid: prevIsValid } = prevProps.formik;
        if (!isEqual(prevProps.formik, this.props.formik)) {
            this.props.onChange(
                {
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    isValidating,
                    isValid,
                    setFieldTouched,
                } as FormikProps<TValues>,
                {
                    values: prevValues,
                    touched: prevTouched,
                    errors: prevErrors,
                    isSubmitting: prevIsSubmitting,
                    isValidating: prevIsValidating,
                    isValid: prevIsValid,
                } as FormikProps<TValues>,
            );
        }
    }

    render(): React.ReactNode {
        return null;
    }
};

export default connect(Effect);
