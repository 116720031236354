import classNames from 'classnames';
import { noop } from 'lodash-es';
import * as React from 'react';
import { HTMLAttributes } from 'react';
import Textarea from 'react-textarea-autosize';

import { createDataId, DataId } from '../../common/utils/dataId';
import { formatDate } from '../../common/utils/formatters';
import { BaseStatefulComponent } from '../BaseStatefulComponent';
import { Button, ButtonType } from '../Buttons/Button';
import { DropdownMenu, DropdownMenuItem } from '../DropdownMenu/DropdownMenu';
import Icon, { IconSize, ICONS } from '../Icon/Icon';
import Tooltip from '../Tooltip/Tooltip';

import './Assignee.scss';

export interface AssigneeProps extends HTMLAttributes<HTMLDivElement> {
    name: React.ReactNode;
    subtext?: string;
    date?: Date;
    status: AssigneeStatus;
    comment?: string;
    onConfirm?: (comment: string) => void;
    onReject?: (comment: string) => void;
    commentPlaceholder?: string;
    confirmText?: string;
    rejectText?: string;
    noCommentText?: string;
    confirmErrorText?: string;
    loading?: boolean;
    onAddApproverBefore?: () => void;
    onAddApproverAfter?: () => void;
    isApproverAddingAvailable?: boolean;
    addBeforeText?: string;
    addAfterText?: string;
    dataId: DataId;
}

export enum AssigneeStatus {
    ASSIGNER = 'ASSIGNER',
    PENDING = 'PENDING',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    WAITING_ACTION = 'WAITING_ACTION',
}

export interface AssigneeState {
    comment: string;
}

class RejectButton extends React.Component<{ onClick: () => void; rejectText: string; dataId: DataId }> {
    render() {
        return (
            <Button dataId={this.props.dataId || 'rejectButton'} buttonType={ButtonType.ICON_TEXT} icon={ICONS.PLUS} onClick={this.props.onClick} iconRotation={45}>
                {this.props.rejectText}
            </Button>
        );
    }
}

export class Assignee extends BaseStatefulComponent<AssigneeProps, AssigneeState> {
    private inputNode: HTMLTextAreaElement;
    static defaultProps: Partial<AssigneeProps> = {
        status: AssigneeStatus.PENDING,
    };

    constructor(props: AssigneeProps) {
        super(props);
        this.state = {
            comment: undefined,
        };
    }

    handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({
            comment: e.target.value,
        });
    };

    handleConfirmClick = () => {
        this.props.onConfirm(this.state.comment);
    };

    handleRejectClick = () => {
        this.props.onReject(this.state.comment);
    };

    hasComment() {
        return this.state.comment && this.state.comment.trim().length > 0;
    }

    isActionsMenuVisible = () => {
        const { onAddApproverAfter, onAddApproverBefore, isApproverAddingAvailable } = this.props;
        return onAddApproverBefore && onAddApproverAfter && isApproverAddingAvailable;
    };

    render() {
        const {
            dataId,
            name,
            subtext,
            date,
            status,
            comment,
            loading,
            onAddApproverAfter,
            onAddApproverBefore,
            addAfterText,
            addBeforeText,
            commentPlaceholder,
            noCommentText,
            confirmErrorText,
            confirmText,
            /* eslint-disable @typescript-eslint/no-unused-vars */
            onReject,
            className,
            isApproverAddingAvailable,
            onConfirm,
            children,
            /* eslint-enable @typescript-eslint/no-unused-vars */
            rejectText,
            ...rest
        } = this.props;
        const classes = classNames('assignee', { 'assignee--waiting-action': status === AssigneeStatus.WAITING_ACTION, 'assignee--loading': loading });
        return (
            <div className={classes} {...rest} data-id={createDataId(dataId || 'assignee')}>
                {status === AssigneeStatus.ASSIGNER && <Icon name={ICONS.ARROW_RIGHT} className="assignee__icon" />}
                {status === AssigneeStatus.APPROVED && <Icon name={ICONS.CHECKMARK} className="assignee__icon" />}
                {status === AssigneeStatus.REJECTED && <Icon name={ICONS.PLUS} rotation={45} className="assignee__icon" />}
                <div className="assignee__content">
                    <div className="assignee__title">
                        <div>
                            <p className="assignee__name">{name}</p>
                            {subtext && <p className="assignee__subtext">{subtext}</p>}
                        </div>
                        {date && ![AssigneeStatus.PENDING, AssigneeStatus.WAITING_ACTION].includes(status) && <p className="assignee__date">{formatDate(date)}</p>}
                        {this.isActionsMenuVisible() && (
                            <DropdownMenu
                                dataId={createDataId(dataId || 'assignee', 'actionsMenu')}
                                items={
                                    <>
                                        <DropdownMenuItem onClick={onAddApproverBefore} icon={ICONS.ARROW_BEFORE} dataId="addBefore">
                                            {addBeforeText}
                                        </DropdownMenuItem>
                                        <DropdownMenuItem onClick={onAddApproverAfter} icon={ICONS.ARROW_AFTER} dataId="addAfter">
                                            {addAfterText}
                                        </DropdownMenuItem>
                                    </>
                                }
                            >
                                <Button
                                    dataId={createDataId(dataId || 'assignee', 'actionsMenu', 'trigger')}
                                    className="assignee__more-actions"
                                    icon={ICONS.MORE}
                                    iconSize={IconSize.SM}
                                    buttonType={ButtonType.ICON}
                                />
                            </DropdownMenu>
                        )}
                    </div>
                    {comment && <p className="assignee__comment">{comment}</p>}
                    {status === AssigneeStatus.WAITING_ACTION && (
                        <div className="assignee__actions-wrap">
                            <Textarea
                                data-id={createDataId(dataId || 'assignee', 'comment')}
                                className="assignee__comment-area"
                                placeholder={commentPlaceholder}
                                maxLength={400}
                                onChange={this.handleCommentChange}
                                inputRef={(node) => {
                                    this.inputNode = node;
                                }}
                            />
                            <div className="assignee__actions">
                                {confirmErrorText ? (
                                    <Tooltip content={confirmErrorText} trigger={'click'} delay={0} placement={'bottom'}>
                                        <Button dataId={createDataId(dataId || 'assignee', 'actions', 'confirm')} buttonType={ButtonType.ICON_TEXT} icon={ICONS.ARROW_RIGHT} onClick={noop}>
                                            {confirmText}
                                        </Button>
                                    </Tooltip>
                                ) : (
                                    <Button
                                        dataId={createDataId(dataId || 'assignee', 'actions', 'confirm')}
                                        buttonType={ButtonType.ICON_TEXT}
                                        icon={ICONS.ARROW_RIGHT}
                                        onClick={this.handleConfirmClick}
                                    >
                                        {confirmText}
                                    </Button>
                                )}
                                {this.hasComment() && (
                                    <RejectButton dataId={createDataId(dataId || 'assignee', 'actions', 'reject', 'withComment')} onClick={this.handleRejectClick} rejectText={rejectText} />
                                )}
                                {!this.hasComment() && (
                                    <Tooltip content={noCommentText} trigger={'click'} delay={0} placement={'bottom'}>
                                        <RejectButton dataId={createDataId(dataId || 'assignee', 'actions', 'reject', 'withoutComment')} onClick={noop} rejectText={rejectText} />
                                    </Tooltip>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default Assignee;
