import React from 'react';
import CombineFieldSelection from './CombineFieldSelection';
import { isEmpty, size } from 'lodash-es';
import CombineMatchTypeSelection from './CombineMatchTypeSelection';
import { AutomationStepFields, AutoTransactionsAddViewFields, ConditionFields } from '../../../autoTransactionAddViewFields';
import { AutoTransactionMatchType, CombinationOptionType } from '../../../../../services/types/ApiTypes';
import { AutomationStepCombineFieldItem } from '../../../AutoTransactionsAddViewHelper';
import { TFunction } from 'i18next';
import { FormikProps } from 'formik';
import CombineValueInput from './CombineValueInput';

export interface ConditionsProps {
    allCombineFields: AutomationStepCombineFieldItem[];
    automationStep: AutomationStepFields;
    fieldNamePrefix: string;
    t: TFunction;
    formik: FormikProps<AutoTransactionsAddViewFields>;
}

export interface ConditionProps extends ConditionsProps {
    allCombineFields: AutomationStepCombineFieldItem[];
    condition: ConditionFields;
}

export function stepHasCombineField(automationStep: AutomationStepFields): boolean {
    return [CombinationOptionType.CombineSearch, CombinationOptionType.ApplyToAllWhere].includes(automationStep.combinationOption);
}

export function stepHasRowSearchStringInput(condition: ConditionFields): boolean {
    return [AutoTransactionMatchType.Equal, AutoTransactionMatchType.Contains].includes(condition.combineMatchType);
}

export function setMatchType(matchType: AutoTransactionMatchType, formik: FormikProps<AutoTransactionsAddViewFields>, fieldNamePrefix: string, conditionIndex: number) {
    formik.setFieldValue(`${fieldNamePrefix}.conditions[${conditionIndex}].combineMatchType`, matchType);
}

const Condition = React.memo(function ConditionComponent({ allCombineFields, automationStep, fieldNamePrefix, t, formik, condition }: ConditionProps) {
    return (
        <>
            {/* Only show the combine field selection if the automation step can have it */}
            {stepHasCombineField(automationStep) && (
                <CombineFieldSelection
                    key={`${fieldNamePrefix}.${automationStep.id}.${condition.orderNo}.combineField`}
                    t={t}
                    formik={formik}
                    allCombineFields={allCombineFields}
                    condition={condition}
                    fieldNamePrefix={fieldNamePrefix}
                />
            )}
            {/* Only show the matchType selection if the automation step can have combineField and the combineField has been selected */}
            {stepHasCombineField(automationStep) && !isEmpty(condition.combineField) && (
                <CombineMatchTypeSelection
                    key={`${fieldNamePrefix}.${automationStep.id}.${condition.orderNo}.combineMatchType`}
                    t={t}
                    fieldNamePrefix={fieldNamePrefix}
                    callback={(matchType: AutoTransactionMatchType) => {
                        setMatchType(matchType, formik, fieldNamePrefix, condition.orderNo - 1);
                    }}
                    condition={condition}
                    automationStep={automationStep}
                />
            )}
            {/* Only show the value input when the the matchType supports it or if automation step is CombineBy */}
            {(automationStep.combinationOption === CombinationOptionType.CombineBy || !!condition.combineMatchType) && (
                <CombineValueInput allCombineFields={allCombineFields} automationStep={automationStep} condition={condition} fieldNamePrefix={fieldNamePrefix} formik={formik} t={t} />
            )}
            {/* Only show the splitter element with text "AND" if the automation step has two conditions and only show it after the first condition */}
            {!isEmpty(condition.rowSearchString) && size(automationStep.conditions) === 2 && condition.orderNo === 1 && (
                <span key={`${fieldNamePrefix}.conditions.and`} className={'automation-step__item'}>
                    {t('view.AutoTransactions.Details.AutomationStep.Conditions.And')}
                </span>
            )}
        </>
    );
});

const Conditions: React.FC<ConditionsProps> = (props) => {
    return (
        <>
            {props.automationStep.conditions.map((condition) => (
                <Condition {...props} condition={condition} key={`${props.fieldNamePrefix}.conditions[${condition.orderNo - 1}]`} />
            ))}
        </>
    );
};

export default React.memo(Conditions);
