import { connect } from 'react-redux';

import { GlobalState } from '../../../../rootReducer';
import { getPurchaseOrderHistory } from './PurchaseOrderHistoryActions';
import { selectPurchaseOrderHistory } from './PurchaseOrderHistoryReducers';
import withSuspense from '../../../../common/hocs/withSuspense';
import PurchaseOrderHistory, { DispatchProps, Props } from './PurchaseOrderHistory';

const mapStateToProps = (state: GlobalState): Pick<Props, 'purchaseOrderHistoryLoadable'> => ({
    purchaseOrderHistoryLoadable: selectPurchaseOrderHistory(state),
});

const mapDispatchToProps: DispatchProps = {
    getPurchaseOrderHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(withSuspense(PurchaseOrderHistory));
