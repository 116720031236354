import * as React from 'react';
import { Dispatchable2 } from 'redux-dispatchers';

import { createDataId, WithDataId } from '../../../../common/utils/dataId';
import { Role } from '../../../../common/user/userPermissionUtil';
import { BaseComponent } from '../../../../components/BaseComponent';
import { Button, ButtonType } from '../../../../components/Buttons/Button';
import { DropdownMenu, DropdownMenuItem } from '../../../../components/DropdownMenu/DropdownMenu';
import { IconSize, ICONS } from '../../../../components/Icon/Icon';
import AccessLevel from '../../../../components/AccessLevel/AccessLevel';
import { PurchaseOrdersViewProps } from '../../PurchaseOrdersView';

export interface Props extends Pick<PurchaseOrdersViewProps, 't' | 'deletePurchaseOrder' | 'duplicatePurchaseOrder'> {
    purchaseOrderId: number;
    isReadOnly: boolean;
    OrderNumber: string;
    exportPurchaseOrderToPdf: Dispatchable2<string, string>;
}

class PurchaseOrdersItemMenu extends BaseComponent<Props & WithDataId> {
    render() {
        const { purchaseOrderId, t, isReadOnly, deletePurchaseOrder, dataId, exportPurchaseOrderToPdf, OrderNumber, duplicatePurchaseOrder } = this.props;
        if (isReadOnly) {
            return null;
        }
        return (
            <DropdownMenu
                dataId={dataId}
                className="purchase-orders__options-dropdown"
                isOverflowHidden
                items={
                    <>
                        <DropdownMenuItem
                            className="purchase-orders__options-dropdown-item"
                            onClick={() => exportPurchaseOrderToPdf(purchaseOrderId.toString(), OrderNumber)}
                            dataId={createDataId(dataId, 'exportToPdf')}
                            icon={ICONS.EXPORT_24}
                        >
                            {t('view.PurchaseOrders.Options.Export')}
                        </DropdownMenuItem>
                        <AccessLevel role={Role.CanAddPurchaseOrders}>
                            <DropdownMenuItem
                                className="purchase-orders__options-dropdown-item"
                                onClick={() => {
                                    duplicatePurchaseOrder(purchaseOrderId.toString());
                                }}
                                dataId={createDataId(dataId, 'duplicate')}
                                icon={ICONS.COPY_24}
                            >
                                {t('view.PurchaseOrders.Options.Clone')}
                            </DropdownMenuItem>
                        </AccessLevel>
                        <AccessLevel role={Role.CanDeletePurchaseOrders}>
                            <DropdownMenuItem
                                className="purchase-orders__options-dropdown-item"
                                onClick={() => {
                                    deletePurchaseOrder(purchaseOrderId);
                                }}
                                dataId={createDataId(dataId, 'delete')}
                                icon={ICONS.DELETE_24}
                            >
                                {t('view.PurchaseOrders.Options.Delete')}
                            </DropdownMenuItem>
                        </AccessLevel>
                    </>
                }
            >
                <Button dataId={createDataId(dataId, 'button')} icon={ICONS.MORE} iconSize={IconSize.SM} buttonType={ButtonType.ICON} />
            </DropdownMenu>
        );
    }
}

export default PurchaseOrdersItemMenu;
