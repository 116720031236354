import React from 'react';
import { useTranslation } from 'react-i18next';
import StampLabel, { StampType } from '../../../../components/StampLabel/StampLabel';
import { PurchaseOrdersDTO, PurchaseOrderStatus as PoStatus } from '../../../../services/types/ApiTypes';
import { getStatusKey } from '../../PurchaseOrdersViewHelper';

import './PoStatusLabel.scss';

export const getChipType = (status: PurchaseOrdersDTO['OrderStatus']): StampType => {
    const statusMap: Map<PoStatus, StampType> = new Map([
        [PoStatus.New, StampType.INFO],
        [PoStatus.Assigned, StampType.ACTIVE],
        [PoStatus.Confirmed, StampType.APPROVED],
        [PoStatus.Rejected, StampType.ALARM],
    ]);
    return statusMap.get(status) || StampType.NEUTRAL;
};

export type PoStatusChipProps = {
    status: PoStatus;
};

const PoStatusLabel: React.FC<PoStatusChipProps> = (props) => {
    const { t } = useTranslation();
    return (
        <div className="po-status-label">
            <StampLabel type={getChipType(props.status)} text={t(getStatusKey(props.status))} />
        </div>
    );
};

export default React.memo(PoStatusLabel);
