import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { GlobalState } from '../../../../rootReducer';

import EditUserModal, { DispatchProps, Props } from './EditUserModal';
import { getCurrentCompanySettings } from '../../../../common/company/CompanyActions';
import { saveNewUser } from '../../UserListViewActions';
import { save } from '../../../user-add/UserAddViewActions';
import { getCurrencies } from '../../../../common/user/UserActions';
import { selectCurrencies } from '../../../../common/user/UserSelectors';
import { selectIsCurrentCompanySettingsLoading, selectCurrentCompanySettings } from '../../../../common/company/CompanySelectors';

const mapStateToProps = (state: GlobalState): Pick<Props, 'currentCompanySettingsLoading' | 'currentCompanySettings' | 'loadedCurrencies'> => {
    return {
        currentCompanySettingsLoading: selectIsCurrentCompanySettingsLoading(state),
        currentCompanySettings: selectCurrentCompanySettings(state),
        loadedCurrencies: selectCurrencies(state),
    };
};

const mapDispatchToProps: DispatchProps = {
    saveExistingUser: save,
    saveNewUser,
    getCurrentCompanySettings,
    getCurrencies,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditUserModal));
