import { FormikProps } from 'formik';
import { find, isEmpty, isString } from 'lodash-es';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';

import { ExtensionField, ExtensionType } from '../../../../../services/types/ApiTypes';
import api from '../../../../../services/ApiServices';
import { AutoTransactionsAddViewFields, ConditionFields, TriggerFields } from '../../../autoTransactionAddViewFields';
import CombineFieldSelection from './CombineFieldSelection';
import { TFunction } from 'i18next';
import { AutomationStepCombineFieldItem } from '../../../AutoTransactionsAddViewHelper';

export interface CombineByMethodsDropdownProps {
    allCombineFields: AutomationStepCombineFieldItem[];
    condition: ConditionFields;
    t: TFunction;
    fieldNamePrefix: string;
    formik: FormikProps<AutoTransactionsAddViewFields>;
    callback(item: AutomationStepCombineFieldItem): void;
}

const CombineByMethodsDropdown = (props: CombineByMethodsDropdownProps) => {
    const { allCombineFields, condition, callback, t, fieldNamePrefix, formik } = props;
    const getMetaInfos = useCallback(
        async (name: string) => {
            const companyTrigger = find(
                formik.values.triggersView.triggers,
                (trigger: TriggerFields) => trigger.extensionType === ExtensionType.SellerParty && trigger.extensionField === ExtensionField.Company,
            );
            const response = await api.autoTransaction.getLastMetaInfosBySupplier(companyTrigger && (isString(companyTrigger.value) ? Number(companyTrigger.value) : null), name);
            if (response.data) {
                return response.data.map((item) => ({
                    value: {
                        field: item.Field,
                        value: item.Value,
                    },
                    text: item.Value,
                }));
            }
            return [];
        },
        [formik],
    );

    const [metaInfos, setMetaInfos] = useState([]);

    useEffect(() => {
        const fetchMetaInfos = async () => {
            const response = await getMetaInfos('');
            setMetaInfos(response);
        };
        if (isEmpty(metaInfos)) {
            fetchMetaInfos();
        }
    }, [getMetaInfos, metaInfos]);

    return !isEmpty(metaInfos) || condition.rowSearchString ? (
        <CombineFieldSelection
            key={`${fieldNamePrefix}.conditions[${condition.orderNo - 1}].metas`}
            allCombineFields={allCombineFields}
            condition={condition}
            fieldNamePrefix={fieldNamePrefix}
            callback={callback}
            t={t}
            formik={formik}
            label={condition.rowSearchString}
        />
    ) : (
        <span key={`${fieldNamePrefix}.conditions[${condition.orderNo - 1}].noMeta`} className={'automation-step__item'}>
            {t('view.AutoTransactions.Details.AutomationStep.CombineBy.NoMetas')}
        </span>
    );
};

export default React.memo(CombineByMethodsDropdown);
