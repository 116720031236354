import React, { cloneElement } from 'react';

import { useColumnsSettingsContext } from '../../TableColumnsConfigurableContext';
import TableHead from '../../../../../../components/Table/TableHead';

export type TableRowConfigurableChildProps = {
    columnName?: string;
    columnWidth?: number;
};

export const TableRowConfigurable: React.FC = ({ children }) => {
    const columnsConfig = useColumnsSettingsContext();
    const renderChildrenAndOrderByColumnIndex = () => {
        const childrenNodes: React.ReactElement<TableRowConfigurableChildProps>[] = [];
        React.Children.forEach(children, (child: React.ReactElement<TableRowConfigurableChildProps>) => {
            const { columnName } = child.props;
            if (!columnName) {
                childrenNodes.push(child);
                return;
            }

            const newChild = child.type === TableHead ? cloneElement(child, { key: child.props.columnName, columnWidth: columnsConfig?.getColumnWidth(columnName) }) : child;
            const columnIndex = columnsConfig?.columns.findIndex((col) => col.id === columnName);
            if (columnIndex < 0) {
                childrenNodes.push(newChild);
                return;
            }

            const isColumnVisible = columnsConfig?.checkIsVisible(columnName);
            if (!isColumnVisible) {
                return;
            }
            childrenNodes[columnIndex] = newChild;
        });
        return childrenNodes;
    };

    return <>{renderChildrenAndOrderByColumnIndex()}</>;
};
