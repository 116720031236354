import React from 'react';
import { AutoTransactionsAddViewFields, ConditionFields } from '../../../autoTransactionAddViewFields';
import { DropdownMenu, DropdownMenuItem } from '../../../../../components/DropdownMenu/DropdownMenu';
import { createDataId } from '../../../../../common/utils/dataId';
import { UserRoles } from '../../../../user-list/components/UserRoles/UserRoles';
import { Button, ButtonType } from '../../../../../components/Buttons/Button';
import { getCombineFieldSelectionTriggerLabel, getMetaFieldLabel } from '../AutomationStep';
import { FormikProps } from 'formik';
import { TFunction } from 'i18next';
import { isEmpty } from 'lodash-es';
import { AutomationStepCombineFieldItem, getAutomationStepCombineFieldLabel } from '../../../AutoTransactionsAddViewHelper';

export interface CombineFieldSelectionProps {
    allCombineFields: AutomationStepCombineFieldItem[];
    condition: ConditionFields;
    callback?(item: AutomationStepCombineFieldItem): void;
    label?: string;
    fieldNamePrefix: string;
    formik: FormikProps<AutoTransactionsAddViewFields>;
    t: TFunction;
}

const CombineFieldSelection: React.FC<CombineFieldSelectionProps> = ({ allCombineFields, condition, callback, label, fieldNamePrefix, formik, t }) => {
    const getDropMenuItems = (fields: AutomationStepCombineFieldItem[]) => {
        return fields.map((item, index) => {
            const metaFieldName: string = getMetaFieldLabel(item.field);
            const itemFieldName = getAutomationStepCombineFieldLabel(item.name);
            return (
                <DropdownMenuItem
                    onClick={() => {
                        if (!callback) {
                            formik.setFieldValue(`${fieldNamePrefix}.conditions[${condition.orderNo - 1}].combineField`, item.value);
                        } else {
                            callback(item);
                        }
                    }}
                    key={item.name + index}
                    dataId={createDataId('automationStep', 'combineField', 'item')}
                >
                    {!!item.field && <UserRoles userRoles={[t(metaFieldName)]} />}&nbsp;{t(itemFieldName)}
                </DropdownMenuItem>
            );
        });
    };

    return (
        <DropdownMenu dataId={createDataId('automationStep', 'combineField')} items={getDropMenuItems(allCombineFields)}>
            <Button className={`automation-step__item${!label && isEmpty(condition.combineField) ? ' automation-step__item--placeholder' : ''}`} buttonType={ButtonType.TEXT}>
                {getCombineFieldSelectionTriggerLabel(condition, t, label)}
            </Button>
        </DropdownMenu>
    );
};

export default React.memo(CombineFieldSelection);
