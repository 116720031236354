import { debounce } from 'lodash-es';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Dispatchable2, Dispatchable3 } from 'redux-dispatchers';

import { createDataId } from '../../../../../common/utils/dataId';
import { formatDate } from '../../../../../common/utils/formatters';
import { LoadableData } from '../../../../../common/utils/LoadableData';
import { ContentBlockBody } from '../../../../../components/ContentBlock/ContentBlockBody';
import { ContentBlockFooter, ContentBlockFooterType } from '../../../../../components/ContentBlock/ContentBlockFooter';
import ContentBlockSearch from '../../../../../components/ContentBlock/ContentBlockSearch';
import Pager from '../../../../../components/Pager/Pager';
import Table from '../../../../../components/Table/Table';
import TableBody from '../../../../../components/Table/TableBody';
import TableCell, { ResponsiveCellType } from '../../../../../components/Table/TableCell';
import TableHead from '../../../../../components/Table/TableHead';
import { DataTableHeader } from '../../../../../components/Table/TableHeader';
import TableRow from '../../../../../components/Table/TableRow';
import { BaseSearch, PagedListContainer, PurchaseOrderHeaderHistoryDTO, PurchaseOrdersDTO } from '../../../../../services/types/ApiTypes';
import { HistorySearchParams } from '../../../PurchaseOrdersAddViewReducer';

const dataId = 'purchase-order-history';

export interface Props {
    purchaseOrderHistoryLoadable: LoadableData<PagedListContainer<PurchaseOrderHeaderHistoryDTO>, HistorySearchParams>;
    searchParams: HistorySearchParams;
    purchaseOrder: PurchaseOrdersDTO;
}

export interface DispatchProps {
    getHistoryList: Dispatchable2<BaseSearch, number>;
    searchPoHistoryList: Dispatchable2<string, number>;
    setPoHistoryPagingOptions: Dispatchable3<number, number, number>;
    sortPoHistoryList: Dispatchable2<string, number>;
}

export type HistoryListViewProps = Props & DispatchProps & WithTranslation;

const HistoryList = (props: HistoryListViewProps) => {
    React.useEffect(() => {
        if (props.purchaseOrder?.Id) {
            props.getHistoryList(undefined, props.purchaseOrder.Id);
        }
    }, [props.purchaseOrder]);

    const debounceDoSearch = debounce((searchString: string) => {
        props.searchPoHistoryList(searchString, props.purchaseOrder.Id);
    }, 400);

    const handleSearchInputClick = (value: string) => {
        debounceDoSearch(value);
    };

    const handlePageChange = (page: number, pageSize?: number) => {
        props.setPoHistoryPagingOptions(props.purchaseOrder.Id, page, pageSize);
    };

    const handleSortClick = (value: string) => {
        props.sortPoHistoryList(value, props.purchaseOrder.Id);
    };

    return (
        <>
            <ContentBlockSearch dataId={dataId} onClick={handleSearchInputClick} withSearchButton className="po-history__search" />
            <div className="po-hisotry__table">
                <ContentBlockBody className="po-hisotry__table-content" loading={props.purchaseOrderHistoryLoadable.loading} dataId="contentBlockBodyPoHistoryList">
                    <Table>
                        <DataTableHeader data-id={createDataId(dataId, 'Table', 'Header')}>
                            <TableRow>
                                <TableHead
                                    sortable={true}
                                    searchParams={props.purchaseOrderHistoryLoadable.request}
                                    columnName="AuditDate"
                                    onClick={handleSortClick}
                                    dataId={createDataId(dataId, 'AuditDate')}
                                    style={{ width: '20%' }}
                                >
                                    {props.t('view.PurchaseOrders.History.Column.EventDateTime')}
                                </TableHead>
                                <TableHead
                                    sortable={true}
                                    searchParams={props.purchaseOrderHistoryLoadable.request}
                                    columnName="ModifiedByName"
                                    onClick={handleSortClick}
                                    dataId={createDataId(dataId, 'ModifiedByName')}
                                    style={{ width: '15%' }}
                                >
                                    {props.t('view.PurchaseOrders.History.Column.ModifiedBy')}
                                </TableHead>
                                <TableHead
                                    sortable={true}
                                    searchParams={props.purchaseOrderHistoryLoadable.request}
                                    onClick={handleSortClick}
                                    columnName="Change.Name"
                                    dataId={createDataId(dataId, 'Field')}
                                    style={{ width: '15%' }}
                                >
                                    {props.t('view.PurchaseOrders.History.Column.Field')}
                                </TableHead>
                                <TableHead
                                    sortable={true}
                                    searchParams={props.purchaseOrderHistoryLoadable.request}
                                    onClick={handleSortClick}
                                    columnName="Change.OldValue"
                                    dataId={createDataId(dataId, 'OldValue')}
                                    style={{ width: '25%' }}
                                >
                                    {props.t('view.PurchaseOrders.History.Column.OldValue')}
                                </TableHead>
                                <TableHead
                                    sortable={true}
                                    searchParams={props.purchaseOrderHistoryLoadable.request}
                                    onClick={handleSortClick}
                                    columnName="Change.NewValue"
                                    dataId={createDataId(dataId, 'NewValue')}
                                    style={{ width: '25%' }}
                                >
                                    {props.t('view.PurchaseOrders.History.Column.NewValue')}
                                </TableHead>
                            </TableRow>
                        </DataTableHeader>
                        <TableBody>
                            {props.purchaseOrderHistoryLoadable?.payload &&
                                props.purchaseOrderHistoryLoadable.payload.Items.map((row, id) => (
                                    <TableRow key={id} data-id={createDataId(dataId, 'tableRow', id)}>
                                        <TableCell dataId={createDataId(dataId, `${id}.AuditDate`)} hideOverflow={true} responsiveCellType={ResponsiveCellType.LABEL}>
                                            {formatDate(row?.AuditDate, 'dd.MM.yyyy HH:mm:ss') || '\u00A0'}
                                        </TableCell>
                                        <TableCell dataId={createDataId(dataId, `${id}.ModifiedByName`)} hideOverflow={true} responsiveCellType={ResponsiveCellType.LABEL}>
                                            {row.ModifiedByName || '\u00A0'}
                                        </TableCell>
                                        <TableCell dataId={createDataId(dataId, `${id}.Field`)} hideOverflow={true} responsiveCellType={ResponsiveCellType.LABEL}>
                                            {row.Change.Name || '\u00A0'}
                                        </TableCell>
                                        <TableCell dataId={createDataId(dataId, `${id}.OldValue`)} hideOverflow={true}>
                                            {row.Change.OldValue || '\u00A0'}
                                        </TableCell>
                                        <TableCell dataId={createDataId(dataId, `${id}.NewValue`)} hideOverflow={true}>
                                            {row.Change.NewValue || '\u00A0'}
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </ContentBlockBody>
            </div>
            <ContentBlockFooter type={ContentBlockFooterType.PAGER}>
                <Pager pages={props.purchaseOrderHistoryLoadable.payload} onPageChange={handlePageChange} />
            </ContentBlockFooter>
        </>
    );
};

export default withTranslation()(HistoryList);
