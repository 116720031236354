import { GlobalState } from '../../rootReducer';
import { connect } from '../../storeConfig';
import { getCurrentUser } from '../../common/user/UserSelectors';
import { getCurrentCompanySettings } from '../../common/company/CompanyActions';
import { selectCurrentCompanySettings, selectIsCurrentCompanySettingsLoading } from '../../common/company/CompanySelectors';
import { selectAddSupplierLoadable } from '../../components/EditSupplierModal/EditSupplierModalReducer';
import { showAddOrEditSupplierModal } from '../../components/EditSupplierModal/EditSupplierModalActions';
import { addNewPurchaseOrder } from './PurchaseOrdersNewActions';
import PurcahseOrdersNew from './PurchaseOrdersNew';
import { DispatchProps, PurchaseOrdersNewProps } from './PurchaseOrdersNewTypes';

const mapStateToProps = (state: GlobalState): PurchaseOrdersNewProps => {
    return {
        currentUser: getCurrentUser(state),
        currentCompanySettingsLoading: selectIsCurrentCompanySettingsLoading(state),
        currentCompanySettings: selectCurrentCompanySettings(state),
        addSupplierLoadable: selectAddSupplierLoadable(state),
    };
};

const mapDispatchToProps: DispatchProps = {
    addNewPurchaseOrder,
    getCurrentCompanySettings,
    showAddOrEditSupplierModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(PurcahseOrdersNew);
