import { ExtensionType } from '../../../../../services/types/ApiTypes';

export const extensionTypesObject = {
    [ExtensionType.BuyerParty]: 'view.AutoTransactions.Details.Triggers.ExtensionType.BuyerParty',
    [ExtensionType.DeliveryParty]: 'view.AutoTransactions.Details.Triggers.ExtensionType.DeliveryParty',
    [ExtensionType.FactorParty]: 'view.AutoTransactions.Details.Triggers.ExtensionType.FactoringParty',
    [ExtensionType.InvoiceInformation]: 'view.AutoTransactions.Details.Triggers.ExtensionType.InvoiceInformation',
    [ExtensionType.InvoiceRows]: 'view.AutoTransactions.Details.Triggers.ExtensionType.InvoiceRows',
    [ExtensionType.InvoiceSumGroup]: 'view.AutoTransactions.Details.Triggers.ExtensionType.InvoiceSumGroup',
    [ExtensionType.PayerParty]: 'view.AutoTransactions.Details.Triggers.ExtensionType.PayerParty',
    [ExtensionType.PaymentInformation]: 'view.AutoTransactions.Details.Triggers.ExtensionType.PaymentInformation',
    [ExtensionType.RecipientParty]: 'view.AutoTransactions.Details.Triggers.ExtensionType.RecipientParty',
    [ExtensionType.SellerParty]: 'view.AutoTransactions.Details.Triggers.ExtensionType.SellerParty',
};

export const getExtensionTypes = (): Array<{
    type: ExtensionType;
    value: string;
}> => {
    return Object.keys(extensionTypesObject).map((key: string) => {
        const obj: {
            type: ExtensionType;
            value: string;
        } = {
            type: null,
            value: null,
        };
        switch (Number(key)) {
            case ExtensionType.PaymentInformation:
                obj.type = ExtensionType.PaymentInformation;
                obj.value = extensionTypesObject[key];
                break;
            case ExtensionType.SellerParty:
                obj.type = ExtensionType.SellerParty;
                obj.value = extensionTypesObject[key];
                break;
            case ExtensionType.InvoiceSumGroup:
                obj.type = ExtensionType.InvoiceSumGroup;
                obj.value = extensionTypesObject[key];
                break;
            case ExtensionType.InvoiceRows:
                obj.type = ExtensionType.InvoiceRows;
                obj.value = extensionTypesObject[key];
                break;
            case ExtensionType.RecipientParty:
                obj.type = ExtensionType.RecipientParty;
                obj.value = extensionTypesObject[key];
                break;
            case ExtensionType.FactorParty:
                obj.type = ExtensionType.FactorParty;
                obj.value = extensionTypesObject[key];
                break;
            case ExtensionType.DeliveryParty:
                obj.type = ExtensionType.DeliveryParty;
                obj.value = extensionTypesObject[key];
                break;
            case ExtensionType.BuyerParty:
                obj.type = ExtensionType.BuyerParty;
                obj.value = extensionTypesObject[key];
                break;
            case ExtensionType.PayerParty:
                obj.type = ExtensionType.PayerParty;
                obj.value = extensionTypesObject[key];
                break;
            case ExtensionType.InvoiceInformation:
                obj.type = ExtensionType.InvoiceInformation;
                obj.value = extensionTypesObject[key];
                break;
            default:
                break;
        }
        return obj;
    });
};

export const getExtensionTypeLabel = (extensionType: ExtensionType) => extensionTypesObject[extensionType];
