import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { GlobalState } from '../../rootReducer';
import { getCurrentUserLoaded } from '../user/UserSelectors';
import { getBoCurrentUser } from '../../views/back-office/common/BoUserActions';
import { selectBoCurrentUserLoadable } from '../../common/user/UserSelectors';

import Routes, { DispatchProps, StateProps } from './Routes';
import withSuspense from '../hocs/withSuspense';
import { getCurrencies } from '../user/UserActions';

const mapStateToProps = (state: GlobalState): StateProps => {
    return {
        boCurrentUserLoadable: selectBoCurrentUserLoadable(state),
        currentUserLoaded: getCurrentUserLoaded(state),
    };
};

const mapDispatchToProps: DispatchProps = {
    getBoCurrentUser,
    getCurrencies,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withSuspense(Routes)));
