import * as React from 'react';
import Loadable from 'react-loadable';

const RouteLoading = (props: any) => {
    if (props.error) {
        return <p>Error loading page...</p>;
    } else if (props.timedOut) {
        return <p>Loading takes too long...</p>;
    } else if (props.pastDelay) {
        return <p>Loading...</p>;
    } else {
        return null;
    }
};

export default function RouterLoadable(opts: any) {
    const delay = 300;
    const timeout = 10000;

    return Loadable({ loading: RouteLoading, delay, timeout, ...opts });
}
