import { FieldArray, FieldArrayRenderProps, FormikProps } from 'formik';
import { cloneDeep, maxBy } from 'lodash-es';
import React, { useEffect, useState } from 'react';

import { createDataId } from '../../../../common/utils/dataId';
import FormikField from '../../../../common/utils/FormikField';
import { SwitchField } from '../../../../components/Switch/Switch';
import { TypeaheadAsyncField } from '../../../../components/Typeahead/TypeaheadAsync';
import { AutoTransactionMatchType, AutoTransactionMeta, CombinationOptionType, CustomCostObjectiveFullDTO, MetaField, SearchType, VatCodeDTO } from '../../../../services/types/ApiTypes';
import api from '../../../../services/ApiServices';
import fieldNames, { AutomationStepFields, AutoTransactionsAddViewFields } from '../../autoTransactionAddViewFields';
import { AutomationStepCombineFieldItem, AutomationStepCombineFields, getAutomationStepFieldId } from '../../AutoTransactionsAddViewHelper';
import { notify } from '../../../../common/utils/notify';
import AutomationStep from './AutomationStep';
import CombinationOptionSelection from './components/CombinationOptionSelection';

import './AutomationSteps.scss';

export interface AutomationStepsProps {
    automationSteps: AutomationStepFields[];
    userVatCodes: VatCodeDTO[];
    t: any;
    formik: FormikProps<AutoTransactionsAddViewFields>;
    handleSplitAllocation: (rowIndex: number, vatRateIndex: number, allocationIndex: number, formik: FormikProps<AutoTransactionsAddViewFields>, count: number) => void;
    fieldNamePrefix: string;
    customCostObjectives: CustomCostObjectiveFullDTO[];
    handleStepDragging: (isDragging: boolean) => void;
    companyVatRates: number[];
}

export const getCombineFields = async (): Promise<AutomationStepCombineFieldItem[]> => {
    const response = await api.autoTransaction.getLastMetaInfosBySupplier(null, '');
    return [
        ...(response &&
            response.data
                .filter(({ Field, Value }: AutoTransactionMeta) => {
                    // filter out and return error if non-meta field does not exist in our pre-defined list.
                    if (Field === MetaField.None && AutomationStepCombineFields[Value] === undefined) {
                        console.error(new Error(`AutomationStepCombineFields has no child named '${JSON.stringify({ Field, Value })}'!`));
                        return false;
                    }
                    return !!AutomationStepCombineFields[Value] || Field !== MetaField.None;
                })
                .map(({ Field, Value }: AutoTransactionMeta) => ({
                    field: Field,
                    name: Value,
                    value: !Field ? Value : `ItemReserve:::${Field}:::${Value}`, // we're using ItemReserve:::{MetaField.Field}:::{MetaField.Value} here, so BE can split by delimiter ':::' to get correct values
                    label: Field ? 'component.AutoTransaction.Parameter.ItemReserve' : undefined,
                }))),
    ];
};

export const AutomationSteps = (props: AutomationStepsProps) => {
    const { t, customCostObjectives, fieldNamePrefix, automationSteps, userVatCodes, handleSplitAllocation, formik } = props;

    const [defaultOpenStep, setDefaultOpenStep] = useState<number>();
    const [allCombineFields, setAllCombineFields] = useState<AutomationStepCombineFieldItem[]>([]);

    useEffect(() => {
        const getFields = async () => await getCombineFields();
        getFields()
            .then((fields) => {
                if (!fields) {
                    notify.error(t('component.AutoTransaction.ErrorGettingMetaInfoBySupplier'));
                } else {
                    setAllCombineFields(fields);
                }
            })
            .catch((e) => {
                console.error('Failed loading MetaInfoBySupplier due to: ', e);
                notify.error(t('component.AutoTransaction.ErrorGettingMetaInfoBySupplier'));
            });
    }, []);

    const cloneStep = (automationStep: AutomationStepFields, automationStepsProps: FieldArrayRenderProps, index: number) => {
        const newStep = cloneDeep(automationStep);
        newStep.orderNo = automationSteps?.length > 0 ? maxBy(automationSteps, (o: AutomationStepFields) => o.orderNo).orderNo + 1 : 1;
        newStep.id = getAutomationStepFieldId();
        automationStepsProps.insert(index + 1, newStep);
    };

    return (
        <div className="tab-pane__content">
            <FieldArray
                name={`${fieldNamePrefix}`}
                render={(automationStepsProps: FieldArrayRenderProps) => (
                    <>
                        <div className={'rule-config-row transaction-rows-recreation-wrapper'}>
                            <div className={'rule-config-row__texts'}>
                                <span>
                                    <strong>
                                        {formik.values.recreateTransactionRows
                                            ? t('component.AutoTransaction.Tab.TransactionRows.RowsRecreationEnabled.On')
                                            : t('component.AutoTransaction.Tab.TransactionRows.RowsRecreationEnabled.Off')}
                                    </strong>
                                </span>
                                <span>
                                    {formik.values.recreateTransactionRows
                                        ? t('component.AutoTransaction.Tab.TransactionRows.RowsRecreationEnabled.On.Description')
                                        : t('component.AutoTransaction.Tab.TransactionRows.RowsRecreationEnabled.Off.Description')}
                                </span>
                            </div>
                            <div className={'rule-config-row__switch'}>
                                <FormikField component={SwitchField} name={fieldNames.recreateTransactionRows} dataId={fieldNames.recreateTransactionRows} />
                            </div>
                        </div>
                        <div className={'automation-step toggle-content--default'}>
                            {/* WORKFLOW IS ENABLED SWITCH */}
                            <div className={'toggle-content__trigger'}>
                                <span className={'toggle-content__toggle'} />
                                <div className={'toggle-content__trigger-content'}>
                                    <div className={'automation-step-items'}>
                                        {formik.values.workflow.enabled ? (
                                            <>
                                                <span className="automation-step__item">
                                                    {t('component.AutoTransaction.Assignment.AssignTo')}
                                                    &nbsp;
                                                    {t('component.AutoTransaction.Assignment.AssignTo.Workflow')}
                                                    &nbsp;
                                                </span>
                                                <FormikField
                                                    name={'workflow.assignee'}
                                                    wrapperClass="automation-step__item"
                                                    component={TypeaheadAsyncField}
                                                    dataId="workflow.assignee"
                                                    placeholder={t('component.AutoTransaction.PlaceHolder.ClickToSelect')}
                                                    inputProps={{
                                                        ['data-id']: createDataId('workflow.assignee', 'typeahead'),
                                                        onFocusPlaceholder: t('component.AutoTransaction.PlaceHolder.TypeToSearch'),
                                                        autosize: true,
                                                    }}
                                                    isTooltipError={true}
                                                    searchOnFocus={true}
                                                    loadItems={(name: string) =>
                                                        api.workflowTemplate
                                                            .getCompanyTemplates({
                                                                PagingOptions: {
                                                                    Page: 1,
                                                                    Count: 100,
                                                                },
                                                                Restrictions: [
                                                                    {
                                                                        Field: 'Name',
                                                                        Value: name,
                                                                        Values: null,
                                                                        FieldSearchType: SearchType.NotSelected,
                                                                    },
                                                                ],
                                                                SortItems: [],
                                                            })
                                                            .then((r) => {
                                                                return r.data.Items.map((workflowTemplate) => {
                                                                    return {
                                                                        value: workflowTemplate.Id,
                                                                        text: workflowTemplate.Name,
                                                                    };
                                                                });
                                                            })
                                                    }
                                                />
                                            </>
                                        ) : (
                                            <span>{t('component.AutoTransaction.Assignment.Enable')}</span>
                                        )}
                                    </div>
                                    <div className={'action-wrap'}>
                                        <FormikField component={SwitchField} name={'workflow.enabled'} dataId={'workflow.enabled'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {automationSteps &&
                            automationSteps.map((step, index) => (
                                <AutomationStep
                                    key={step.id}
                                    t={t}
                                    formik={formik}
                                    customCostObjectives={customCostObjectives}
                                    fieldNamePrefix={`${fieldNamePrefix}[${index}]`}
                                    allCombineFields={allCombineFields}
                                    automationStepIndex={index}
                                    automationStep={step}
                                    cloneStep={(e: AutomationStepFields) => cloneStep(e, automationStepsProps, index)}
                                    userVatCodes={userVatCodes}
                                    handleSplitAllocation={handleSplitAllocation}
                                    handleDeleteAutomationStep={automationStepsProps.remove}
                                    defaultOpen={defaultOpenStep === index}
                                    id={step.id}
                                    index={index}
                                    moveStep={automationStepsProps.swap}
                                    handleIsDragging={props.handleStepDragging}
                                    companyVatRates={props.companyVatRates}
                                />
                            ))}
                        <div className={'automation-step automation-step-add'}>
                            <div className="automation-step-add__icon" />
                            <div className="automation-step-add__dropdown ">
                                <CombinationOptionSelection
                                    t={t}
                                    callback={(automationStep: AutomationStepFields) => {
                                        if (automationStep.combinationOption !== CombinationOptionType.CombineBy) {
                                            setDefaultOpenStep(automationSteps.length);
                                        } else {
                                            automationStep.conditions[0].combineMatchType = AutoTransactionMatchType.Equal;
                                        }
                                        automationStep.orderNo = automationSteps.length > 0 ? maxBy(automationSteps, (o: AutomationStepFields) => o.orderNo).orderNo + 1 : 1;
                                        automationStepsProps.push(automationStep);
                                    }}
                                    icon={true}
                                />
                            </div>
                        </div>
                    </>
                )}
            />
        </div>
    );
};

export default AutomationSteps;
