import { connect } from 'react-redux';

import { GlobalState } from '../../../../../rootReducer';
import { getHistoryList, searchPoHistoryList, setPoHistoryPagingOptions, sortPoHistoryList } from '../../../PurchaseOrdersAddViewActions';
import { selectPurchaseOrderHistoryLoadable, selectPurchaseOrderHistorySearchParams } from '../../../PurchaseOrdersAddViewSelectors';
import withSuspense from '../../../../../common/hocs/withSuspense';
import HistoryList, { DispatchProps, Props } from './HistoryList';

const mapStateToProps = (state: GlobalState): Pick<Props, 'purchaseOrderHistoryLoadable' | 'searchParams'> => ({
    purchaseOrderHistoryLoadable: selectPurchaseOrderHistoryLoadable(state),
    searchParams: selectPurchaseOrderHistorySearchParams(state),
});

const mapDispatchToProps: DispatchProps = {
    getHistoryList,
    searchPoHistoryList,
    setPoHistoryPagingOptions,
    sortPoHistoryList,
};

export default connect(mapStateToProps, mapDispatchToProps)(withSuspense(HistoryList));
