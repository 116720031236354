import classNames from 'classnames';
import { FieldArray, FieldArrayRenderProps, FormikProps } from 'formik';
import { differenceBy, isEmpty, sortBy, uniqBy } from 'lodash-es';
import * as React from 'react';

import { addDataId, createDataId } from '../../../../common/utils/dataId';
import { BaseComponent } from '../../../../components/BaseComponent';
import { DropdownMenu, DropdownMenuItem } from '../../../../components/DropdownMenu/DropdownMenu';
import { CustomCostObjectiveFullDTO } from '../../../../services/types/ApiTypes';
import { AutoTransactionsAddViewFields, DimensionFields } from '../../autoTransactionAddViewFields';
import { createDimensionFields } from '../../AutoTransactionsAddViewHelper';

import { Dimension } from './Dimension';
import Link, { LinkType } from '../../../../components/Link/Link';

export interface DimensionsProps {
    children?: React.ReactNode;
    className?: string;
    dimensions: DimensionFields[];
    isVisible?: boolean;
    t: any;
    formik: FormikProps<AutoTransactionsAddViewFields>;
    fieldNamePrefix: string;
    customCostObjectives: CustomCostObjectiveFullDTO[];
    dataId: string;
}

export class Dimensions extends BaseComponent<DimensionsProps> {
    static defaultProps: Partial<DimensionsProps> = {
        isVisible: true,
    };
    static addCustomField(customField: DimensionFields, push: any) {
        push(customField);
    }
    getAddableDimensions(customCostObjectives: CustomCostObjectiveFullDTO[], customFields: DimensionFields[]) {
        const customFieldsTemplates = customCostObjectives.map(
            (customCostObjective: CustomCostObjectiveFullDTO): DimensionFields => {
                return createDimensionFields(customCostObjective);
            },
        );
        const addableDimensions = sortBy(
            uniqBy(differenceBy(customFieldsTemplates, customFields, 'customCostObjective.value'), (value) => {
                return value;
            }),
            (val) => {
                return val.customCostObjective.value;
            },
        );
        return addableDimensions;
    }
    render() {
        const { className, customCostObjectives, children, isVisible, dimensions, t, fieldNamePrefix, formik } = this.props;
        const renderDimensions = dimensions.sort((a, b) => a.orderNo - b.orderNo);
        const classes = classNames('dimensions', className);
        const addableDimensions = this.getAddableDimensions(customCostObjectives, dimensions);
        return (
            isVisible && (
                <div className={classes} data-id={this.props.dataId}>
                    {children ? (
                        children
                    ) : (
                        <FieldArray
                            name={`${fieldNamePrefix}`}
                            render={(customFieldsArrayProps: FieldArrayRenderProps) => (
                                <>
                                    {renderDimensions.map((dimension: DimensionFields, index: number) => (
                                        <Dimension
                                            formik={formik}
                                            fieldNamePrefix={`${fieldNamePrefix}[${index}]`}
                                            key={dimension.orderNo}
                                            dimension={dimension}
                                            dimensionIndex={index}
                                            handleDeleteDimension={customFieldsArrayProps.remove}
                                            t={t}
                                        />
                                    ))}
                                    {!isEmpty(addableDimensions) && (
                                        <div className="dimension">
                                            <DropdownMenu
                                                dataId={addDataId(`${fieldNamePrefix}.addDimensionDropdown`, '')}
                                                items={addableDimensions.map((customField, index) => (
                                                    <DropdownMenuItem
                                                        key={index}
                                                        onClick={() => {
                                                            Dimensions.addCustomField(customField, customFieldsArrayProps.push);
                                                        }}
                                                        dataId={createDataId('option', index)}
                                                    >
                                                        <span>{customField.customCostObjective.text}</span>
                                                    </DropdownMenuItem>
                                                ))}
                                            >
                                                <Link dataId={createDataId(fieldNamePrefix, 'addDimensionDropdown', 'trigger')} linkType={LinkType.TEXT} className={'add-dimension'} button={true}>
                                                    {t('component.transactionRows.newDimension')}
                                                </Link>
                                            </DropdownMenu>
                                        </div>
                                    )}
                                </>
                            )}
                        />
                    )}
                </div>
            )
        );
    }
}
