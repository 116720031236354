import { Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import * as Yup from 'yup';
import FormikField from '../../../../common/utils/FormikField';

import { BaseStatefulComponent } from '../../../../components/BaseStatefulComponent';
import { Button, ButtonType } from '../../../../components/Buttons/Button';
import { ContentBlock } from '../../../../components/ContentBlock/ContentBlock';
import { ContentBlockFooter, ContentBlockFooterType } from '../../../../components/ContentBlock/ContentBlockFooter';
import { ContentBlockHeader } from '../../../../components/ContentBlock/ContentBlockHeader';
import Controls from '../../../../components/Controls/Controls';
import { ICONS } from '../../../../components/Icon/Icon';
import { TextInputField, TextInputType } from '../../../../components/TextInput/TextInput';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import { TypeaheadAsyncField, TypeaheadItem } from '../../../../components/Typeahead/TypeaheadAsync';
import { PurchaseOrderTaskAction, SearchType, SortDirection, WorkflowTemplateDTO } from '../../../../services/types/ApiTypes';
import api from '../../../../services/ApiServices';
import { DispatchProps } from '../../PurchaseOrdersAddView';

import './AssignWorkflow.scss';
import { createDataId, DataId } from '../../../../common/utils/dataId';
import withSuspense from '../../../../common/hocs/withSuspense';

export interface Props extends Pick<DispatchProps, 'doTaskAction'> {
    canAssignByParentComponent?: () => Promise<string>;
    onClose: () => void;
    dataId?: DataId;
    isEditting: boolean;
}

export type AssignWorkflowProps = WithTranslation & Props;

interface AssignFormFields {
    comment: string;
    workflow: TypeaheadItem<WorkflowTemplateDTO>;
}

interface State {
    focusComment: boolean;
    focusWorkflow: boolean;
    assignErrorText: string;
}

class AssignWorkflow extends BaseStatefulComponent<AssignWorkflowProps, State> {
    private initialValues: AssignFormFields = {
        workflow: undefined,
        comment: undefined,
    };

    private validationSchema: any;

    constructor(props: AssignWorkflowProps) {
        super(props);
        const { t } = this.props;
        this.state = {
            focusComment: false,
            focusWorkflow: true,
            assignErrorText: null,
        };
        this.validationSchema = Yup.object().shape({
            workflow: Yup.string()
                .required(t('view.PurchaseOrders.Workflow.WorkflowRequired'))
                .nullable(true),
        });
    }

    componentDidMount() {
        this.focusWorkflow();
        if (this.props.canAssignByParentComponent) {
            this.props.canAssignByParentComponent().then((result) => {
                this.setState({ assignErrorText: result });
            });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleEscape);
    }

    handleEscape = (e: KeyboardEvent) => {
        if (['Escape', 'Esc'].includes(e.key)) {
            this.props.onClose();
        }
    };

    handleWorkflowMenuClose = () => {
        window.removeEventListener('keydown', this.handleEscape);
        setTimeout(() => {
            window.addEventListener('keydown', this.handleEscape);
        }, 100);
    };

    handleAssign = (fields: AssignFormFields) => {
        if (!fields.workflow) {
            return;
        }
        this.props.onClose();
        this.props.doTaskAction(fields.workflow.value.Id, fields.comment, PurchaseOrderTaskAction.Created);
    };

    getWorkflowByName = (name: string) => {
        return api.workflowTemplate
            .getCompanyTemplates({
                PagingOptions: {
                    Count: 25,
                    Page: 0,
                },
                Restrictions: [
                    {
                        Field: 'Name',
                        Value: name,
                        Values: undefined,
                        FieldSearchType: SearchType.NotSelected,
                    },
                    {
                        Field: 'IsActive',
                        Value: true,
                        Values: undefined,
                        FieldSearchType: SearchType.NotSelected,
                    },
                ],
                SortItems: [
                    {
                        SortColumn: 'Name',
                        SortDirection: SortDirection.Asc,
                    },
                ],
            })
            .then((r) => r.data.Items);
    };

    getWorkflowText = (item: WorkflowTemplateDTO) => {
        return item.Name;
    };

    handleWorkflowChange = (item: TypeaheadItem<WorkflowTemplateDTO>) => {
        if (this.props.canAssignByParentComponent) {
            this.props.canAssignByParentComponent().then((result) => {
                this.setState({ assignErrorText: result });
            });
        }
        if (item && item.value) {
            this.setState(
                {
                    focusComment: true,
                    focusWorkflow: false,
                },
                () => {
                    // reset the focusComment for next selections to work also
                    this.setState({
                        focusComment: false,
                    });
                },
            );
        }
    };

    focusWorkflow = () => {
        setTimeout(() => {
            this.setState(
                {
                    focusWorkflow: true,
                },
                () => {
                    this.setState({
                        focusWorkflow: false,
                    });
                },
            );
        }, 0);
    };

    render() {
        const { t, isEditting } = this.props;
        return (
            <>
                <Formik initialValues={this.initialValues} onSubmit={this.handleAssign} validationSchema={this.validationSchema}>
                    {(formik: FormikProps<AssignFormFields>) => (
                        <Form>
                            <ContentBlock className="assign-workflow" loading={false} isMuted={isEditting} dataId={this.props.dataId || 'assignWorkflow'}>
                                <ContentBlockHeader>
                                    <div>
                                        <h2>{t('view.PurchaseOrders.Workflow.AssignTitle')}</h2>
                                    </div>
                                    <Tooltip content={t('view.PurchaseOrders.Workflow.Close')}>
                                        <Button
                                            buttonType={ButtonType.ICON}
                                            icon={ICONS.PLUS}
                                            iconRotation={45}
                                            onClick={this.props.onClose}
                                            dataId={createDataId(this.props.dataId || 'assignWorkflow', 'close')}
                                        />
                                    </Tooltip>
                                    <div className="assign-workflow__content">
                                        <div>
                                            <FormikField
                                                component={TypeaheadAsyncField}
                                                name="workflow"
                                                onMenuClose={this.handleWorkflowMenuClose}
                                                placeholder={t('view.PurchaseOrders.Workflow.ClickToSearch')}
                                                loadData={this.getWorkflowByName}
                                                itemToText={this.getWorkflowText}
                                                searchOnFocus={true}
                                                noResultsText={t('view.PurchaseOrders.Workflow.NoResults')}
                                                onChange={this.handleWorkflowChange}
                                                limitTo={25}
                                                error={formik.touched.workflow && formik.errors.workflow}
                                                dataId="assignWorkflow.workflow"
                                                inputProps={{
                                                    onFocusPlaceholder: t('view.PurchaseOrders.Workflow.TypeToSearch'),
                                                    autofocus: this.state.focusWorkflow,
                                                    type: TextInputType.BORDERED,
                                                    label: t('view.PurchaseOrders.Workflow.WorkflowLabel'),
                                                }}
                                            />
                                            <div className="assign-workflow__comment-wrap">
                                                <FormikField
                                                    label={t('view.PurchaseOrders.Workflow.CommentLabel')}
                                                    textarea={true}
                                                    className="assign-workflow__comment"
                                                    component={TextInputField}
                                                    autofocus={this.state.focusComment}
                                                    placeholder={t('view.PurchaseOrders.Workflow.AddComment')}
                                                    name="comment"
                                                    type={TextInputType.BORDERED}
                                                    maxLength={400}
                                                    dataId={createDataId(this.props.dataId || 'assignWorkflow', 'workflowComment', 'field')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </ContentBlockHeader>
                                <ContentBlockFooter type={ContentBlockFooterType.NARROW}>
                                    <Controls reverse={true}>
                                        <Tooltip content={this.state.assignErrorText}>
                                            <div>
                                                <Button disabled={!!this.state.assignErrorText} dataId={createDataId(this.props.dataId || 'assignWorkflow', 'assign')} type="submit">
                                                    {t('view.PurchaseOrders.Workflow.AssignButton')}
                                                </Button>
                                            </div>
                                        </Tooltip>
                                        <Button dataId={createDataId(this.props.dataId || 'assignWorkflow', 'cancel')} buttonType={ButtonType.SECONDARY} onClick={this.props.onClose}>
                                            {t('views.global.Cancel')}
                                        </Button>
                                    </Controls>
                                </ContentBlockFooter>
                            </ContentBlock>
                        </Form>
                    )}
                </Formik>
            </>
        );
    }
}

export default withSuspense(withTranslation()(AssignWorkflow));
