import React from 'react';
import { useTranslation } from 'react-i18next';
import { cloneDeep, set } from 'lodash-es';

import { rowFieldNames } from '../../../purchase-orders-add/purchaseOrderAddViewFields';
import { ContentBlockHeader } from '../../../../components/ContentBlock/ContentBlockHeader';
import ContentBlockHeading from '../../../../components/ContentBlock/ContentBlockHeading';
import { createDataId } from '../../../../common/utils/dataId';
import Table from '../../../../components/Table/Table';
import { DataTableHeader } from '../../../../components/Table/TableHeader';
import TableRow from '../../../../components/Table/TableRow';
import TableHead from '../../../../components/Table/TableHead';
import TableBody from '../../../../components/Table/TableBody';
import { ICONS } from '../../../../components/Icon/Icon';
import TableCell, { ResponsiveCellType } from '../../../../components/Table/TableCell';
import { Button, ButtonIconPlacement, ButtonType } from '../../../../components/Buttons/Button';
import { DECIMAL_ROWS_VAT_REGEXP_POSITIVE_PERCENT } from '../../../../common/utils/validators';
import { TextInput, TextInputType } from '../../../../components/TextInput/TextInput';
import { getInitialRowObject, getSumByField, inputPlaceholder, rowValueCalculationFns } from '../../PurchaseOrdersNewHelper';
import ConfirmationTooltip from '../../../../components/ConfirmationTooltip/ConfirmationTooltip';
import { dataId } from './RowsHelper';
import { RowsProps } from './RowsType';

import './Rows.scss';

const Rows: React.FC<RowsProps> = (props) => {
    const { rows, setPurchaseOrderRowValues, onChanged } = props;
    const { t } = useTranslation();

    const handleCellChange = (cellValue: any, dataKey: string, index: number) => {
        const row = cloneDeep(rows[index]);
        const rowValueCalculationFn = rowValueCalculationFns[dataKey];
        set(row, dataKey, cellValue);

        if (rowValueCalculationFn) {
            rowValueCalculationFn(row);
        }
        const newRows = [...rows];
        newRows[index] = row;
        // temp fix for missed input focus on tab navigation
        setTimeout(() => {
            setPurchaseOrderRowValues(newRows);
        }, 0);
        onChanged?.();
    };

    const handleDelete = (i: number) => {
        const newRows = [...rows];
        newRows.splice(i, 1);
        setPurchaseOrderRowValues(newRows);
        onChanged?.();
    };

    const handleAddNewRow = () => {
        const newRows = [...rows, getInitialRowObject()];
        setPurchaseOrderRowValues(newRows);
        onChanged?.();
    };

    return (
        <div className="purchase-order-rows">
            <ContentBlockHeader>
                <ContentBlockHeading dataId={createDataId(dataId, 'title')}>{t('view.PurchaseOrders.Rows.Title')}</ContentBlockHeading>
            </ContentBlockHeader>
            <Table>
                <DataTableHeader data-id={createDataId(dataId, 'Table', 'Header')}>
                    <TableRow>
                        <TableHead style={{ width: '2%' }} columnName="OrderNo" dataId={createDataId(dataId, 'TableHead', 'OrderNumber')}>
                            {'#'}
                        </TableHead>
                        <TableHead style={{ width: '25%' }} columnName="Description" dataId={createDataId(dataId, 'TableHead', 'Description')}>
                            {t('component.invoiceRows.description')}
                        </TableHead>
                        <TableHead style={{ width: '8%' }} columnName="Quantity" dataId={createDataId(dataId, 'TableHead', 'Quantity')}>
                            {t('component.invoiceRows.qty')}
                        </TableHead>
                        <TableHead style={{ width: '8%' }} columnName="Unit" dataId={createDataId(dataId, 'TableHead', 'Unit')}>
                            {t('component.invoiceRows.unit')}
                        </TableHead>
                        <TableHead style={{ width: '10%' }} columnName="Price" dataId={createDataId(dataId, 'TableHead', 'Price')}>
                            {t('component.invoiceRows.price')}
                        </TableHead>
                        <TableHead style={{ width: '10%' }} columnName="NET" dataId={createDataId(dataId, 'TableHead', 'SumWithoutVat')}>
                            {t('component.invoiceRows.net')}
                        </TableHead>
                        <TableHead style={{ width: '8%' }} columnName="VATPercent" dataId={createDataId(dataId, 'TableHead', 'VatRate')}>
                            {`${t('component.invoiceRows.VAT')} %`}
                        </TableHead>
                        <TableHead style={{ width: '10%' }} columnName="VAT" dataId={createDataId(dataId, 'TableHead', 'VAT')}>
                            {t('component.invoiceRows.VAT')}
                        </TableHead>
                        <TableHead style={{ width: '10%' }} columnName="Total" dataId={createDataId(dataId, 'TableHead', 'Total')}>
                            {t('component.invoiceRows.total')}
                        </TableHead>
                        <TableHead style={{ width: '5%' }} columnName="EditButton" dataId={createDataId(dataId, 'TableHead', 'EditButton')}>
                            {null}
                        </TableHead>
                    </TableRow>
                </DataTableHeader>
                <TableBody>
                    <>
                        {rows?.map((row: any, index: number) => (
                            <TableRow id={index} key={row.Key} data-id={createDataId(dataId, 'InvoiceRow.row', index)} isEditting>
                                <TableCell dataId={createDataId(dataId, index, 'Cell', 'OrderNumber')} className="purchase-order-rows__cell-order-number">
                                    {`${index + 1}.`}
                                </TableCell>
                                <TableCell dataId={createDataId(dataId, index, 'Cell', 'Description')} className="purchase-order-rows__text-area">
                                    <TextInput
                                        showTooltipOnOverflow
                                        textarea
                                        onlyChangeOnBlur
                                        hideError
                                        maxLength={256}
                                        dataId={createDataId(dataId, index, 'Input', 'Description')}
                                        value={row[rowFieldNames.Description]}
                                        placeholder={inputPlaceholder}
                                        type={TextInputType.BORDERED}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            handleCellChange(e.target.value, rowFieldNames.Description, index);
                                        }}
                                    />
                                </TableCell>
                                <TableCell dataId={createDataId(dataId, index, 'Cell', 'Quantity')}>
                                    <TextInput
                                        showTooltipOnOverflow
                                        onlyChangeOnBlur
                                        compareOnBlur
                                        replaceComma
                                        addThousandsSeparator
                                        precisionCount={4}
                                        dataId={createDataId(dataId, index, 'Input', 'Quantity')}
                                        value={row[rowFieldNames.Quantity]}
                                        placeholder={inputPlaceholder}
                                        type={TextInputType.BORDERED}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            handleCellChange(e.target.value, rowFieldNames.Quantity, index);
                                        }}
                                    />
                                </TableCell>
                                <TableCell dataId={createDataId(dataId, index, 'Cell', 'Unit')} className="purchase-order-rows__cell-unit">
                                    <TextInput
                                        showTooltipOnOverflow
                                        onlyChangeOnBlur
                                        dataId={createDataId(dataId, index, 'Input', 'Unit')}
                                        value={row[rowFieldNames.Unit]}
                                        placeholder={inputPlaceholder}
                                        maxLength={16}
                                        type={TextInputType.BORDERED}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            handleCellChange(e.target.value, rowFieldNames.Unit, index);
                                        }}
                                    />
                                </TableCell>
                                <TableCell dataId={createDataId(dataId, index, 'Cell', 'Price')}>
                                    <TextInput
                                        showTooltipOnOverflow
                                        onlyChangeOnBlur
                                        compareOnBlur
                                        replaceComma
                                        precisionCount={4}
                                        dataId={createDataId(dataId, index, 'Input', 'Price')}
                                        value={row[rowFieldNames.UnitPrice]}
                                        placeholder={inputPlaceholder}
                                        type={TextInputType.BORDERED}
                                        addThousandsSeparator
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            handleCellChange(e.target.value, rowFieldNames.UnitPrice, index);
                                        }}
                                    />
                                </TableCell>
                                <TableCell dataId={createDataId(dataId, index, 'Cell', 'SumWithoutVat')}>
                                    <TextInput
                                        showTooltipOnOverflow
                                        onlyChangeOnBlur
                                        compareOnBlur
                                        replaceComma
                                        hideError
                                        addThousandsSeparator
                                        dataId={createDataId(dataId, index, 'Input', 'SumWithoutVat')}
                                        value={row[rowFieldNames.SumWithoutVat]}
                                        placeholder={inputPlaceholder}
                                        type={TextInputType.BORDERED}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            handleCellChange(e.target.value, rowFieldNames.SumWithoutVat, index);
                                        }}
                                    />
                                </TableCell>
                                <TableCell dataId={createDataId(dataId, index, 'Cell', 'VatRate')} className="purchase-order-rows__cell-vat">
                                    <TextInput
                                        showTooltipOnOverflow
                                        onlyChangeOnBlur
                                        compareOnBlur
                                        replaceComma
                                        hideError
                                        replaceMinus
                                        dataId={createDataId(dataId, index, 'Input', 'VatRate')}
                                        value={row[rowFieldNames.Vat]}
                                        placeholder={inputPlaceholder}
                                        type={TextInputType.BORDERED}
                                        validCharacters={DECIMAL_ROWS_VAT_REGEXP_POSITIVE_PERCENT}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            handleCellChange(e.target.value, rowFieldNames.Vat, index);
                                        }}
                                    />
                                </TableCell>
                                <TableCell dataId={createDataId(dataId, index, 'Cell', 'VAT')}>
                                    <TextInput
                                        showTooltipOnOverflow
                                        onlyChangeOnBlur
                                        compareOnBlur
                                        replaceComma
                                        hideError
                                        addThousandsSeparator
                                        dataId={createDataId(dataId, index, 'Input', 'VAT')}
                                        value={row[rowFieldNames.VatAmount]}
                                        placeholder={inputPlaceholder}
                                        type={TextInputType.BORDERED}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            handleCellChange(e.target.value, rowFieldNames.VatAmount, index);
                                        }}
                                    />
                                </TableCell>
                                <TableCell dataId={createDataId(dataId, index, 'Cell', 'Total')}>
                                    <TextInput
                                        showTooltipOnOverflow
                                        onlyChangeOnBlur
                                        compareOnBlur
                                        replaceComma
                                        hideError
                                        addThousandsSeparator
                                        dataId={createDataId(dataId, index, 'Input', 'Total')}
                                        value={row[rowFieldNames.Total]}
                                        placeholder={inputPlaceholder}
                                        type={TextInputType.BORDERED}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            handleCellChange(e.target.value, rowFieldNames.Total, index);
                                        }}
                                    />
                                </TableCell>
                                <TableCell
                                    className="purchase-order-rows__cell-buttons"
                                    dataId={createDataId(dataId, index, 'Cell', 'EditButton')}
                                    responsiveCellType={ResponsiveCellType.ACTIONS}
                                    nowrap
                                >
                                    <ConfirmationTooltip
                                        dataId={createDataId(dataId, index, 'Button', 'Discard')}
                                        type="alert"
                                        title={t('view.PurchaseOrders.Rows.DeleteRow')}
                                        confirmButtonIcon={ICONS.DELETE_24}
                                        onConfirm={() => handleDelete(index)}
                                    >
                                        <Button
                                            iconPlacement={ButtonIconPlacement.LEFT}
                                            dataId={createDataId(createDataId(dataId, index, 'Button', 'Discard'))}
                                            icon={ICONS.DELETE_24}
                                            className="icon-button confirmation-tooltip__table-body-discard-button"
                                            buttonType={ButtonType.ICON}
                                        />
                                    </ConfirmationTooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow className="icon-button purchase-order-rows__table-footer">
                            <TableCell dataId={createDataId(dataId, 'OrderNumber', 'Cell', 'Buttons')}> </TableCell>
                            <TableCell dataId={createDataId(dataId, 'Description', 'Cell', 'empty')}>
                                <Button
                                    iconPlacement={ButtonIconPlacement.LEFT}
                                    onClick={handleAddNewRow}
                                    dataId={createDataId(dataId, 'Button', 'AddNew')}
                                    icon={ICONS.ADD_24}
                                    className="icon-button"
                                    buttonType={ButtonType.HEADER_SECONDARY}
                                >
                                    {t('component.invoiceRows.addRow')}
                                </Button>
                            </TableCell>
                            <TableCell dataId={createDataId(dataId, 'Quantity', 'Cell', 'empty')}> </TableCell>
                            <TableCell dataId={createDataId(dataId, 'Unit', 'Cell', 'empty')}> </TableCell>
                            <TableCell dataId={createDataId(dataId, 'Price', 'Cell', 'empty')}>SUM</TableCell>
                            <TableCell dataId={createDataId(dataId, 'SumWithoutVat', 'Cell', 'empty')}> {getSumByField(rows, 'SumWithoutVat')}</TableCell>
                            <TableCell dataId={createDataId(dataId, 'VatRate', 'Cell', 'empty')}> </TableCell>
                            <TableCell dataId={createDataId(dataId, 'VatAmount', 'Cell', 'empty')}>{getSumByField(rows, 'VatAmount')}</TableCell>
                            <TableCell dataId={createDataId(dataId, 'VatTotal', 'Cell', 'empty')}>{getSumByField(rows, 'Total')}</TableCell>
                            <TableCell dataId={createDataId(dataId, 'Delete', 'Cell', 'empty')}> </TableCell>
                        </TableRow>
                    </>
                </TableBody>
            </Table>
        </div>
    );
};

export default Rows;
