import React, { CSSProperties, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Draggable, DraggableProvided, DraggableStateSnapshot, Droppable, DropResult } from 'react-beautiful-dnd';

import { eventTrack } from '../../../../../../common/analytics/gtm';
import { Button, ButtonType } from '../../../../../Buttons/Button';
import { ModalScrollableFormWithFixedHeaderAndSubmit, ModalType } from '../../../../../Modal/Modal';
import Checkbox from '../../../../../Checkbox/Checkbox';
import Icon, { ICONS, IconSize } from '../../../../../Icon/Icon';
import { TableColumnsConfigurableItem, useColumnsSettingsContext } from '../../TableColumnsConfigurableContext';

import './TableSettingsModalView.scss';

export type TableSettingsTranslations = {
    title: string;
    description?: string;
};

export type TableSettingsModalProps = {
    dataId: string;
    translationsMap: Record<string, TableSettingsTranslations>;
    eventTrackName?: string;
};

export const TableSettingsModal: React.FC<TableSettingsModalProps> = (props) => {
    const { translationsMap, dataId, eventTrackName } = props;
    const [tempColumns, setTempColumns] = useState<TableColumnsConfigurableItem[] | null>(null);
    const [isDropDisabled, setIsDropDisabled] = useState<boolean>(false);
    const columnsConfig = useColumnsSettingsContext();

    const { t } = useTranslation();
    const isShowConfigModal = Boolean(tempColumns);

    const toggleShowConfigModal = useCallback(() => {
        if (eventTrackName && !isShowConfigModal) {
            eventTrack({
                event: eventTrackName,
                label: 'Open table column settings',
            });
        }
        setTempColumns(isShowConfigModal ? null : columnsConfig.columns);
    }, [isShowConfigModal, columnsConfig.columns]);

    const changeColumnsVisibility = useCallback(
        (column: TableColumnsConfigurableItem) => () => {
            if (eventTrackName) {
                eventTrack({
                    event: eventTrackName,
                    label: column.isVisible ? 'Unselect column visibility' : 'Select column visibility',
                });
            }
            setTempColumns((prev) => {
                return prev.map((col) => {
                    return col.id !== column.id ? col : { ...col, isVisible: !col.isVisible };
                });
            });
        },
        [],
    );

    const checkIsVisible = useCallback(
        (columnId: string): boolean => {
            const foundColumn = tempColumns?.find((col) => col.id === columnId);
            return Boolean(foundColumn?.isVisible);
        },
        [tempColumns],
    );

    const getDraggableStylesFixed = (provided: DraggableProvided, snapshot: DraggableStateSnapshot): React.CSSProperties => {
        const baseStyle: CSSProperties = { ...provided.draggableProps.style };

        if (snapshot.isDragging) {
            baseStyle.marginLeft = snapshot.isDragging ? '40px' : 'auto';
        }
        if (snapshot.isDropAnimating) {
            baseStyle.transitionDuration = '0.001s';
        }
        return baseStyle;
    };

    const handleSave = useCallback(async () => {
        if (eventTrackName) {
            eventTrack({
                event: eventTrackName,
                label: 'Save table column settings',
            });
        }
        await columnsConfig.updateSettings(tempColumns);
        toggleShowConfigModal();
    }, [tempColumns, columnsConfig]);

    const handleMouseOver = useCallback(
        (column: TableColumnsConfigurableItem) => () => {
            setIsDropDisabled(column.isFrozen);
        },
        [],
    );

    const handleDragEnd = useCallback(
        (result: DropResult) => {
            const fromIndex = result.source.index;
            const toIndex = result.destination?.index;
            if (!toIndex || fromIndex === toIndex || tempColumns?.[toIndex].isFrozen) {
                return;
            }

            if (eventTrackName) {
                eventTrack({
                    event: eventTrackName,
                    label: 'Reorder column',
                });
            }

            setTempColumns((prev) => {
                const newColumns = [...prev];
                const [removed] = newColumns.splice(fromIndex, 1);
                newColumns.splice(toIndex, 0, removed);
                return newColumns;
            });
        },
        [tempColumns],
    );

    return (
        <>
            <div className={'table-columns-settings-modal--toggle-button'}>
                <Button onClick={toggleShowConfigModal} buttonType={ButtonType.ICON} icon={ICONS.SETTINGS} />
            </div>
            <ModalScrollableFormWithFixedHeaderAndSubmit
                dataId={dataId}
                isOpen={isShowConfigModal}
                onClose={toggleShowConfigModal}
                className={'table-columns-settings-modal'}
                type={ModalType.FIXED_RIGHT_MIDDLE_SCROLL}
                footerButtonText={t('component.TableSettingsModal.SaveButton')}
                headerText={t('component.TableSettingsModal.Header')}
                onSubmitClick={handleSave}
            >
                <DragDropContext onDragEnd={handleDragEnd}>
                    <div className={'table-columns-settings-modal--content'}>
                        <Droppable droppableId={'modal-config'} direction="vertical" isDropDisabled={isDropDisabled}>
                            {(provided) => {
                                return (
                                    <div className={'table-columns-settings-modal--modal-list'} {...provided.droppableProps} ref={provided.innerRef}>
                                        {tempColumns?.map((column, index) => {
                                            const translation = translationsMap[column.id] || { title: column.id };
                                            return (
                                                <Draggable draggableId={column.id} index={index} key={column.id} isDragDisabled={column.isFrozen}>
                                                    {(provided, snapshot) => {
                                                        return (
                                                            <div
                                                                className={'table-columns-settings-modal--list-item'}
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                style={getDraggableStylesFixed(provided, snapshot)}
                                                                onMouseOver={handleMouseOver(column)}
                                                            >
                                                                <div className={'table-columns-settings-modal--list-item--checkbox'}>
                                                                    <Checkbox
                                                                        value={checkIsVisible(column.id)}
                                                                        onChange={changeColumnsVisibility(column)}
                                                                        name={column.id}
                                                                        disabled={column.isFrozen}
                                                                    />
                                                                    <div className={'table-columns-settings-modal--list-item--label'}>
                                                                        <span className={'table-columns-settings-modal--list-item--label__title'}>{t(translation.title)}</span>
                                                                        {translation.description && (
                                                                            <span className={'table-columns-settings-modal--list-item--label__description'}>{t(translation?.description)}</span>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                {!column.isFrozen && (
                                                                    <div className={'table-columns-settings-modal--list-item--drag-handler'} {...provided.dragHandleProps}>
                                                                        <Icon iconName={ICONS.DRAG_HANDLER_LONG} size={IconSize.SM} />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        );
                                                    }}
                                                </Draggable>
                                            );
                                        })}
                                        {provided.placeholder}
                                    </div>
                                );
                            }}
                        </Droppable>
                    </div>
                </DragDropContext>
            </ModalScrollableFormWithFixedHeaderAndSubmit>
        </>
    );
};
