import classNames from 'classnames';
import { FastField, FormikProps } from 'formik';
import { get, isEmpty } from 'lodash-es';
import * as React from 'react';

import { addDataId, createDataId } from '../../../../common/utils/dataId';
import FormikField from '../../../../common/utils/FormikField';
import { numericSortCodes } from '../../../../common/utils/numericSort';
import { BaseComponent } from '../../../../components/BaseComponent';
import { Button, ButtonType } from '../../../../components/Buttons/Button';
import { IconSize, ICONS } from '../../../../components/Icon/Icon';
import { TextInputType } from '../../../../components/TextInput/TextInput';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import { TypeaheadItem } from '../../../../components/Typeahead/Typeahead';
import { TypeaheadAsyncField } from '../../../../components/Typeahead/TypeaheadAsync';
import { AccountDTO, BaseSearch, CostObjectiveType, DimensionDTO, SearchType } from '../../../../services/types/ApiTypes';
import api from '../../../../services/ApiServices';
import { AutoTransactionsAddViewFields, DimensionFields } from '../../autoTransactionAddViewFields';
import { getCustomCostObjectiveName } from '../../AutoTransactionsAddViewHelper';

import './Dimension.scss';

export interface DimensionProps {
    className?: string;
    dimension: DimensionFields;
    dimensionIndex: number;
    handleDeleteDimension: any;
    formik: FormikProps<AutoTransactionsAddViewFields>;
    fieldNamePrefix: string;
    t: any;
}

export class Dimension extends BaseComponent<DimensionProps> {
    constructor(props: DimensionProps) {
        super(props);
    }
    getAccounts = (accountName: string) => {
        const searchParams: BaseSearch = {
            PagingOptions: {
                Count: 100,
                Page: 1,
            },
            Restrictions: [],
            SortItems: [],
        };
        if (!isEmpty(accountName)) {
            searchParams.Restrictions = [
                {
                    Field: 'GeneralSearch',
                    Value: accountName,
                    Values: null,
                    FieldSearchType: SearchType.NotSelected,
                },
            ];
        }
        return api.user.getActiveAccountsForUser(searchParams).then((response) => {
            return response.data.Items.sort(numericSortCodes).map((item) => {
                return {
                    value: item.Id,
                    text: this.getAccountText(item),
                };
            });
        });
    };
    getAccountText = (account: AccountDTO) => {
        return account.Code + ' - ' + account.Description;
    };
    getVatCodes = (vatCodeName: string) => {
        const searchParams: BaseSearch = {
            PagingOptions: {
                Count: 100,
                Page: 1,
            },
            Restrictions: [],
            SortItems: [],
        };
        if (!isEmpty(vatCodeName)) {
            searchParams.Restrictions = [
                {
                    Field: 'GeneralSearch',
                    Value: vatCodeName,
                    Values: null,
                    FieldSearchType: SearchType.NotSelected,
                },
            ];
        }
        return api.user.getActiveVatCodesForUser(searchParams).then((response) => {
            return response.data.Items.sort(numericSortCodes).map((item) => {
                return {
                    value: item.Id,
                    text: `${item.Code} - ${item.Description}`,
                };
            });
        });
    };
    getCustomCostObjectives = (name: string) => {
        return api.customCostObjective.getByDescriptionPart(name, false).then((response) => response.data.map((item) => ({ value: item.Id, text: getCustomCostObjectiveName(item) })));
    };
    getDimensions = (name: string, dimension: DimensionFields): Promise<Array<TypeaheadItem<number>>> => {
        if (isEmpty(dimension.customCostObjective) || !dimension.customCostObjective.value) {
            return new Promise((resolve) => {
                resolve([]);
            });
        }
        const customCostObjectiveId = dimension.customCostObjective.value;
        return api.user
            .getActiveCostObjectiveItemsForUser(
                {
                    SortItems: [],
                    Restrictions: [
                        {
                            Field: 'GeneralSearch',
                            Value: name,
                            Values: null,
                            FieldSearchType: SearchType.NotSelected,
                        },
                    ],
                    PagingOptions: {
                        Page: 1,
                        Count: 100,
                    },
                },
                customCostObjectiveId,
            )
            .then((response) => {
                return response.data.Items.map((item) => ({
                    value: item.Id,
                    text: this.getDimensionsName(item),
                }));
            });
    };
    getDimensionsName = (dimension: DimensionDTO) => {
        return dimension.Code + ' - ' + dimension.Description;
    };
    handleCustomCostObjectiveChange = (customCostObjective: TypeaheadItem<number>) => {
        const { formik, fieldNamePrefix } = this.props;
        const previous: TypeaheadItem<number> = get(formik.values, `${fieldNamePrefix}.customCostObjective`);
        if (customCostObjective !== previous) {
            formik.setFieldValue(`${fieldNamePrefix}.dimension`, null);
        }
    };
    getCostObjectiveTypeElement = (dimension: DimensionFields) => {
        const { fieldNamePrefix, t } = this.props;
        const typeElem = {
            [CostObjectiveType.Account]: <span className={'dimension__type'}>{t('controller.accountListController.Account')}</span>,
            [CostObjectiveType.VatCode]: <span className={'dimension__type'}>{t('controller.accountListController.VAT_code')}</span>,
            [CostObjectiveType.CustomCostObjective]: (
                <FastField
                    component={TypeaheadAsyncField}
                    name={`${fieldNamePrefix}.customCostObjective`}
                    placeholder={t('component.AutoTransaction.Menu.ClickToAdd.Dimension')}
                    onlyChangeOnBlur={true}
                    loadItems={this.getCustomCostObjectives}
                    searchOnFocus={true}
                    isTooltipError={true}
                    positionFixed={true}
                    wrapperClass={'dimension__type'}
                    onChange={this.handleCustomCostObjectiveChange}
                    inputProps={{
                        ['data-id']: addDataId(`${fieldNamePrefix}.dimensionTypeTypeahead`, '.input'),
                        onFocusPlaceholder: t('Type to set rule name'),
                        autosize: true,
                        type: TextInputType.INLINE_TABLE,
                    }}
                />
            ),
        };
        return typeElem[dimension.type];
    };
    getDimensionValueElement = (dimension: DimensionFields) => {
        const { fieldNamePrefix, t } = this.props;
        const valueElem = {
            [CostObjectiveType.Account]: (
                <FastField
                    component={TypeaheadAsyncField}
                    wrapperClass={'dimension__value'}
                    name={`${fieldNamePrefix}.dimension`}
                    placeholder={t('component.transactionRows.chooseAnAccount')}
                    searchOnFocus={true}
                    isTooltipError={true}
                    loadItems={this.getAccounts}
                    onlyChangeOnBlur={true}
                    positionFixed={true}
                    inputProps={{
                        autosize: true,
                        pullRight: false,
                        ['data-id']: addDataId(`${fieldNamePrefix}.accountValueTypeahead`, '.input'),
                        onFocusPlaceholder: t('Type to set rule name'),
                        type: TextInputType.INLINE_TABLE,
                    }}
                />
            ),
            [CostObjectiveType.VatCode]: (
                <FastField
                    wrapperClass={'dimension__value'}
                    component={TypeaheadAsyncField}
                    name={`${fieldNamePrefix}.dimension`}
                    placeholder={t('component.transactionRows.chooseAVAT')}
                    searchOnFocus={true}
                    onlyChangeOnBlur={true}
                    loadItems={this.getVatCodes}
                    isTooltipError={true}
                    positionFixed={true}
                    inputProps={{
                        autosize: true,
                        pullRight: false,
                        ['data-id']: addDataId(`${fieldNamePrefix}.vatCodeValueTypeahead`, '.input'),
                        onFocusPlaceholder: t('Type to set rule name'),
                        type: TextInputType.INLINE_TABLE,
                    }}
                />
            ),
            [CostObjectiveType.CustomCostObjective]: (
                <FormikField
                    wrapperClass={'dimension__value'}
                    component={TypeaheadAsyncField}
                    isTooltipError={true}
                    searchOnFocus={true}
                    name={`${fieldNamePrefix}.dimension`}
                    placeholder={t('component.AutoTransaction.PlaceHolder.ClickToSet')}
                    loadItems={(name: string) => this.getDimensions(name, dimension)}
                    positionFixed={true}
                    dataId={`${fieldNamePrefix}.dimensionValueTypeahead`}
                    inputProps={{
                        ['data-id']: createDataId(`${fieldNamePrefix}.dimensionValueTypeahead`, 'input'),
                        autosize: true,
                        pullRight: false,
                        type: TextInputType.INLINE_TABLE,
                        hideError: false,
                    }}
                />
            ),
        };
        return valueElem[dimension.type];
    };
    render() {
        const { dimension, className, handleDeleteDimension, dimensionIndex, fieldNamePrefix, t } = this.props;
        const classes = classNames('dimension', className);
        return (
            <div className={classes}>
                {this.getCostObjectiveTypeElement(dimension)}
                {this.getDimensionValueElement(dimension)}
                <div className="action-wrap">
                    {!dimension.readOnly ? (
                        <Tooltip content={t('component.AutoTransaction.Menu.Delete')}>
                            <Button
                                dataId={addDataId(`${fieldNamePrefix}.delete`, '')}
                                buttonType={ButtonType.ICON_SQUARE}
                                icon={ICONS.DELETE}
                                iconSize={IconSize.SM}
                                onClick={() => handleDeleteDimension(dimensionIndex)}
                            />
                        </Tooltip>
                    ) : (
                        <span className={'button button--sm button--square-padding-sm'}>
                            <div className={'icon--sm '}>&nbsp;</div>
                        </span>
                    )}
                </div>
            </div>
        );
    }
}
