import { connect } from 'react-redux';

import { GlobalState } from '../../../../../rootReducer';
import {
    filterPoInvoices,
    getInvoicesList,
    getLinkedInvoicesCount,
    linkPurchaseOrderToInvoice,
    searchPoInvoicesList,
    setPoInvoicesPagingOptions,
    sortPoInvoicesList,
    unlinkPurchaseOrderFromInvoice,
} from './InvoicesListActions';
import withSuspense from '../../../../../common/hocs/withSuspense';
import InvoicesList, { DispatchProps, Props } from './InvoicesList';
import { selectPurchaseOrderLinkedInvoicesCount, selectPurchaseOrderInvoicesLoadable, selectPurchaseOrderInvoicesSearchParams, selectPurchaseOrderInvoicesFilterType } from './InvoicesListReducers';

const mapStateToProps = (state: GlobalState): Pick<Props, 'purchaseOrderLinkedInvoicesCount' | 'purchaseOrderInvoicesLoadable' | 'searchParams' | 'filterType'> => ({
    purchaseOrderLinkedInvoicesCount: selectPurchaseOrderLinkedInvoicesCount(state),
    purchaseOrderInvoicesLoadable: selectPurchaseOrderInvoicesLoadable(state),
    searchParams: selectPurchaseOrderInvoicesSearchParams(state),
    filterType: selectPurchaseOrderInvoicesFilterType(state),
});

const mapDispatchToProps: DispatchProps = {
    getInvoicesList,
    getLinkedInvoicesCount,
    searchPoInvoicesList,
    setPoInvoicesPagingOptions,
    sortPoInvoicesList,
    filterPoInvoices,
    linkPurchaseOrderToInvoice,
    unlinkPurchaseOrderFromInvoice,
};

export default connect(mapStateToProps, mapDispatchToProps)(withSuspense(InvoicesList));
