import React from 'react';
import { size } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import Tooltip from '../../../../components/Tooltip/Tooltip';

import './UserRoles.scss';

export interface UserRoleProps {
    name: string;
    key?: any;
    className?: string;
}

export interface UserRolesProps {
    userRoles: string[];
}

export const UserRole = ({ name, key, className }: UserRoleProps) => (
    <span key={key} className={`user-role ${className || ''}`}>
        {name}
    </span>
);

export const UserRoles = (props: UserRolesProps) => {
    const { t } = useTranslation();
    const { userRoles } = props;
    if (size(userRoles) === 0) {
        return null;
    }
    return size(userRoles) > 1 ? (
        <Tooltip content={userRoles && userRoles.join(', ')}>
            <UserRole name={`${userRoles.length} ${t('view.UserList.Column.Cell.Roles')}`} />
        </Tooltip>
    ) : (
        <UserRole name={`${userRoles[0]}`} />
    );
};
