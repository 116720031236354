import classNames from 'classnames';
import * as React from 'react';

import OverflowTooltip from '../Tooltip/OverflowTooltip';

import './OnFocusField.scss';

export interface OnFocusFieldProps {
    /**
     * Value which is rendered while component has no focus
     */
    textValue: React.ReactNode;
    placeholder: string;
    hasFocus?: boolean;
    onFocus?: () => void;
    disabled?: boolean;
    styles?: any;
    dataId: string;
}

interface OnFocusFieldState {
    hasFocus: boolean;
}

export class OnFocusField extends React.Component<OnFocusFieldProps, OnFocusFieldState> {
    private componentRootElement = React.createRef<HTMLSpanElement>();

    constructor(props: OnFocusFieldProps) {
        super(props);
        this.state = {
            hasFocus: this.props.hasFocus,
        };
    }

    componentDidUpdate(prevProps: OnFocusFieldProps) {
        if (this.state.hasFocus !== this.props.hasFocus && this.props.hasFocus !== prevProps.hasFocus) {
            this.setState({
                hasFocus: this.props.hasFocus,
            });
        }
    }

    handleFocus = () => {
        if (this.props.disabled || this.state.hasFocus) {
            return;
        }
        if (this.props.onFocus) {
            this.props.onFocus();
        } else {
            this.setState({
                hasFocus: true,
            });
        }
    };

    handleBlur = () => {
        if (this.props.disabled) {
            return;
        }
        if (!this.props.onFocus) {
            this.setState({
                hasFocus: false,
            });
        }
    };

    shouldComponentUpdate(nextProps: OnFocusFieldProps, nextState: OnFocusFieldState) {
        return (
            this.props.textValue !== nextProps.textValue || this.state.hasFocus !== nextState.hasFocus || this.props.hasFocus !== nextProps.hasFocus || this.props.placeholder !== nextProps.placeholder
        );
    }

    render() {
        const classes = classNames('on-focus-field', {
            'on-focus-field--has-focus': this.state.hasFocus,
            'on-focus-field--is-disabled': this.props.disabled,
            'on-focus-field--has-content': !!this.props.textValue,
        });
        const { styles, dataId } = this.props;
        const overflowTooltipStyles: any = {};
        if (styles.hasOwnProperty('height') && styles.height) {
            overflowTooltipStyles.lineHeight = styles.height;
        }
        return (
            <span
                data-id={dataId}
                style={styles}
                className={classes}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                onClick={this.handleFocus}
                tabIndex={this.state.hasFocus ? -1 : 0}
                ref={this.componentRootElement}
            >
                {this.state.hasFocus ? this.props.children : <OverflowTooltip style={overflowTooltipStyles}>{this.props.textValue ? this.props.textValue : this.props.placeholder}</OverflowTooltip>}
            </span>
        );
    }
}
