import React from 'react';
import classNames from 'classnames';

import { createDataId } from '../../common/utils/dataId';

import './ProgressBar.scss';

export type ProgressBarProps = {
    value: number;
    color?: 'alarm' | 'success';
    dataId?: string;
    hidePercentage?: boolean;
};

const baseTestId = createDataId('ui-progress');

export const dataIds = {
    progressBar: createDataId(baseTestId, 'bar'),
    progressIndicator: createDataId(baseTestId, 'indicator'),
    progressLabel: createDataId(baseTestId, 'label'),
};

const ProgressBar: React.FC<ProgressBarProps> = (props) => {
    const { color, value, dataId, hidePercentage } = props;
    return (
        <div className={classNames('ui-progress', { [`ui-progress--${color}`]: Boolean(color) })} data-id={dataId}>
            <div className={classNames('ui-progress__bar')} data-id={dataIds.progressBar}>
                <div className={classNames('ui-progress__indicator')} style={{ width: `${value}%` }} data-id={dataIds.progressIndicator} />
            </div>
            {!hidePercentage && (
                <div className={classNames('ui-progress__label')} data-id={dataIds.progressLabel}>
                    {value}%
                </div>
            )}
        </div>
    );
};

export default ProgressBar;
