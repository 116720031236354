import { connect, FormikContext } from 'formik';
import { TFunction } from 'i18next';
import { groupBy, get } from 'lodash-es';
import React from 'react';

import { createDataId } from '../../../../common/utils/dataId';
import { Button, ButtonType } from '../../../../components/Buttons/Button';
import { IconSize, ICONS } from '../../../../components/Icon/Icon';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import { AutoTransactionMatchType } from '../../../../services/types/ApiTypes';
import { TriggerFields } from '../../autoTransactionAddViewFields';
import { getTriggerMatchTypes } from './helpers/matchTypeHelpers';

import { createTriggerValueInput, MatchTypeSelect, TriggerMainCondition, TriggerMainConditionItemProps } from './helpers/triggersHelpers';

export interface TriggerProps {
    t: TFunction;
    trigger: TriggerFields;
    triggers: TriggerMainConditionItemProps[];
    index: number;
    remove: () => void;
    formik?: FormikContext<TriggerFields>;
    fieldNamePrefix: string;
}

export const Trigger = (props: TriggerProps) => {
    const {
        t,
        index,
        triggers,
        trigger,
        fieldNamePrefix,
        formik: { setFieldValue, values },
    } = props;

    const groupsItems = groupBy(triggers, 'groupName');

    const fieldNames: Record<'mainCondition' | 'matchType', string> = {
        mainCondition: fieldNamePrefix,
        matchType: `${fieldNamePrefix}.matchType`,
    };

    const handleMainConditionSelect = (trigger: TriggerFields) => {
        const availableMatchTypes = getTriggerMatchTypes(trigger.extensionField);
        const currentMatchType = get(values, fieldNames.matchType);
        if (!availableMatchTypes.includes(currentMatchType)) {
            return setFieldValue(fieldNames.mainCondition, trigger);
        }
        setFieldValue(fieldNames.mainCondition, { ...trigger, matchType: currentMatchType });
    };

    const handleMatchTypeSelect = (selectedMatchType: AutoTransactionMatchType): void => {
        setFieldValue(fieldNames.matchType, selectedMatchType);
    };

    return (
        <div className={'trigger'}>
            <div className={'trigger-items'}>
                <TriggerMainCondition groupsItems={groupsItems} trigger={trigger} handleItemClick={handleMainConditionSelect} t={t} />
                <MatchTypeSelect trigger={trigger} cbFunc={handleMatchTypeSelect} t={t} matchTypeName={null} />
                {createTriggerValueInput(trigger, `${fieldNamePrefix}.value`, index, t, props.formik)}
            </div>
            <div className={'action-wrap'}>
                <Tooltip content={t('component.AutoTransaction.Menu.Delete')}>
                    <Button dataId={createDataId('asd', '.delete')} buttonType={ButtonType.ICON_SQUARE} icon={ICONS.DELETE_24} iconSize={IconSize.SM} onClick={props.remove} />
                </Tooltip>
            </div>
        </div>
    );
};

export default connect(Trigger);
