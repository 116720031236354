import { FastField, FormikProps } from 'formik';
import { TFunction } from 'i18next';
import { get, isNumber } from 'lodash-es';
import React from 'react';

import { addDataId, createDataId } from '../../../../../common/utils/dataId';
import FormikField from '../../../../../common/utils/FormikField';
import { Button, ButtonType } from '../../../../../components/Buttons/Button';
import { DropdownMenu, DropdownMenuItem } from '../../../../../components/DropdownMenu/DropdownMenu';
import { TextInputField } from '../../../../../components/TextInput/TextInput';
import { TypeaheadAsyncField } from '../../../../../components/Typeahead/TypeaheadAsync';
import { AutoTransactionMatchType, InvoiceType } from '../../../../../services/types/ApiTypes';
import api from '../../../../../services/ApiServices';
import { TriggerFields } from '../../../autoTransactionAddViewFields';

export const matchTypesWithInput = [
    AutoTransactionMatchType.Contains,
    AutoTransactionMatchType.Equal,
    AutoTransactionMatchType.NotEqual,
    AutoTransactionMatchType.LessEqual,
    AutoTransactionMatchType.Less,
    AutoTransactionMatchType.GreaterEqual,
    AutoTransactionMatchType.Greater,
];

export interface ValueInputProps {
    t: TFunction;
    triggerIndex?: number;
    formikName: string;
    maxLength?: number;
    pattern?: RegExp;
}

export function getInvoiceTypeLabel(invoiceType: InvoiceType) {
    const invoiceTypes = {
        [InvoiceType.PurchaseInvoice]: 'view.AutoTransactions.Details.Triggers.ExtensionField.Invoice.Type.Debit',
        [InvoiceType.Expense]: 'view.AutoTransactions.Details.Triggers.ExtensionField.Invoice.Type.Credit',
    };
    return invoiceTypes[invoiceType];
}

export const CompanyValueInput = ({ t, formikName }: ValueInputProps) => (
    <FormikField
        wrapperClass={'trigger__item'}
        component={TypeaheadAsyncField}
        name={formikName}
        dataId="companyValue"
        placeholder={t('component.AutoTransaction.PlaceHolder.ClickToSearch')}
        inputProps={{
            className: 'trigger__item-button',
            ['data-id']: createDataId('companyValue', 'input'),
            autosize: true,
            onFocusPlaceholder: t('component.AutoTransaction.PlaceHolder.TypeToSearch'),
        }}
        searchOnFocus={false}
        isTooltipError={true}
        disableSearchOnSelectedFocus={true}
        loadItems={(name: string) =>
            api.suppliers.getSuppliersByName(name || '').then((r) => {
                return r.data.map((company) => {
                    return {
                        value: company.Id,
                        text: `${company.Name} (${company.RegistrationCode})`,
                    };
                });
            })
        }
    />
);

export const ReferenceNumberInput = ({ t, formikName }: ValueInputProps) => (
    <FastField
        wrapperClass={'trigger__item'}
        className={'trigger__item-input'}
        component={TextInputField}
        name={formikName}
        autosize={true}
        dataId={addDataId('referenceNumberValue', '')}
        maxLength={50}
        onlyChangeOnBlur={true}
        isTooltipError={true}
        placeholder={t('component.AutoTransaction.PlaceHolder.ClickToSet')}
        onFocusPlaceholder={t('component.AutoTransaction.PlaceHolder.TypeToSet')}
    />
);

export const AmountInput = ({ t, formikName, pattern }: ValueInputProps) => (
    <FastField
        wrapperClass={'trigger__item'}
        className={'trigger__item-input'}
        component={TextInputField}
        name={formikName}
        autosize={true}
        dataId={createDataId('trigger', formikName, 'input')}
        validCharacters={pattern}
        onlyChangeOnBlur={true}
        isTooltipError={true}
        placeholder={t('component.AutoTransaction.PlaceHolder.ClickToSet')}
        onFocusPlaceholder={t('component.AutoTransaction.PlaceHolder.TypeToSet')}
    />
);

export const StringInput = ({ t, formikName, maxLength, pattern }: ValueInputProps) => (
    <FastField
        wrapperClass={'trigger__item'}
        className={'trigger__item-input'}
        component={TextInputField}
        name={formikName}
        autosize={true}
        maxLength={maxLength}
        validCharacters={pattern}
        isTooltipError={true}
        dataId={createDataId('trigger', formikName, 'input')}
        onlyChangeOnBlur={true}
        placeholder={t('component.AutoTransaction.PlaceHolder.ClickToSet')}
        onFocusPlaceholder={t('component.AutoTransaction.PlaceHolder.TypeToSet')}
    />
);

export const InvoiceTypeInput = ({ t, formikName, formik }: { t: TFunction; triggerIndex: number; formikName: string; formik: FormikProps<TriggerFields> }) => {
    const getInvoiceTypeDropdownLabel = () => {
        const invoiceType = get(formik.values, formikName);
        if (isNumber(parseInt(invoiceType, 10)) && !isNaN(parseInt(invoiceType, 10)) && [InvoiceType.PurchaseInvoice, InvoiceType.Expense].includes(Number(invoiceType))) {
            return t(getInvoiceTypeLabel(invoiceType));
        }
        return t('component.AutoTransaction.PlaceHolder.ClickToSelect');
    };

    return (
        <DropdownMenu
            dataId={addDataId(null, 'Dropdown')}
            items={[InvoiceType.PurchaseInvoice, InvoiceType.Expense].map((key, invoiceTypeIndex) => {
                return (
                    <DropdownMenuItem key={invoiceTypeIndex} onClick={() => formik.setFieldValue(formikName, key)} dataId={createDataId('option', invoiceTypeIndex)}>
                        {t(getInvoiceTypeLabel(key))}
                    </DropdownMenuItem>
                );
            })}
        >
            <Button dataId={addDataId(formikName + 'Dropdown', '.trigger')} buttonType={ButtonType.TEXT} className={'trigger__item'}>
                {getInvoiceTypeDropdownLabel()}
            </Button>
        </DropdownMenu>
    );
};
