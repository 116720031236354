import { UserRole } from '../../../../common/constants/appConstants';
import { getCurrentUserData, hasUserARole } from '../../../../common/user/userPermissionUtil';

export const dataId = 'po-fulfilment';

export const canUserAdjustFulfilment = (createdById: number): boolean => {
    const isPOCAdmin = hasUserARole(UserRole.PurchaseOrdersAdministrator);
    if (isPOCAdmin) {
        return true;
    }
    const isPOCreator = hasUserARole(UserRole.PurchaseOrdersCreator);
    const groupMemberId = getCurrentUserData().GroupMemberId;
    if (isPOCreator && groupMemberId === createdById) {
        return true;
    }

    return false;
};
