import React from 'react';

import { createDataId } from '../../../../common/utils/dataId';
import TabList from '../../../../components/TabList/TabList';
import TabListItem from '../../../../components/TabList/TabListItem';
import { AutoTransactionsAddViewFields } from '../../autoTransactionAddViewFields';
import { Tabs } from '../../AutoTransactionsAddView';

import './MainTabList.scss';

export interface MainTabListProps {
    tabs: AutoTransactionsAddViewFields;
    activeTab: Tabs;
    handleClick: (tab: Tabs) => void;
    t: any;
}

export const MainTabList = (props: MainTabListProps) => {
    return (
        <div className={'main-tabs'}>
            <TabList className={'tab-list'}>
                <TabListItem
                    dataId={createDataId('tab', 'triggers')}
                    className={'tab-list__item triggers-tab'}
                    isActive={props.activeTab === Tabs.Triggers}
                    onClick={() => props.handleClick(Tabs.Triggers)}
                >
                    <span className={'button--text-uppercase'}>{`${props.t('component.AutoTransaction.Tab.Triggers')} (${props.tabs.triggersView &&
                        props.tabs.triggersView.triggers &&
                        props.tabs.triggersView.triggers.length})`}</span>
                </TabListItem>
                <TabListItem
                    dataId={createDataId('tab', 'automationSteps')}
                    className={'tab-list__item automation-steps-tab'}
                    isActive={props.activeTab === Tabs.AutomationSteps}
                    onClick={() => props.handleClick(Tabs.AutomationSteps)}
                >
                    <span className={'button--text-uppercase'}>{`${props.t('component.AutoTransaction.Tab.AutomationSteps')} (${props.tabs.autoTransactionsRows.length})`}</span>
                </TabListItem>
                <TabListItem
                    dataId={createDataId('tab', 'invoiceCustomFields')}
                    className={'tab-list__item invoice-custom-fields'}
                    isActive={props.activeTab === Tabs.InvoiceCustomFields}
                    onClick={() => props.handleClick(Tabs.InvoiceCustomFields)}
                >
                    <span className={'button--text-uppercase'}>{`${props.t('component.AutoTransaction.Tab.InvoiceCustomFields')} (${props.tabs.autoTransactionsCustomFields.length})`}</span>
                </TabListItem>
            </TabList>
        </div>
    );
};

export default MainTabList;
