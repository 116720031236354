import * as React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { Dispatchable0 } from 'redux-dispatchers';

import { BaseComponent } from '../../components/BaseComponent';

export interface RouteGuard {
    /**
     * If the condition is not met then either redirect to onFail or don't render the route
     */
    failCondition: boolean;
    /**
     * If request is still in progress we don't want to call onFail yet
     */
    requestDone: boolean;
    /**
     * URL where to redirect to, when condition is not met
     */
    onFail?: string | null | Dispatchable0;
}

interface PrivateRouteProps extends RouteProps {
    guards: RouteGuard[];
}

class PrivateRoute extends BaseComponent<PrivateRouteProps> {
    /*
        Decide what to render into the route
     */
    getRenderer = (guards: RouteGuard[], Component: any, props: any) => {
        for (const guard of guards) {
            if (!guard.requestDone) {
                // if guard request isn't done then render nothing and wait for requestDone to change
                return null;
            } else if (guard.failCondition) {
                // if guard request is done then check if failCondition matches
                // and if it does then either redirect to onFail or display nothing
                if (guard.onFail) {
                    if (typeof guard.onFail === 'string') {
                        return <Redirect to={guard.onFail} />;
                    } else {
                        guard.onFail();
                        return null;
                    }
                } else {
                    return null;
                }
            }
        }

        return <Component {...props} />;
    };

    render(): JSX.Element {
        const { component, guards, ...rest } = this.props;
        return <Route {...rest} render={(props) => this.getRenderer(guards, component, props)} />;
    }
}

export default PrivateRoute;
