import classNames from 'classnames';
import * as React from 'react';

import './Controls.scss';

export interface ControlsProps {
    children?: React.ReactNode;
    className?: string;
    reverse?: boolean;
}

const Controls: React.StatelessComponent<ControlsProps> = ({ children, className, reverse }: ControlsProps) => {
    const classes = classNames('controls', className, { 'controls--reverse': reverse });
    return <div className={classes}>{children}</div>;
};

export default Controls;
