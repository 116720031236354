import { FormikProps } from 'formik';
import { TFunction } from 'i18next';
import { differenceWith, get, isEqual, size } from 'lodash-es';
import React from 'react';

import { createDataId } from '../../../../../common/utils/dataId';
import FormikField from '../../../../../common/utils/FormikField';
import { DECIMAL_DP2_REGEXP, DECIMAL_DP4_REGEXP } from '../../../../../common/utils/validators';
import { Button, ButtonType } from '../../../../../components/Buttons/Button';
import { DropdownMenu, DropdownMenuItem } from '../../../../../components/DropdownMenu/DropdownMenu';
import Icon, { ICONS, IconSize } from '../../../../../components/Icon/Icon';
import { SwitchField } from '../../../../../components/Switch/Switch';
import { ExtensionField } from '../../../../../services/types/ApiTypes';
import { AutoTransactionsAddViewFields, TriggerFields } from '../../../autoTransactionAddViewFields';
import { getMatchType } from '../../../AutoTransactionsAddViewHelper';

import { getExtensionFields } from './extensionFieldHelpers';
import { extensionTypesObject, getExtensionTypeLabel } from './extensionTypeHelpers';
import { getTriggerDefaultMatchType, getTriggerMatchTypes } from './matchTypeHelpers';
import { AmountInput, CompanyValueInput, InvoiceTypeInput, matchTypesWithInput, ReferenceNumberInput, StringInput } from './valueInputHelpers';

export interface TriggerMainConditionItemProps {
    name: string;
    id: number;
    groupName: string;
    groupId: number;
}

export class TriggerMainConditionDropdown extends DropdownMenu<TriggerMainConditionItemProps> {}

export function getAllTriggers(t: TFunction): TriggerMainConditionItemProps[] {
    const dropdownGroupsArray: TriggerMainConditionItemProps[] = [];
    Object.keys(extensionTypesObject).forEach((extensionType) => {
        const extensionFields = getExtensionFields(Number(extensionType));
        Object.keys(extensionFields).forEach((field) => {
            dropdownGroupsArray.push({
                groupName: t(getExtensionTypeLabel(Number(extensionType))),
                groupId: Number(extensionType),
                name: t(getExtensionFields(Number(extensionType), Number(field))),
                id: Number(field),
            });
        });
    });
    return dropdownGroupsArray;
}

export interface TriggerTypeSelectDropdownProps {
    addableTriggers: _.Dictionary<TriggerMainConditionItemProps[]>;
    buttonContent: string;
    cbFunc: any;
    t: TFunction;
    triggerTypeName: string;
}

export const TriggerTypeSelect = ({ triggerTypeName = 'addTrigger', addableTriggers, t, cbFunc, buttonContent }: TriggerTypeSelectDropdownProps) => (
    <TriggerMainConditionDropdown
        dataId={createDataId(triggerTypeName)}
        hasArrow={true}
        hasGroups={true}
        items={addableTriggers}
        filterable={true}
        filterOptions={{
            filterBy: 'name',
            placeholder: 'Type to filter',
        }}
        handleDropdownItemClick={({ groupId, id }) => {
            const trigger: TriggerFields = {
                extensionField: id,
                extensionType: groupId,
                value: '',
                matchType: getTriggerDefaultMatchType(id),
                displayValue: '',
            };
            cbFunc(trigger);
        }}
    >
        <Button dataId={createDataId(triggerTypeName, 'trigger')} className={'trigger__item' + (!buttonContent ? ' trigger__item--add-new' : '')} buttonType={ButtonType.TEXT}>
            {buttonContent ? (
                t(buttonContent)
            ) : (
                <>
                    <Icon name={ICONS.PLUS_THICK_24} size={IconSize.XS} /> &nbsp; {t('component.AutoTransaction.Menu.ClickToAdd.Trigger')}
                </>
            )}
        </Button>
    </TriggerMainConditionDropdown>
);

export interface TriggerMainConditionProps {
    groupsItems: _.Dictionary<TriggerMainConditionItemProps[]>;
    trigger: TriggerFields;
    handleItemClick: (item: TriggerFields) => void;
    t: TFunction;
}

export const TriggerMainCondition = ({ trigger, groupsItems, t, handleItemClick }: TriggerMainConditionProps) => {
    if (!trigger.extensionType) {
        return null;
    }
    return (
        <TriggerMainConditionDropdown
            hasArrow={true}
            dataId={createDataId('autoTransactions', 'triggers', 'typeDropdown')}
            hasGroups={true}
            items={groupsItems}
            filterable={true}
            filterOptions={{
                filterBy: 'name',
                placeholder: 'Type to filter',
            }}
            handleDropdownItemClick={({ groupId, id }) => {
                const newTrigger: TriggerFields = {
                    extensionField: id,
                    extensionType: groupId,
                    value: '',
                    matchType: getTriggerDefaultMatchType(id),
                    displayValue: '',
                };
                handleItemClick(newTrigger);
            }}
        >
            <Button className={'trigger__item'} type={'button'} buttonType={ButtonType.TEXT} dataId={createDataId('trigger', trigger.extensionField, 'button')}>
                {t(getExtensionFields(trigger.extensionType, trigger.extensionField))}
            </Button>
        </TriggerMainConditionDropdown>
    );
};

export interface MatchTypeSelectProps {
    trigger: TriggerFields;
    cbFunc: any;
    matchTypeName: string;
    t: TFunction;
}

export const MatchTypeSelect: React.FC<MatchTypeSelectProps> = ({ matchTypeName = 'matchType', cbFunc, t, trigger }) => {
    const matchTypes = getTriggerMatchTypes(trigger.extensionField);
    return size(matchTypes) > 1 ? (
        <DropdownMenu
            dataId={createDataId(matchTypeName + 'Dropdown')}
            items={matchTypes.map((key, matchTypeIndex) => {
                return (
                    <DropdownMenuItem key={matchTypeIndex} onClick={() => cbFunc(key)} dataId={createDataId('option', matchTypeIndex)}>
                        {t(getMatchType(key))}
                    </DropdownMenuItem>
                );
            })}
        >
            <Button dataId={createDataId(trigger.extensionField + 'Dropdown', 'trigger')} buttonType={ButtonType.TEXT} className={'trigger__item'}>
                {trigger.matchType ? t(getMatchType(trigger.matchType)) : t('component.AutoTransaction.PlaceHolder.ClickToSelect')}
            </Button>
        </DropdownMenu>
    ) : (
        <span className={'trigger__item'}>{t(getMatchType(trigger.matchType))}</span>
    );
};

export function createTriggerValueInput(trigger: TriggerFields, fieldNamePrefix: string, triggerIndex: number, t: TFunction, formik: FormikProps<TriggerFields>): React.ReactNode {
    // TODO: for some reason trigger.matchType here is string, so cast it back to Number
    if (!matchTypesWithInput.includes(Number(trigger.matchType))) {
        return null;
    }
    switch (trigger.extensionField) {
        case ExtensionField.BeneficiaryName:
        case ExtensionField.Company:
            return <CompanyValueInput t={t} triggerIndex={triggerIndex} formikName={fieldNamePrefix} />;
        case ExtensionField.ReferenceNumber:
            return <ReferenceNumberInput t={t} triggerIndex={triggerIndex} formikName={fieldNamePrefix} />;
        case ExtensionField.GrandTotal:
            return <AmountInput t={t} triggerIndex={triggerIndex} formikName={fieldNamePrefix} pattern={DECIMAL_DP2_REGEXP} />;
        case ExtensionField.NETAmount:
            return <AmountInput t={t} triggerIndex={triggerIndex} formikName={fieldNamePrefix} pattern={DECIMAL_DP4_REGEXP} />;
        case ExtensionField.InvoiceType:
            return <InvoiceTypeInput t={t} triggerIndex={triggerIndex} formikName={fieldNamePrefix} formik={formik} />;
        case ExtensionField.FactorContractNumber:
        case ExtensionField.ContractNumber:
        case ExtensionField.DocumentName:
        case ExtensionField.InvoiceNumber:
        case ExtensionField.ContentCode:
        case ExtensionField.ContentText:
        case ExtensionField.ExtensionId:
        case ExtensionField.ExtensionName:
        case ExtensionField.ExtensionContent:
        case ExtensionField.UniqueCode:
        case ExtensionField.DepartmentId:
        case ExtensionField.ContactPerson:
        case ExtensionField.Description:
            return <StringInput t={t} triggerIndex={triggerIndex} formikName={fieldNamePrefix} />;
        case ExtensionField.GLN:
            return <StringInput t={t} formikName={fieldNamePrefix} maxLength={13} pattern={/\d+/} />;
        default:
            return null;
    }
}

export function getAddableTriggers(triggers: TriggerFields[], allTriggerConditions: TriggerMainConditionItemProps[]): TriggerMainConditionItemProps[] {
    const existingTriggersMainConditions: TriggerMainConditionItemProps[] = triggers.map((trigger) => ({
        groupId: trigger.extensionType,
        groupName: null,
        id: trigger.extensionField,
        name: null,
    }));
    return differenceWith(allTriggerConditions, existingTriggersMainConditions, (existing, all) => {
        return isEqual({ id: existing.id, groupId: existing.groupId }, { id: all.id, groupId: all.groupId });
    });
}

export interface TriggerSwitchesProps {
    fieldNamePrefix: string;
    t: TFunction;
    formik: FormikProps<AutoTransactionsAddViewFields>;
}

export const TriggersSwitches = ({ t, fieldNamePrefix, formik }: TriggerSwitchesProps) => (
    <>
        <div className={'rule-config-row'}>
            {/* RULE IS ENABLED SWITCH */}
            <div className={'rule-config-row__texts'}>
                <span>
                    <strong>
                        {get(formik.values, `${fieldNamePrefix}.isRuleActive`) ? t('component.AutoTransaction.Tab.Trigger.IsEnabled.On') : t('component.AutoTransaction.Tab.Trigger.IsEnabled.Off')}
                    </strong>
                </span>
                <span>
                    {get(formik.values, `${fieldNamePrefix}.isRuleActive`)
                        ? t('component.AutoTransaction.Tab.Trigger.IsEnabled.On.Description')
                        : t('component.AutoTransaction.Tab.Trigger.IsEnabled.Off.Description')}
                </span>
            </div>
            <div className={'rule-config-row__switch'}>
                <FormikField component={SwitchField} name={`${fieldNamePrefix}.isRuleActive`} dataId={`${fieldNamePrefix}.isRuleActive`} />
            </div>
        </div>
        <div className={'rule-config-row'}>
            {/* RULE IS ENABLED SWITCH */}
            <div className={'rule-config-row__texts'}>
                <span>{t('component.AutoTransaction.Tab.Trigger.StopProcessing')}</span>
            </div>
            <div className={'rule-config-row__switch'}>
                <FormikField component={SwitchField} name={`${fieldNamePrefix}.stopProcessing`} dataId={`${fieldNamePrefix}.stopProcessing`} />
            </div>
        </div>
    </>
);
