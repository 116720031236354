import { some } from 'lodash-es';
import * as React from 'react';

import { isAuthorized } from '../../common/user/userPermissionUtil';

export interface AccessLevelProps {
    children: React.ReactNode;
    role: string | string[];
}

// We wrap children into fragment because we don't know what the children might be, see https://github.com/Microsoft/TypeScript/issues/21699
const AccessLevel: React.SFC<AccessLevelProps> = (props: AccessLevelProps) => {
    const { children, role } = props;
    if (typeof role === 'string') {
        return isAuthorized(role) ? <>{children}</> : null;
    }
    return some(role, (r) => isAuthorized(r)) ? <>{children}</> : null;
};

export default AccessLevel;
