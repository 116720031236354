import React from 'react';
import { TFunction } from 'i18next';

import { formatDate, formatMoneyAndCurrency } from '../../../../common/utils/formatters';
import { PurchaseOrdersDTO, PurchaseOrderType } from '../../../../services/types/ApiTypes';
import { createDataId } from '../../../../common/utils/dataId';
import { DEFAULT_CURRENCY_PLACEHOLDER } from '../../../../common/constants/appConstants';
import PropertyListItem from '../../../../components/PropertyListItem/PropertyListItem';
import { HeaderPlaceholder } from './HeaderPlaceholder';
import PoStatusLabel from '../../../purchase-orders/components/Status/PoStatusLabel';
import OverflowTooltip from '../../../../components/Tooltip/OverflowTooltip';
import { Typography } from '../../../../components/Typography';
import { PropertyListItemWrapper } from '../../../invoice-details/components/invoice-header/components/PropertyListItemWrapper';

const HeaderViewMode = ({ dataId, purchaseOrder, loading, t }: { dataId: string; purchaseOrder: PurchaseOrdersDTO; loading: boolean; t: TFunction }) => (
    <>
        <div className="purchase-order-header__head">
            <div className="purchase-order-header__head--row">
                <div className="purchase-order-header__head--left">
                    <ul>
                        <PropertyListItemWrapper dataId={dataId} label={t('component.additionalInfo.supplier')} isLoading={!purchaseOrder || loading}>
                            <Typography variant="h1" element="span">
                                {purchaseOrder?.Supplier?.Name || '--'}
                            </Typography>
                        </PropertyListItemWrapper>
                    </ul>
                </div>
                <div className="purchase-order-header__head--right">
                    <ul>
                        <PropertyListItemWrapper dataId={dataId} alignRight label={t('component.purchaseOrder.header.orderNumber')} isLoading={!purchaseOrder || loading}>
                            <Typography variant="h1" element="span">
                                <OverflowTooltip>{purchaseOrder?.OrderNumber}</OverflowTooltip>
                            </Typography>
                        </PropertyListItemWrapper>
                    </ul>
                </div>
            </div>
            <div className="purchase-order-header__head--labels-row">
                {!purchaseOrder || loading ? (
                    <HeaderPlaceholder class={'purchase-order'} />
                ) : (
                    <p data-id={createDataId(dataId, 'description')} className="purchase-order-description">
                        {purchaseOrder?.Description}
                    </p>
                )}
                {!purchaseOrder || loading ? (
                    <HeaderPlaceholder class={'purchase-order'} />
                ) : (
                    <div data-id={createDataId(dataId, 'stamp-labels')} className="stamp-labels">
                        <PoStatusLabel status={purchaseOrder?.OrderStatus} />
                    </div>
                )}
            </div>
        </div>
        <div className="purchase-order-header__fields">
            <ul>
                <PropertyListItem dataId={dataId} label={t('component.purchaseOrder.header.regNumber')} value={purchaseOrder?.Supplier?.RegistrationCode} showPlaceholder={!purchaseOrder || loading} />
                <PropertyListItem dataId={dataId} label={t('component.purchaseOrder.header.supplierVatNumber')} value={purchaseOrder?.Supplier?.VatCode} showPlaceholder={!purchaseOrder || loading} />
                <PropertyListItem dataId={dataId} label={t('component.purchaseOrder.header.eprCode')} value={purchaseOrder?.Supplier?.Code} showPlaceholder={!purchaseOrder || loading} />
            </ul>
            <ul>
                <PropertyListItem
                    dataId={dataId}
                    label={t('view.PurchaseOrders.Type')}
                    value={purchaseOrder?.OrderType === PurchaseOrderType.Opex ? 'OPEX' : 'CAPEX'}
                    showPlaceholder={!purchaseOrder || loading}
                />
            </ul>
            <ul>
                <PropertyListItem dataId={dataId} label={t('component.purchaseOrder.header.createdDate')} value={formatDate(purchaseOrder?.DateCreated)} showPlaceholder={!purchaseOrder || loading} />
                <PropertyListItem
                    dataId={dataId}
                    label={t('view.PurchaseOrders.CreatedBy')}
                    value={`${purchaseOrder?.CreatedByName || t('view.PurchaseOrders.CreatedBy.Import')}`}
                    showPlaceholder={!purchaseOrder || loading}
                />
            </ul>
            <ul>
                <PropertyListItem
                    dataId={dataId}
                    alignRight
                    label={t('component.purchaseOrder.header.NetTotal')}
                    value={formatMoneyAndCurrency(purchaseOrder?.TotalWithoutVat, purchaseOrder?.Currency || DEFAULT_CURRENCY_PLACEHOLDER)}
                    showPlaceholder={!purchaseOrder || loading}
                />
                <PropertyListItem
                    dataId={dataId}
                    alignRight
                    label={t('component.purchaseOrder.header.VatTotal')}
                    value={formatMoneyAndCurrency(purchaseOrder?.Total - purchaseOrder?.TotalWithoutVat, purchaseOrder?.Currency || DEFAULT_CURRENCY_PLACEHOLDER)}
                    showPlaceholder={!purchaseOrder || loading}
                />

                <PropertyListItemWrapper dataId={dataId} alignRight label={t('component.purchaseOrder.header.GrandTotal')} isLoading={!purchaseOrder || loading}>
                    <Typography variant="h1" element="span">
                        {formatMoneyAndCurrency(purchaseOrder?.Total, purchaseOrder?.Currency || DEFAULT_CURRENCY_PLACEHOLDER)}
                    </Typography>
                </PropertyListItemWrapper>
            </ul>
        </div>
    </>
);

export default HeaderViewMode;
