import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getCompanyGroupMembers } from '../../common/company/CompanyActions';
import { getCurrentUser } from '../../common/user/UserSelectors';
import { showAddOrEditSupplierModal } from '../../components/EditSupplierModal/EditSupplierModalActions';
import { selectAddSupplierLoadable, selectUpdateSupplierLoadable } from '../../components/EditSupplierModal/EditSupplierModalReducer';
import { GlobalState } from '../../rootReducer';
import PurchaseOrdersAddView, { DispatchProps, Props } from './PurchaseOrdersAddView';
import {
    addApprover,
    addOrUpdatePO,
    updatePoRow,
    deletePoRow,
    deleteFile,
    downloadFile,
    doTaskAction,
    openFileInNewWindow,
    replaceUrlToDetails,
    retrievePO,
    retrievePOFiles,
    retrievePORows,
    uploadFile,
    updateExtraStatus,
    deletePurchaseOrder,
} from './PurchaseOrdersAddViewActions';
import { exportPurchaseOrderToPdf, duplicatePurchaseOrder } from '../purchase-orders/PurchaseOrdersViewActions';
import {
    selectDownloadFileLoadable,
    selectIsApproverAddingAvailable,
    selectPotentialApprovers,
    selectPoTaskItemsLoadable,
    selectRetrievePOLoadable,
    selectUploadFileLoadable,
    selectUploadFileProgress,
    selectUpdateExtraStatusLoadable,
    selectPoRowsLoadable,
    selectPoFilesLoadable,
} from './PurchaseOrdersAddViewSelectors';

const mapStateToProps = (state: GlobalState): Props => {
    return {
        retrievePOLoadable: selectRetrievePOLoadable(state),
        poRowsLoadable: selectPoRowsLoadable(state),
        poFilesLoadable: selectPoFilesLoadable(state),
        poTaskItemsLoadable: selectPoTaskItemsLoadable(state),
        currentUser: getCurrentUser(state),
        uploadFileLoadable: selectUploadFileLoadable(state),
        uploadFileProgress: selectUploadFileProgress(state),
        addSupplierLoadable: selectAddSupplierLoadable(state),
        potentialApprovers: selectPotentialApprovers(state),
        isApproverAddingAvailable: selectIsApproverAddingAvailable(state),
        downloadFileLoadable: selectDownloadFileLoadable(state),
        updateExtraStatusLoadable: selectUpdateExtraStatusLoadable(state),
        updateSupplierLoadable: selectUpdateSupplierLoadable(state),
    };
};

const mapDispatchToProps: DispatchProps = {
    addOrUpdatePO,
    updatePoRow,
    deletePoRow,
    retrievePO,
    retrievePORows,
    retrievePOFiles,
    uploadFile,
    deletePurchaseOrder,
    deleteFile,
    downloadFile,
    doTaskAction,
    openFileInNewWindow,
    showAddOrEditSupplierModal,
    getCompanyGroupMembers,
    addApprover,
    replaceUrlToDetails,
    exportPurchaseOrderToPdf,
    duplicatePurchaseOrder,
    updateExtraStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PurchaseOrdersAddView));
