type KeyFactory = (previousKey: string, currentKey: string) => string;

export function flattenObject(ob: { [key: string]: any }, keyFactory: KeyFactory | null = null): { [key: string]: any } {
    if (keyFactory === null) {
        keyFactory = (previousKey, currentKey) => previousKey + '.' + currentKey;
    }
    const toReturn: { [key: string]: string } = {};
    for (const i in ob) {
        if (!ob.hasOwnProperty(i)) {
            continue;
        }
        if (typeof ob[i] === 'object') {
            const flatObject = flattenObject(ob[i]);
            for (const x in flatObject) {
                if (!flatObject.hasOwnProperty(x)) {
                    continue;
                }
                toReturn[keyFactory(i, x)] = flatObject[x];
            }
        } else {
            toReturn[i] = ob[i];
        }
    }
    return toReturn;
}
