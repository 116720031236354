// Avoid `console` errors in browsers that lack a console.
export function fixConsole() {
    let method;
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const noop = function() {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    noop.prototype.apply = function() {};
    const methods = [
        'assert',
        'clear',
        'count',
        'debug',
        'dir',
        'dirxml',
        'error',
        'exception',
        'group',
        'groupCollapsed',
        'groupEnd',
        'info',
        'log',
        'markTimeline',
        'profile',
        'profileEnd',
        'table',
        'time',
        'timeEnd',
        'timeStamp',
        'trace',
        'warn',
    ];
    let length = methods.length;
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    const console = (window.console = window.console || {});

    while (length--) {
        method = methods[length];
        // Only stub undefined methods.
        if (!console[method]) {
            console[method] = noop;
        }
    }
}
