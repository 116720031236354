import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import FormikField from '../../../../common/utils/FormikField';
import { TextInputField, TextInputType } from '../../../../components/TextInput/TextInput';
import { SupplierDTO, Currency, PurchaseOrderType } from '../../../../services/types/ApiTypes';
import { createDataId } from '../../../../common/utils/dataId';
import api from '../../../../services/ApiServices';
import { TypeaheadAsync, TypeaheadAsyncField } from '../../../../components/Typeahead/TypeaheadAsync';
import { Typeahead, TypeaheadItem } from '../../../../components/Typeahead/Typeahead';
import { getCurrencyName } from '../../../../common/user/userUtils';
import fieldNames from '../../../purchase-orders-add/purchaseOrderAddViewFields';
import { filterCurrenciesByName } from '../../../invoice-details/components/invoice-header/utils';
import AddNewSupplierButton from '../../../invoice-details/components/invoice-header/components/AddSupplierButton';
import { orderTypesList } from '../../../purchase-orders-add/PurchaseOrdersAddViewHelper';
import { getSuppliersByName } from '../../PurchaseOrdersNewHelper';
import { PurchaseOrdersNewViewFields } from '../../PurchaseOrdersNewTypes';
import { dataId, parseCurrencyText, parseSupplierText } from './HeaderHelper';
import { HeaderProps } from './HeaderTypes';

import './Header.scss';

const Header: React.FC<HeaderProps> = ({ showAddOrEditSupplierModal, setPurchaseOrderFormValues, formik, addSupplierLoadable }: HeaderProps) => {
    const [currencyList, setCurrencyList] = useState<Currency[]>(null);
    const [selectedType, setSelectedType] = useState<TypeaheadItem<number>>({
        text: PurchaseOrderType[PurchaseOrderType.Opex].toLocaleUpperCase(),
        value: PurchaseOrderType.Opex,
    });

    const { t } = useTranslation();

    const loadSupplier = (supplier: SupplierDTO) => {
        if (supplier) {
            setPurchaseOrderFormValues({
                ...formik.values,
                Supplier: { text: supplier.Name, value: supplier },
            });
            formik.setFieldTouched(fieldNames.Supplier, true);
        }
    };

    useEffect(() => {
        if (addSupplierLoadable?.loaded && addSupplierLoadable?.payload?.Id && !addSupplierLoadable?.payload?.IsNew && !isEmpty(formik.values)) {
            loadSupplier(addSupplierLoadable.payload);
        }
    }, [addSupplierLoadable]);

    const changeSupplier = (supplier: TypeaheadItem<SupplierDTO>, values: PurchaseOrdersNewViewFields) => {
        setPurchaseOrderFormValues({ ...values, Supplier: supplier });
    };

    const getCurrenciesByName = async (name: string): Promise<Currency[]> => {
        if (!currencyList?.length) {
            const { data } = await api.invoice.getCurrencyList();
            setCurrencyList(data);
            return filterCurrenciesByName(name, data);
        }
        return filterCurrenciesByName(name, currencyList);
    };

    const handleSupplierChange = (supplier: TypeaheadItem<SupplierDTO>) => {
        changeSupplier(supplier, formik.values);
    };

    const onAddSupplierClick = () => {
        showAddOrEditSupplierModal(undefined);
    };

    const handleTypeChange = (type: TypeaheadItem<PurchaseOrderType>) => {
        setSelectedType(type);
        formik.setFieldValue(fieldNames.Type, type.value);
    };

    const handleCurrencyChange = (currency: TypeaheadItem<Currency>) => {
        formik.setFieldValue(fieldNames.Currency, {
            text: `${currency.value.Code} - ${getCurrencyName(currency.value.Code)}`,
            value: currency.value.Code,
        });
        formik.setFieldTouched(fieldNames.Currency, true);
    };

    return (
        <div className="purchase-order-new-header">
            <div className="purchase-order-new-header--left">
                <div className="fixed-height supplier">
                    <FormikField
                        component={TypeaheadAsyncField}
                        dataId={createDataId(dataId, 'supplierName')}
                        toggleVisible
                        name={fieldNames.Supplier}
                        searchOnFocus
                        onChange={handleSupplierChange}
                        inputProps={{ label: t('component.additionalInfo.supplier'), type: TextInputType.COMPACT }}
                        loadData={getSuppliersByName}
                        itemToText={parseSupplierText}
                        placeholder={t('views.purchaseOrders.newPO.header.input.addSupplier.placeholder')}
                        wrapperClass="limit-width"
                        preItemsListElement={<AddNewSupplierButton t={t} dataId={dataId} onClick={onAddSupplierClick} />}
                    />
                </div>

                <FormikField
                    component={TextInputField}
                    dataId={createDataId(dataId, 'purchaseOrderDescription')}
                    name={fieldNames.Description}
                    textarea
                    maxLength={1024}
                    type={TextInputType.COMPACT}
                    label={t('component.additionalInfo.description')}
                    placeholder={t('views.purchaseOrders.newPO.header.input.description.placeholder')}
                    wrapperClass="fixed-height limit-width"
                />
            </div>
            <div className="purchase-order-new-header--right">
                <FormikField
                    component={Typeahead}
                    dataId={createDataId(dataId, 'type')}
                    name={fieldNames.Type}
                    toggleVisible
                    onChange={handleTypeChange}
                    value={selectedType}
                    inputProps={{ label: t('view.PurchaseOrders.Type'), type: TextInputType.COMPACT, readOnly: true }}
                    items={orderTypesList}
                    placeholder={t('view.PurchaseOrders.Type')}
                    wrapperClass="fixed-height fixed-width"
                />

                <TypeaheadAsync
                    dataId={createDataId(dataId, 'currency')}
                    value={formik.values?.Currency}
                    searchOnFocus
                    toggleVisible
                    onChange={handleCurrencyChange}
                    inputProps={{ label: t('component.additionalInfo.currency'), type: TextInputType.COMPACT }}
                    loadData={getCurrenciesByName}
                    itemToText={parseCurrencyText}
                    placeholder={t('component.additionalInfo.currency')}
                    wrapperClass="fixed-height fixed-width"
                />
            </div>
        </div>
    );
};

export default Header;
