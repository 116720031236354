import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setConfirmLocation, setMenuLocked, setMenuUnlocked } from './MenuLockReducer';

export const useMenuLockConfirmation = (shouldConfirm?: boolean) => {
    const dispatch = useDispatch();

    useEffect(() => {
        shouldConfirm ? dispatch(setMenuLocked()) : dispatch(setMenuUnlocked());
        return () => {
            dispatch(setMenuUnlocked());
            dispatch(setConfirmLocation(null));
        };
    }, [shouldConfirm]);
};
