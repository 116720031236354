import { FormikProps } from 'formik';
import { cloneDeep, debounce, isEmpty } from 'lodash-es';
import React, { ChangeEvent, useEffect, useState } from 'react';

import { createDataId } from '../../../../common/utils/dataId';
import { TypeaheadAsync, TypeaheadItem } from '../../../../components/Typeahead/TypeaheadAsync';
import { LabelStyle, TextInput, TextInputType } from '../../../../components/TextInput/TextInput';
import CalendarDatePicker from '../../../../components/CalendarDatePicker/CalendarDatePicker';
import { AutoTransactionInvoiceCustomFieldDTO, InvoiceCustomFieldDTO, InvoiceCustomFieldItemDTO, InvoiceFieldType } from '../../../../services/types/ApiTypes';
import api from '../../../../services/ApiServices';
import { AutoTransactionsAddViewFields } from '../../autoTransactionAddViewFields';
import { notify } from '../../../../common/utils/notify';

import './InvoiceCustomFields.scss';

export interface InvoiceCustomFieldsProps {
    t: any;
    formik: FormikProps<AutoTransactionsAddViewFields>;
    fieldNamePrefix: string;
}

export const InvoiceCustomFields = (props: InvoiceCustomFieldsProps) => {
    const { t, formik, fieldNamePrefix } = props;

    const [allCustomFields, setAllCustomFields] = useState<InvoiceCustomFieldDTO[]>([]);

    const getInvoiceCustomFields = async () => {
        try {
            const response = await api.company.getInvoiceCustomFieldsWithoutItems();
            setAllCustomFields(response.data);
        } catch (error) {
            notify.error(t('component.invoiceHeader.errorGettingCustomizationFields'));
            console.error(error);
        }
    };

    useEffect(() => {
        getInvoiceCustomFields();
    }, []);

    const getInvoiceCustomFieldItems = async (field: string, customization: InvoiceCustomFieldDTO) => {
        let customFieldItemPart;
        if (isEmpty(field) || field.split(' - ').length > 1) {
            // split to check if it is a compound value of 'Name - Code' and search by any
            customFieldItemPart = '';
        } else {
            customFieldItemPart = field;
        }

        const searchParams = {
            customizationId: customization.Id,
            page: 1,
            pagesize: 20,
            customFieldItemPart,
        };
        try {
            const result = await api.invoice.getAvailableCustomizationFields(searchParams);
            return result.data[0].Customization.InvoiceCustomFieldItems;
        } catch (error) {
            notify.error(t('component.invoiceHeader.errorGettingCustomizationFields'));
            console.error(error);
        }
    };

    const mapFormValuesToAutoCustomField = (value: string, field: InvoiceCustomFieldDTO): AutoTransactionInvoiceCustomFieldDTO => ({
        AutoTransactionId: formik.values.id,
        CustomizationId: field.Id,
        CustomizationCode: field.Code,
        CustomizationType: field.FieldType,
        Value: value,
        ModifiedBy: null,
    });

    const updateAutoTransactionsCustomFieldsArray = (field: AutoTransactionInvoiceCustomFieldDTO) => {
        const array = cloneDeep(formik.values.autoTransactionsCustomFields);
        const index = array.findIndex((e) => e.CustomizationId === field.CustomizationId);
        if (index !== -1) {
            if (field.Value) {
                array[index] = field;
            } else {
                array.splice(index, 1);
            }
            return array;
        } else if (field.Value) {
            return [...array, field];
        } else {
            return array;
        }
    };

    const changeCustomTypeaheadField = (field: InvoiceCustomFieldItemDTO) => {
        formik.setFieldValue(
            fieldNamePrefix,
            updateAutoTransactionsCustomFieldsArray(
                mapFormValuesToAutoCustomField(
                    field.Code,
                    allCustomFields.find((e) => e.Id === field.InvoiceCustomFieldId),
                ),
            ),
        );
    };

    const changeCustomDateField = (day: string, customizationId: number) => {
        formik.setFieldValue(
            fieldNamePrefix,
            updateAutoTransactionsCustomFieldsArray(
                mapFormValuesToAutoCustomField(
                    day,
                    allCustomFields.find((e) => e.Id === customizationId),
                ),
            ),
        );
    };

    const changeCustomTextField = (value: string, customizationId: number) => {
        formik.setFieldValue(
            fieldNamePrefix,
            updateAutoTransactionsCustomFieldsArray(
                mapFormValuesToAutoCustomField(
                    value,
                    allCustomFields.find((e) => e.Id === customizationId),
                ),
            ),
        );
    };

    const debounceChangeCustomTextField = debounce(changeCustomTextField, 500);

    const getCustomFieldInput = (field: InvoiceCustomFieldDTO, index: number) => {
        const fieldType = field?.FieldType;
        const value = formik.values.autoTransactionsCustomFields.find((e) => e.CustomizationId === field.Id);
        switch (fieldType) {
            case InvoiceFieldType.Dropdown:
                const emptyField = { InvoiceCustomFieldId: field.Id, Code: null } as InvoiceCustomFieldItemDTO;
                return (
                    <TypeaheadAsync
                        dataId={createDataId(fieldNamePrefix, field.Description)}
                        placeholder={t('component.AutoTransaction.InvoiceCustomFields.Input.LeaveAsIs')}
                        searchOnFocus
                        toggleVisible
                        key={`${index}-custom-field-${field.Code}`}
                        inputProps={{ type: TextInputType.COMPACT, label: field.Description, labelStyle: LabelStyle.UPPERCASE }}
                        debounceInterval={400}
                        loadData={(s: string) => getInvoiceCustomFieldItems(s, field)}
                        value={value ? { value: value.Value, text: value.Value } : null}
                        wrapperClass={'automation-invoice-custom-fields__field'}
                        onChange={(v: TypeaheadItem<InvoiceCustomFieldItemDTO>) => changeCustomTypeaheadField(v?.value || emptyField)}
                        itemToText={(f: InvoiceCustomFieldDTO) => `${f.Description} - ${f.Code}`}
                        ignoreInvalidValue
                    />
                );

            case InvoiceFieldType.DateTime:
                const dateValue = value ? new Date(value.Value) : null;
                return (
                    <CalendarDatePicker
                        key={`${index}-custom-field-${field.Code}`}
                        dataId={createDataId(fieldNamePrefix, field.Description)}
                        label={field.Description}
                        placeholder={t('component.AutoTransaction.InvoiceCustomFields.Input.LeaveAsIs')}
                        value={dateValue}
                        wrapperClass={'automation-invoice-custom-fields__field'}
                        onChange={(d: string) => changeCustomDateField(d, field.Id)}
                    />
                );
            default:
                return (
                    <TextInput
                        dataId={createDataId(fieldNamePrefix, field.Description)}
                        key={`${index}-custom-field-${field.Code}`}
                        placeholder={t('component.AutoTransaction.InvoiceCustomFields.Input.LeaveAsIs')}
                        labelStyle={LabelStyle.UPPERCASE}
                        type={TextInputType.COMPACT}
                        label={field.Description}
                        value={value?.Value || null}
                        wrapperClass={'automation-invoice-custom-fields__field'}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => debounceChangeCustomTextField(e.currentTarget.value, field.Id)}
                    />
                );
        }
    };

    return (
        <>
            <div className="automation-invoice-custom-fields">
                {allCustomFields &&
                    allCustomFields.map((l, i) => {
                        return getCustomFieldInput(l, i);
                    })}
            </div>
        </>
    );
};

export default InvoiceCustomFields;
