import createFieldNameConstants from '../../common/utils/fieldNames';
import { TypeaheadItem } from '../../components/Typeahead/TypeaheadAsync';
import {
    AllocationAmountType,
    AutomationStepCombineField,
    AutoTransactionInvoiceCustomFieldDTO,
    AutoTransactionMatchType,
    CostObjectiveType,
    ExtensionField,
    ExtensionType,
    MetaField,
    VatCodeDTO,
    WorkflowAssignmentType,
} from '../../services/types/ApiTypes';

export interface DimensionFields {
    type: CostObjectiveType;
    customCostObjective: TypeaheadItem<number>;
    dimension: TypeaheadItem<number>;
    readOnly: boolean;
    isNew: boolean;
    automationStepId: number;
    orderNo?: number;
}

export interface AllocationFields {
    dimensions: DimensionFields[];
    description: string;
    comment: string;
    amount: number;
    type: AllocationAmountType;
}

export interface VatRateFields {
    allocations: AllocationFields[];
    unPostedAmount: number;
    vatRate: number;
}

export interface ConditionFields {
    combineField: AutomationStepCombineField;
    combineMatchType: AutoTransactionMatchType;
    combineBySelection: TypeaheadItem<{ field: MetaField; value: string }>;
    rowSearchString: string;
    orderNo: number;
}

export interface AutomationStepFields {
    vatRates: VatRateFields[];
    combinationOption: number;
    conditions: ConditionFields[];
    isNew: boolean;
    automationStepOrderNo: number;
    automationStepId: number;
    comment: string;
    orderNo: number;
    id: number;
}

export interface TriggerFields {
    matchType: AutoTransactionMatchType;
    extensionType: ExtensionType;
    extensionField: ExtensionField;
    value: number | string | TypeaheadItem<number>;
    displayValue: string;
}

export interface TriggersViewFields {
    triggers: TriggerFields[];
    isRuleActive: boolean;
    stopProcessing: boolean;
}

export interface WorkflowFields {
    assignee: TypeaheadItem<number>;
    type: WorkflowAssignmentType;
    enabled: boolean;
}

export interface AutoTransactionsAddViewFields {
    id: number;
    isNew: boolean;
    ruleName: string;
    ruleDescription: string;
    referenceNumber: number;
    workflow: WorkflowFields;
    triggersView: TriggersViewFields;
    autoTransactionsRows: AutomationStepFields[];
    companyVatCodes: VatCodeDTO[];
    recreateTransactionRows: boolean;
    autoTransactionsCustomFields: AutoTransactionInvoiceCustomFieldDTO[];
}

const fieldNamesSource: AutoTransactionsAddViewFields = {
    id: null,
    isNew: true,
    ruleName: null,
    ruleDescription: null,
    workflow: null,
    referenceNumber: null,
    triggersView: null,
    autoTransactionsRows: [],
    companyVatCodes: [],
    recreateTransactionRows: null,
    autoTransactionsCustomFields: [],
};

const fieldNames = createFieldNameConstants(fieldNamesSource);
export default fieldNames;
