import { push } from 'connected-react-router';

import i18n from '../../i18n';
import api from '../../services/ApiServices';
import { DispatchThunk } from '../../storeConfig';
import { notify } from '../../common/utils/notify';
import { PurchaseOrdersDTO } from '../../services/types/ApiTypes';

export const addNewPurchaseOrder = (po: PurchaseOrdersDTO) => {
    return async (dispatch: DispatchThunk) => {
        try {
            const response = await api.purchaseOrder.addNewPurchaseOrder(po);
            if (response.data) {
                dispatch(push(`/purchase-orders/details/${response.data.Id}`));
            } else {
                notify.info(i18n.t('view.PurchaseOrders.Error.General'));
            }
        } catch (e) {
            console.error(e);
            if (e.response?.data?.ErrorCode) {
                notify.error(i18n.t(e.response.data.ErrorCode));
            }
        }
    };
};
