import * as React from 'react';
import { Helmet } from 'react-helmet';

import { getWhitelabelFavicon, getWhitelabelPageTitle } from '../../common/utils/whitelabelHelper';

export interface WhitelabelHelmetProps {
    children?: React.ReactNode;
}

const WhitelabelHelmet: React.FunctionComponent<WhitelabelHelmetProps> = () => {
    return (
        <Helmet>
            <title>{getWhitelabelPageTitle()}</title>
            <link rel="shortcut icon" type="image/x-icon" href={`/app/${getWhitelabelFavicon()}`} />
        </Helmet>
    );
};

export default WhitelabelHelmet;
