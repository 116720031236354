import { ExtensionField, ExtensionType } from '../../../../../services/types/ApiTypes';

import { getExtensionTypeLabel } from './extensionTypeHelpers';

export const InvoiceInformationExtensionFields = (extensionType: ExtensionType) => {
    const type = getExtensionTypeLabel(extensionType);
    return {
        [ExtensionField.InvoiceType]: `${type}.InvoiceType`,
        [ExtensionField.FactorContractNumber]: `${type}.FactorContractNumber`,
        [ExtensionField.ContractNumber]: `${type}.ContractNumber`,
        [ExtensionField.DocumentName]: `${type}.DocumentName`,
        [ExtensionField.InvoiceNumber]: `${type}.InvoiceNumber`,
        [ExtensionField.ContentCode]: `${type}.ContentCode`,
        [ExtensionField.ContentText]: `${type}.ContentText`,
        [ExtensionField.ExtensionId]: `${type}.ExtensionId`,
        [ExtensionField.ExtensionName]: `${type}.ExtensionName`,
        [ExtensionField.ReferenceNumber]: `${type}.ReferenceNumber`,
    };
};

export const CompanyExtensionFields = (extensionType: ExtensionType) => {
    const type = getExtensionTypeLabel(extensionType);
    const companyExtensionFields = {
        [ExtensionField.GLN]: `${type}.GLN`,
        [ExtensionField.UniqueCode]: `${type}.UniqueCode`,
        [ExtensionField.DepartmentId]: `${type}.DepartmentId`,
        [ExtensionField.ContactPerson]: `${type}.ContactPerson`,
        [ExtensionField.ExtensionId]: `${type}.ExtensionId`,
        [ExtensionField.ExtensionName]: `${type}.ExtensionName`,
    };
    if (extensionType === ExtensionType.SellerParty) {
        companyExtensionFields[ExtensionField.Company] = `${type}.Company`;
    }
    return companyExtensionFields;
};

export const PaymentInformationExtensionFields = (extensionType: ExtensionType) => {
    const type = getExtensionTypeLabel(extensionType);
    return {
        [ExtensionField.BeneficiaryName]: `${type}.BeneficiaryName`,
    };
};

export const InvoiceRowsExtensionFields = (extensionType: ExtensionType) => {
    const type = getExtensionTypeLabel(extensionType);
    return {
        [ExtensionField.Description]: `${type}.Description`,
    };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const InvoiceSumGroupExtensionFields = (extensionType: ExtensionType) => {
    // const type = getExtensionTypeLabel(extensionType);
    return {
        [ExtensionField.GrandTotal]: 'view.AutoTransactions.Details.Triggers.ExtensionType.InvoiceInformation.GrandTotal',
        [ExtensionField.NETAmount]: 'view.AutoTransactions.Details.Triggers.ExtensionType.InvoiceInformation.NetAmount',
        // [ExtensionField.ExtensionId]: `${type}.ExtensionId`,
        // [ExtensionField.ExtensionName]: `${type}.ExtensionName`,
        // [ExtensionField.ExtensionContent]: `${type}.ExtensionContent`,
    };
};

export const getExtensionFields = (extensionType: ExtensionType, extensionField?: number) => {
    switch (extensionType) {
        case ExtensionType.InvoiceInformation:
            if (extensionField) {
                return InvoiceInformationExtensionFields(extensionType)[extensionField];
            }
            return InvoiceInformationExtensionFields(extensionType);
        case ExtensionType.InvoiceRows:
            if (extensionField) {
                return InvoiceRowsExtensionFields(extensionType)[extensionField];
            }
            return InvoiceRowsExtensionFields(extensionType);
        case ExtensionType.PaymentInformation:
            if (extensionField) {
                return PaymentInformationExtensionFields(extensionType)[extensionField];
            }
            return PaymentInformationExtensionFields(extensionType);
        case ExtensionType.InvoiceSumGroup:
            if (extensionField) {
                return InvoiceSumGroupExtensionFields(extensionType)[extensionField];
            }
            return InvoiceSumGroupExtensionFields(extensionType);
        case ExtensionType.SellerParty:
        case ExtensionType.PayerParty:
        case ExtensionType.BuyerParty:
        case ExtensionType.DeliveryParty:
        case ExtensionType.FactorParty:
        case ExtensionType.RecipientParty:
            if (extensionField) {
                return CompanyExtensionFields(extensionType)[extensionField];
            }
            return CompanyExtensionFields(extensionType);
        default:
            return {};
    }
};
