import { CombinationOptionType } from '../../../../../services/types/ApiTypes';
import React from 'react';
import { DropdownMenu, DropdownMenuItem } from '../../../../../components/DropdownMenu/DropdownMenu';
import { addDataId, createDataId } from '../../../../../common/utils/dataId';
import { Button, ButtonType } from '../../../../../components/Buttons/Button';
import Icon, { ICONS, IconSize } from '../../../../../components/Icon/Icon';
import { createAutomationStepFields, getCombinationType, getCombinationTypes } from '../../../AutoTransactionsAddViewHelper';
import { TFunction } from 'i18next';
import { AutomationStepFields } from '../../../autoTransactionAddViewFields';

export interface CombinationOptionSelectionProps {
    combinationType?: CombinationOptionType;
    t: TFunction;
    callback(step: number | AutomationStepFields): void;
    fieldNamePrefix?: string;
    content?: React.ReactNode;
    icon?: boolean;
}

export const CombinationOptionSelection: React.FC<CombinationOptionSelectionProps> = ({ combinationType = null, t, callback, fieldNamePrefix = 'automationStep', content, icon = true }) => {
    return (
        <DropdownMenu
            dataId={addDataId(combinationType ? `${fieldNamePrefix}.changeStep.typeDropdown` : `${fieldNamePrefix}.addStep.typeDropdown`, '')}
            items={Object.keys(getCombinationTypes()).map((step, index) => (
                <DropdownMenuItem
                    key={index}
                    dataId={createDataId('option', index)}
                    onClick={() => {
                        if (combinationType !== null) {
                            callback(parseInt(step, 10));
                        } else {
                            callback(createAutomationStepFields(parseInt(step, 10)));
                        }
                    }}
                >
                    {t(getCombinationType(parseInt(step, 10)))}
                </DropdownMenuItem>
            ))}
        >
            <Button
                dataId={addDataId(combinationType ? `${fieldNamePrefix}.changeStep.typeDropdown` : `${fieldNamePrefix}.addStep.typeDropdown`, '.trigger')}
                buttonType={ButtonType.TEXT}
                className={'automation-step__item' + (!combinationType && !content ? ' automation-step__item--add-new' : '')}
            >
                {!!icon && (
                    <>
                        <Icon name={ICONS.PLUS_THICK_24} size={IconSize.XS} />
                        &nbsp;
                    </>
                )}
                {combinationType ? t(getCombinationType(combinationType)) : content ? content : t('component.AutoTransaction.Menu.ClickToAdd.TransactionRow')}
            </Button>
        </DropdownMenu>
    );
};

export default React.memo(CombinationOptionSelection);
