import { debounce } from 'lodash-es';
import * as React from 'react';
import { WithTranslation, Trans } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatchable0, Dispatchable1, Dispatchable2 } from 'redux-dispatchers';

import { isAuthorized, Role } from '../../common/user/userPermissionUtil';
import { createDataId } from '../../common/utils/dataId';
import { formatDate } from '../../common/utils/formatters';
import AccessLevel from '../../components/AccessLevel/AccessLevel';
import { BaseStatefulComponent } from '../../components/BaseStatefulComponent';
import { ContentBlock } from '../../components/ContentBlock/ContentBlock';
import { ContentBlockBody } from '../../components/ContentBlock/ContentBlockBody';
import { ContentBlockFooter, ContentBlockFooterType } from '../../components/ContentBlock/ContentBlockFooter';
import { ContentBlockHeader, ContentBlockHeaderType } from '../../components/ContentBlock/ContentBlockHeader';
import { ICONS } from '../../components/Icon/Icon';
import LabelWrapper, { LabelAlignment } from '../../components/LabelWrapper/LabelWrapper';
import { MainPage, MainPageType } from '../../components/MainPage/MainPage';
import Pager from '../../components/Pager/Pager';
import { Switch } from '../../components/Switch/Switch';
import { TableFilter } from '../../components/Table/components/filter/TableFilters';
import Table from '../../components/Table/Table';
import TableBody from '../../components/Table/TableBody';
import TableCell, { ResponsiveCellType } from '../../components/Table/TableCell';
import TableHead from '../../components/Table/TableHead';
import { DataTableHeader } from '../../components/Table/TableHeader';
import TableRow from '../../components/Table/TableRow';
import { Typography } from '../../components/Typography';
import { User } from '../../services/ApiClient';
import { Restriction, UserCreationDTO, UserDTO, SystemConfigurationDTO } from '../../services/types/ApiTypes';

import { UserRoles } from './components/UserRoles/UserRoles';
import EditUserModalWrapper from './components/EditUserModal/EditUserModalWrapper';
import { getUserRole, DEFAULT_RESTRICTION, getEmailValidationStatusType } from './UserListViewHelpers';
import { UserListSearchParams, UserManagementViewState } from './UserListViewReducer';
import { DropdownMenu, DropdownMenuItem } from '../../components/DropdownMenu/DropdownMenu';
import { Button, ButtonIconPlacement, ButtonType } from '../../components/Buttons/Button';
import ModalConfirm, { ConfirmButtonStyle } from '../../components/Modal/ModalConfirm';
import { sendEmailVerificationLink } from '../../components/EmailVerification/EmailVerificationHelper';
import Status from '../../components/Status/Status';
import Tooltip from '../../components/Tooltip/Tooltip';

import './UserListView.scss';

export interface Props extends Pick<UserManagementViewState, 'activeUsersCountLoadable' | 'usersListLoadable' | 'searchParams'> {
    userData: User;
    currentCompanySettingsLoading: boolean;
    currentCompanySettings: SystemConfigurationDTO[];
}

export interface DispatchProps {
    saveNewUser: Dispatchable1<UserCreationDTO>;
    getActiveUsersCount: Dispatchable0;
    getCurrentCompanySettings: Dispatchable1<boolean>;
    filterUserList: Dispatchable2<Restriction, TableFilter<any>>;
    sortUserList: Dispatchable1<string>;
    getUserList: Dispatchable1<UserListSearchParams>;
    searchUserList: Dispatchable1<string>;
    changeUserStatus: Dispatchable1<UserCreationDTO>;
    setUserListPagingOptions: Dispatchable2<number, number>;
    exportUsersToCsv: Dispatchable0;
    exportUsersToXls: Dispatchable0;
}

interface RouteParams {}

export type UserListViewProps = Props & DispatchProps & WithTranslation & RouteComponentProps<RouteParams>;

export interface State {
    appliedFilters: Array<TableFilter<any>>;
    showEditUserModal: boolean;
    companyCurrency: string;
    activeResendEmailUser: UserDTO;
}

export class UserListView extends BaseStatefulComponent<UserListViewProps, State> {
    constructor(props: UserListViewProps) {
        super(props);
        this.state = {
            appliedFilters: [],
            showEditUserModal: false,
            companyCurrency: null,
            activeResendEmailUser: null,
        };
    }

    componentDidMount(): void {
        if (!isAuthorized(Role.CanViewSettingsUsers, this.props.userData)) {
            this.props.history.push('/dashboard');
            return;
        }
        this.props.getUserList(undefined);
        this.props.getActiveUsersCount();
        this.props.getCurrentCompanySettings(true);
    }

    componentDidUpdate(): void {
        const { currentCompanySettingsLoading, currentCompanySettings } = this.props;
        if (!currentCompanySettingsLoading && currentCompanySettings) {
            const currencySetting = currentCompanySettings.find((s) => s.Name === 'DefaultCurrency');
            this.setState({ companyCurrency: currencySetting?.Value || null });
        }
    }

    // componentDidUpdate(prevProps: Readonly<UserListViewProps>, prevState: Readonly<State>, snapshot?: any): void {
    // TODO @tonister: uncomment when we're ready to move forward with this feature
    // const { searchParams } = prevProps;
    // const filters = searchParams && parseRestrictions(searchParams);
    // if (!isEqual(this.state.appliedFilters, filters)) {
    //     this.setState({
    //         appliedFilters: filters,
    //     });
    // }
    // }

    debounceSendEmailVerificationLink = debounce((user: UserDTO) => {
        sendEmailVerificationLink(user.BOGuid, user.Email, true);
    }, 400);

    debounceDoSearch = debounce((searchString: string) => {
        this.props.searchUserList(searchString);
    }, 400);

    // TODO @tonister: uncomment when we're ready to move forward with this feature
    // debounceDoFilter = debounce((filter: TableFilter<any>) => {
    //     this.props.filterUserList(parseFilters(filter), filter);
    // }, 400);

    handleSearchInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.debounceDoSearch(evt.target.value);
    };

    handleSearchInputClick = (value: string) => {
        this.debounceDoSearch(value);
    };

    handlePageChange = (page: number, pageSize?: number) => {
        this.props.setUserListPagingOptions(page, pageSize);
    };

    // TODO @tonister: uncomment when we're ready to move forward with this feature
    // handleRolesSelectChange = (filter: TableFilter<any>) => {
    //     this.debounceDoFilter(filter);
    // };

    handleUserActiveStateChange = (user: UserDTO, checked: boolean) => {
        const updatedUser: UserCreationDTO = {
            FirstName: user.FirstName,
            LastName: user.LastName,
            Country: user.Country,
            PersonalCode: user.PersonalCode,
            Email: user.Email,
            MembershipStart: user.GroupMember.MembershipStart,
            MembershipEnd: user.GroupMember.MembershipEnd,
            MemberRoles: user.GroupMember.MemberRoles,
            UserGuid: user.BOGuid,
            IsActive: checked,
        };
        this.props.changeUserStatus(updatedUser);
    };

    // TODO @tonister: uncomment when we're ready to move forward with this feature
    // handleUserDelete = (userId: number) => {
    // };

    // TODO @tonister: uncomment when we're ready to move forward with this feature
    // handleFilterRemove = (index: number, filter: TableFilter<any>) => {
    //     const removedFilter = this.state.appliedFilters.find((f) => {
    //         return f.columnName === filter.columnName;
    //     });
    //     // filters with empty values list will be removed upon filtering, hence empty the values
    //     removedFilter.values = [];
    //     this.debounceDoFilter(removedFilter);
    // };

    // TODO @tonister: uncomment when we're ready to move forward with this feature
    // getRoles = (t: any) => {
    //     const roles = getRoles();
    //     return Object.keys(roles).map((role) => ({
    //         text: t(roles[role]),
    //         value: role,
    //     }));
    // };

    getSearchValue() {
        const searchRestriction = this.props.searchParams.Restrictions.find((r) => r.Field === DEFAULT_RESTRICTION);
        return searchRestriction ? searchRestriction.Value : null;
    }

    toggleNewUserModal = () => this.setState((prevState) => ({ showEditUserModal: !prevState.showEditUserModal }));

    render() {
        const { t, activeUsersCountLoadable, usersListLoadable, searchParams, sortUserList, exportUsersToCsv, exportUsersToXls } = this.props;
        // TODO @tonister: uncomment when we're ready to move forward with this feature
        // const { appliedFilters } = this.state;
        return (
            <MainPage className="user-management" type={MainPageType.WIDE}>
                <ContentBlock loading={false}>
                    <ContentBlockHeader
                        className="user-management__header"
                        hasSearch={true}
                        searchOptions={{ withSearchButton: true, dataId: createDataId('userListSearch'), value: this.getSearchValue(), onClick: this.handleSearchInputClick }}
                        // TODO: uncomment when we're ready to move forward with this feature
                        // filterable={true}
                        // filterOptions={{ appliedFilters, t, onRemove: this.handleFilterRemove, dataId: 'userListFilter' }}
                        dataId="userListView.header"
                        type={ContentBlockHeaderType.TABLE_HEADER}
                        title={
                            <Typography variant="h1" element="span">
                                {t('views.home.index.Users')} ({usersListLoadable.payload?.TotalCount || 0})
                            </Typography>
                        }
                    >
                        <DropdownMenu
                            dataId={createDataId('userListView', 'export')}
                            items={
                                <>
                                    <DropdownMenuItem icon={ICONS.CSV} onClick={exportUsersToCsv} dataId={createDataId('userListView', 'exportToCsv')}>
                                        {t('views.global.csv')}
                                    </DropdownMenuItem>
                                    <DropdownMenuItem icon={ICONS.TABLE} onClick={exportUsersToXls} dataId={createDataId('userListView', 'exportToExcel')}>
                                        {t('views.global.excel')}
                                    </DropdownMenuItem>
                                </>
                            }
                        >
                            <Button
                                className="content-block__header-text-button"
                                buttonType={ButtonType.ICON_TEXT}
                                icon={ICONS.EXPORT_24}
                                iconPlacement={ButtonIconPlacement.LEFT}
                                dataId={createDataId('userListView', 'export')}
                            >
                                {t('views.global.export')}
                            </Button>
                        </DropdownMenu>
                        <AccessLevel role={Role.CanAddUser}>
                            <Button
                                className="content-block__header-text-button"
                                buttonType={ButtonType.ICON_TEXT}
                                onClick={this.toggleNewUserModal}
                                icon={ICONS.PLUS_THICK_24}
                                iconPlacement={ButtonIconPlacement.LEFT}
                                dataId={createDataId('newUserButton')}
                            >
                                {t('view.UserList.Add')}
                            </Button>
                        </AccessLevel>
                    </ContentBlockHeader>
                    <div className="user-management__table">
                        <ContentBlockBody className="user-management__table-content" loading={usersListLoadable.loading} dataId="contentBlockBodyUserList">
                            <Table>
                                <DataTableHeader data-id={createDataId('userListView', 'Table', 'Header')}>
                                    <TableRow>
                                        <TableHead
                                            sortable={true}
                                            searchParams={searchParams}
                                            columnName="IsActive"
                                            onClick={sortUserList}
                                            dataId={createDataId('UserManagementTheadSort', 'IsActive')}
                                        >
                                            {t('view.UserList.Column.IsActive')}
                                        </TableHead>
                                        <TableHead
                                            sortable={true}
                                            searchParams={searchParams}
                                            columnName="FirstName"
                                            onClick={sortUserList}
                                            dataId={createDataId('UserManagementTheadSort', 'FirstName')}
                                        >
                                            {t('view.UserList.Column.Name')}
                                        </TableHead>
                                        <TableHead
                                            sortable={true}
                                            searchParams={searchParams}
                                            columnName="LastName"
                                            onClick={sortUserList}
                                            dataId={createDataId('UserManagementTheadSort', 'LastName')}
                                        >
                                            {t('view.UserList.Column.Surname')}
                                        </TableHead>
                                        <TableHead sortable={true} searchParams={searchParams} columnName="Email" onClick={sortUserList} dataId={createDataId('UserManagementTheadSort', 'Email')}>
                                            {t('view.UserList.Column.Email')}
                                        </TableHead>
                                        <TableHead sortable={true} searchParams={searchParams} columnName="Country" onClick={sortUserList} dataId={createDataId('UserManagementTheadSort', 'Country')}>
                                            {t('views.global.country')}
                                        </TableHead>
                                        <TableHead
                                            sortable={true}
                                            searchParams={searchParams}
                                            columnName="PersonalCode"
                                            onClick={sortUserList}
                                            dataId={createDataId('UserManagementTheadSort', 'PersonalCode')}
                                        >
                                            {t('view.UserList.Column.PersonalCode')}
                                        </TableHead>
                                        <TableHead
                                            sortable={true}
                                            searchParams={searchParams}
                                            columnName="StartDate"
                                            onClick={sortUserList}
                                            // TODO @tonister: uncomment when we're ready to move forward with this feature
                                            // filterable={true}
                                            // filterActive={userTouchedEndDateFilter}
                                            // filterItems={this.getStatuses()}
                                            // filterValues={appliedFilters['StartDate'] : []}
                                            // onFilter={(items) => this.handleDateSelectChange('StartDate', items)}
                                            dataId={createDataId('UserManagementTheadSort', 'StartDate')}
                                        >
                                            {t('view.UserList.Column.StartDate')}
                                        </TableHead>
                                        <TableHead
                                            sortable={true}
                                            searchParams={searchParams}
                                            columnName="EndDate"
                                            onClick={sortUserList}
                                            // TODO @tonister: uncomment when we're ready to move forward with this feature
                                            // filterable={true}
                                            // filterActive={userTouchedEndDateFilter}
                                            // filterItems={this.getStatuses()}
                                            // filterValues={userTouchedEndDateFilter ? appliedFilters['EndDate'] : []}
                                            // onFilter={(items) => this.handleDateSelectChange('EndDate', items)}
                                            dataId={createDataId('UserManagementTheadSort', 'EndDate')}
                                        >
                                            {t('view.UserList.Column.EndDate')}
                                        </TableHead>
                                        <TableHead
                                            sortable={true}
                                            searchParams={usersListLoadable.request}
                                            columnName="Roles"
                                            onClick={sortUserList}
                                            // TODO @tonister: uncomment when we're ready to move forward with this feature
                                            // filterable={true}
                                            // filterActive={!isEmpty(isFilterActive('Roles', usersListLoadable.request))}
                                            // filterItems={this.getRoles(t)}
                                            // filterValues={!isEmpty(isFilterActive('Roles', usersListLoadable.request)) ? isFilterActive('Roles', usersListLoadable.request).values : []}
                                            // onFilter={(items) => {
                                            //     const filter = isFilterActive('Roles', usersListLoadable.request);
                                            //     if (isEmpty(filter)) {
                                            //         this.handleRolesSelectChange({
                                            //             placeholder: null,
                                            //             tagSelectType: TagSelectType.COMPACT,
                                            //             onSelectChangeCallback: this.debounceDoFilter,
                                            //             values: items,
                                            //             label: t(getUserListColumnName('Roles')),
                                            //             columnName: 'Roles',
                                            //             items: this.getRoles(t),
                                            //             loadItems: null,
                                            //         });
                                            //     } else {
                                            //         this.handleRolesSelectChange({
                                            //             ...filter,
                                            //             values: items,
                                            //         });
                                            //     }
                                            // }}
                                            dataId={createDataId('UserManagementTheadSort', 'Roles')}
                                        >
                                            {t('view.UserList.Column.Roles')}
                                        </TableHead>
                                        <TableHead className="btn-wrapper">{'\u00A0'}</TableHead>
                                    </TableRow>
                                </DataTableHeader>
                                <TableBody>
                                    {usersListLoadable &&
                                        usersListLoadable.payload &&
                                        usersListLoadable.payload.Items.map((user, id) => (
                                            <TableRow key={user.BOGuid} data-id={createDataId('usersList.row', id)}>
                                                <TableCell
                                                    link={false}
                                                    dataId={createDataId('UserManagement', `${id}.IsActive`)}
                                                    style={{ width: '75px' }}
                                                    responsiveCellType={ResponsiveCellType.LABEL}
                                                >
                                                    <Switch
                                                        dataId={createDataId('UserManagement.IsActive', 'Switch').toString()}
                                                        name=""
                                                        onChange={(checked: boolean) => {
                                                            this.handleUserActiveStateChange(user, checked);
                                                        }}
                                                        value={user.IsActive}
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    link={true}
                                                    linkTo={`/users/${user.BOGuid}`}
                                                    dataId={createDataId('UserManagement', `${id}.FirstName`)}
                                                    hideOverflow={true}
                                                    style={{ width: '200px' }}
                                                    responsiveCellType={ResponsiveCellType.LABEL}
                                                >
                                                    {user.FirstName || '\u00A0'}
                                                </TableCell>
                                                <TableCell
                                                    link={true}
                                                    linkTo={`/users/${user.BOGuid}`}
                                                    dataId={createDataId('UserManagement', `${id}.LastName`)}
                                                    hideOverflow={true}
                                                    style={{ width: '200px' }}
                                                    responsiveCellType={ResponsiveCellType.LABEL}
                                                >
                                                    {user.LastName || '\u00A0'}
                                                </TableCell>
                                                <TableCell
                                                    link={true}
                                                    linkTo={`/users/${user.BOGuid}`}
                                                    dataId={createDataId('UserManagement', `${id}.EMail`)}
                                                    hideOverflow={true}
                                                    style={{ minWidth: '100px', width: '300px' }}
                                                >
                                                    {<Status type={getEmailValidationStatusType(user)}>{user.Email || '-'}</Status>}
                                                </TableCell>
                                                <TableCell link={true} linkTo={`/users/${user.BOGuid}`} dataId={createDataId('UserManagement', `${id}.Country`)} hideOverflow={true}>
                                                    {user.Country || '-'}
                                                </TableCell>
                                                <TableCell
                                                    link={true}
                                                    linkTo={`/users/${user.BOGuid}`}
                                                    dataId={createDataId('UserManagement', `${id}.PersonalCode`)}
                                                    style={{ width: '100px' }}
                                                    hideOverflow={true}
                                                >
                                                    {user.PersonalCode || '-'}
                                                </TableCell>
                                                <TableCell link={true} linkTo={`/users/${user.BOGuid}`} dataId={createDataId('UserManagement', `${id}.StartDate`)}>
                                                    {user.GroupMember && user.GroupMember.MembershipStart ? formatDate(user.GroupMember.MembershipStart) : '-'}
                                                </TableCell>
                                                <TableCell link={true} linkTo={`/users/${user.BOGuid}`} dataId={createDataId('UserManagement', `${id}.EndDate`)} nowrap={true}>
                                                    {user.GroupMember && user.GroupMember.MembershipEnd ? formatDate(user.GroupMember.MembershipEnd) : '-'}
                                                </TableCell>
                                                <TableCell link={true} linkTo={`/users/${user.BOGuid}`} dataId={createDataId('UserManagement', `${id}.Roles`)} nowrap={true}>
                                                    {user.GroupMember && user.GroupMember.MemberRoles ? (
                                                        <UserRoles userRoles={user.GroupMember.MemberRoles.map((role) => t(getUserRole(role.Role)))} />
                                                    ) : (
                                                        '-'
                                                    )}
                                                </TableCell>
                                                <TableCell className="btn-wrapper" dataId={createDataId('UserManagement', `${id}.Options`)}>
                                                    {/* TODO @tonister: uncomment when we're ready to move forward with this feature */}
                                                    {/*<UserListItemMenu*/}
                                                    {/*    t={t}*/}
                                                    {/*    dataId={addDataId('UserManagement', `.ItemMenu.${id}`)}*/}
                                                    {/*    isReadOnly={false}*/}
                                                    {/*    userId={user.Id}*/}
                                                    {/*    deleteUser={this.handleUserDelete}*/}
                                                    {/*/>*/}
                                                    {!user?.EmailLastValidated && user?.Email && (
                                                        <AccessLevel role={Role.CanResendActivationEmail}>
                                                            <Tooltip content={t('view.UserList.resendVerification')}>
                                                                <Button
                                                                    className="user-list__resend-email-button"
                                                                    buttonType={ButtonType.ICON}
                                                                    icon={ICONS.INVITE_24}
                                                                    onClick={() =>
                                                                        this.setState({
                                                                            activeResendEmailUser: user,
                                                                        })
                                                                    }
                                                                    iconPlacement={ButtonIconPlacement.LEFT}
                                                                    dataId={createDataId('UserManagement', `${id}.ActivationEmail`)}
                                                                />
                                                            </Tooltip>
                                                        </AccessLevel>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>

                            <EditUserModalWrapper isModalOpen={this.state.showEditUserModal} onClose={this.toggleNewUserModal} />
                            <ModalConfirm
                                dataId={createDataId('UserManagement', 'Modal', 'ResendActivationEmail')}
                                open={!!this.state.activeResendEmailUser}
                                onClose={() => {
                                    this.setState({
                                        activeResendEmailUser: null,
                                    });
                                }}
                                title={t('view.UserList.Modal.ResendActivationEmail.Title')}
                                confirmText={t('component.invoiceHeader.delete.modal.proceed')}
                                handleActions={() => {
                                    this.debounceSendEmailVerificationLink(this.state.activeResendEmailUser);
                                    this.setState({
                                        activeResendEmailUser: null,
                                    });
                                }}
                                confirmButtonIcon={ICONS.CHECK_24}
                                cancelActions={() => {
                                    this.setState({
                                        activeResendEmailUser: null,
                                    });
                                }}
                                cancelText={t('component.invoiceHeader.delete.modal.cancel')}
                                confirmButtonStyle={ConfirmButtonStyle.PRIMARY}
                                noCloseButton={true}
                            >
                                <Trans i18nKey={'view.UserList.Modal.ResendActivationEmail.BodyText'} values={{ email: this.state.activeResendEmailUser?.Email }}>
                                    <b>{this.state.activeResendEmailUser?.Email}</b>
                                </Trans>
                            </ModalConfirm>
                        </ContentBlockBody>
                    </div>
                    <ContentBlockFooter contentClass={'user-list__footer'} type={ContentBlockFooterType.PAGER}>
                        <Pager pages={usersListLoadable.payload} onPageChange={this.handlePageChange} />
                        <LabelWrapper
                            className="user-list__total-active-users"
                            labelClassName="user-list__total-active-users-label"
                            label={t('view.UserList.TotalActive')}
                            alignment={LabelAlignment.RIGHT}
                            dataId={createDataId('UserManagement', 'totalActiveUsers')}
                        >
                            {(activeUsersCountLoadable && activeUsersCountLoadable.payload) || '0'}
                        </LabelWrapper>
                    </ContentBlockFooter>
                </ContentBlock>
            </MainPage>
        );
    }
}

export default UserListView;
