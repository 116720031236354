import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getCompanyGroupMembers } from '../../common/company/CompanyActions';
import { GlobalState } from '../../rootReducer';

import UserAddView, { DispatchProps, Props } from './UserAddView';
import {
    deleteSubstitute,
    filterDimensions,
    getCostObjectives,
    getUserData,
    save,
    searchDimensions,
    setActiveCostObjective,
    setAllCostObjectiveItemsForUser,
    setAllCostObjectiveItemsOnPageForUser,
    setPagingOptions,
    sortDimensions,
    toggleCostObjectiveItemForUser,
} from './UserAddViewActions';
import {
    selectActiveCostObjective,
    selectCostObjectivesLoadable,
    selectDimensionsLoadable,
    selectSearchParams,
    selectSaveUserLoadable,
    selectSubstitutes,
    selectUserDataLoadable,
} from './UserAddViewReducer';

const mapStateToProps = (state: GlobalState): Props => {
    return {
        groupMemberLoadable: selectUserDataLoadable(state),
        substitutes: selectSubstitutes(state),
        costObjectivesLoadable: selectCostObjectivesLoadable(state),
        dimensionsLoadable: selectDimensionsLoadable(state),
        activeCostObjective: selectActiveCostObjective(state),
        searchParams: selectSearchParams(state),
        saveUserLoadable: selectSaveUserLoadable(state),
    };
};

const mapDispatchToProps: DispatchProps = {
    getUserData,
    save,
    getCompanyGroupMembers,
    deleteSubstitute,
    getCostObjectives,
    setActiveCostObjective,
    searchDimensions,
    filterDimensions,
    setPagingOptions,
    toggleCostObjectiveItemForUser,
    sortDimensions,
    setAllCostObjectiveItemsForUser,
    setAllCostObjectiveItemsOnPageForUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UserAddView));
