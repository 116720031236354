import { TFunction } from 'i18next';
import * as React from 'react';
import { History } from 'history';
import { Dispatchable1, Dispatchable2 } from 'redux-dispatchers';
import classNames from 'classnames';
import Collapsible from 'react-collapsible';

import { BaseStatefulComponent } from '../../../../components/BaseStatefulComponent';
import { isAuthorized, Role } from '../../../../common/user/userPermissionUtil';
import { createDataId, WithDataId } from '../../../../common/utils/dataId';
import { LoadableData } from '../../../../common/utils/LoadableData';
import { Button, ButtonIconPlacement, ButtonType } from '../../../../components/Buttons/Button';
import { ContentBlock } from '../../../../components/ContentBlock/ContentBlock';
import { EditSupplierModalState } from '../../../../components/EditSupplierModal/EditSupplierModalReducer';
import { SupplierDTO, PurchaseOrderStatus } from '../../../../services/types/ApiTypes';
import { SelectOptionValue } from '../../../../components/Select/SelectOption';
import { PurchaseOrdersAddViewState } from '../../PurchaseOrdersAddViewReducer';
import { PurchaseOrdersAddViewFields } from '../../purchaseOrderAddViewFields';
import { PurchaseOrderHeaderBlocks } from '../../PurchaseOrdersAddViewHelper';
import { ICONS } from '../../../../components/Icon/Icon';
import HeaderViewMode from './HeaderViewMode';
import HeaderEditMode from './HeaderEditMode';
import { HeaderPlaceholder } from './HeaderPlaceholder';
import HistoryListWrapper from './HistoryList/HistoryListWrapper';
import InvoicesListWrapper from './InvoicesList/InvoicesListWrapper';

import './Header.scss';

interface Props extends Pick<EditSupplierModalState, 'addSupplierLoadable'>, Pick<PurchaseOrdersAddViewState, 'retrievePOLoadable' | 'updateExtraStatusLoadable'> {
    t: TFunction;
    loading: boolean;
    isReadOnly: boolean;
    isEditting: boolean;
    handleEditClick: (state: boolean) => void;
    orderStatus: PurchaseOrderStatus;
    showAddOrEditSupplierModal: () => void;
    updateSupplierLoadable: LoadableData<SupplierDTO, SupplierDTO>;
    deletePurchaseOrder: (id: number) => void;
    exportPurchaseOrderToPdf: Dispatchable2<string, string>;
    duplicatePurchaseOrder: Dispatchable1<string>;
    updatePurchaseOrder: Dispatchable1<PurchaseOrdersAddViewFields>;
    purchaseOrderId: string;
    history: History;
}

interface HeaderState {
    activeBlock: PurchaseOrderHeaderBlocks;
    open: boolean;
}

type HeaderProps = Props & WithDataId;

export interface AddSupplierFields {
    supplierName: string;
    supplierRegistrationNumber: string;
}
class Header extends BaseStatefulComponent<HeaderProps, HeaderState> {
    constructor(props: HeaderProps) {
        super(props);

        this.state = {
            activeBlock: null,
            open: true,
        };
    }

    translateExtraStatuse(status: SelectOptionValue<number>) {
        return {
            ...status,
            text: this.props.t(`view.PurchaseOrders.ExtraStatus.${status.text}`),
        };
    }

    translateExtraStatuses(statuses: Array<SelectOptionValue<number>>) {
        return statuses.map((element) => this.translateExtraStatuse(element));
    }

    setActiveBlock(block: PurchaseOrderHeaderBlocks) {
        this.setState({
            activeBlock: block,
            open: true,
        });
        this.props.handleEditClick(false);
    }

    handleBackClick() {
        this.props.history.push('/purchase-orders');
    }

    updatePO = (values: PurchaseOrdersAddViewFields) => {
        this.props.updatePurchaseOrder(values);
        this.props.handleEditClick(false);
    };

    render() {
        const { dataId, t, isReadOnly, exportPurchaseOrderToPdf, duplicatePurchaseOrder, purchaseOrderId, retrievePOLoadable, isEditting, handleEditClick } = this.props;
        return (
            <ContentBlock className="purchase-order-header">
                <div className="purchase-order-header__main-content">
                    <div className="purchase-order-header__row">
                        {!isEditting && (
                            <div className="purchase-order-header__back-button">
                                <Button
                                    onClick={() => this.handleBackClick()}
                                    dataId={createDataId(dataId, 'backButton')}
                                    icon={ICONS.ARROW_LEFT_24}
                                    className="icon-button"
                                    buttonType={ButtonType.ICON_TEXT}
                                >
                                    {t('component.purchaseOrder.backToPurchaseOrders')}
                                </Button>
                            </div>
                        )}
                        {!isEditting &&
                            (!retrievePOLoadable.loading && retrievePOLoadable.payload ? (
                                <div className="purchase-order-header__icon-buttons">
                                    <Button
                                        iconPlacement={ButtonIconPlacement.LEFT}
                                        onClick={() => exportPurchaseOrderToPdf(purchaseOrderId, retrievePOLoadable?.payload?.OrderNumber)}
                                        dataId={createDataId(dataId, 'exportToPdf')}
                                        icon={ICONS.EXPORT_24}
                                        className="icon-button"
                                        buttonType={ButtonType.ICON_TEXT}
                                    >
                                        {t('views.global.exportToPdf')}
                                    </Button>

                                    {!isReadOnly && (
                                        <Button
                                            iconPlacement={ButtonIconPlacement.LEFT}
                                            onClick={() => handleEditClick(true)}
                                            dataId={createDataId(dataId, 'editBasicInfo')}
                                            icon={ICONS.EDIT_24}
                                            className="icon-button"
                                            buttonType={ButtonType.ICON_TEXT}
                                        >
                                            {t('views.global.edit')}
                                        </Button>
                                    )}
                                    {isAuthorized(Role.CanAddPurchaseOrders) && (
                                        <Button
                                            onClick={() => duplicatePurchaseOrder(purchaseOrderId)}
                                            dataId={createDataId(dataId, 'duplicate')}
                                            icon={ICONS.COPY_24}
                                            className="icon-button"
                                            buttonType={ButtonType.ICON_TEXT}
                                        >
                                            {t('view.backOffice.companies.actions.clone')}
                                        </Button>
                                    )}
                                    {isAuthorized(Role.CanDeletePurchaseOrders) && !isReadOnly && (
                                        <Button
                                            onClick={() => {
                                                this.props.deletePurchaseOrder(retrievePOLoadable?.payload?.Id);
                                            }}
                                            dataId={createDataId(dataId, 'delete')}
                                            icon={ICONS.DELETE_24}
                                            className="icon-button delete"
                                            buttonType={ButtonType.ICON_TEXT}
                                        >
                                            {t('component.AutoTransaction.Menu.Delete')}
                                        </Button>
                                    )}
                                </div>
                            ) : (
                                <div className={'purchase-order-header__icon-buttons--preloader'}>
                                    <HeaderPlaceholder class={'header'} />
                                </div>
                            ))}
                    </div>
                    {!isEditting ? (
                        <HeaderViewMode dataId={'purchase-order-view'} t={t} purchaseOrder={retrievePOLoadable?.payload} loading={retrievePOLoadable?.loading} />
                    ) : (
                        <HeaderEditMode
                            t={t}
                            updatePurchaseOrder={this.updatePO}
                            updateSupplierLoadable={this.props.updateSupplierLoadable}
                            addSupplierLoadable={this.props.addSupplierLoadable}
                            showAddOrEditSupplierModal={this.props.showAddOrEditSupplierModal}
                            purchaseOrder={retrievePOLoadable?.payload}
                            cancelEditting={this.props.handleEditClick}
                        />
                    )}
                </div>
                <div className="purchase-order-header__basic-info-separator"></div>
                {!retrievePOLoadable.loading && retrievePOLoadable.payload ? (
                    <div className="purchase-order-header__info-blocks">
                        <div className="purchase-order-header__info-blocks-links">
                            <Button
                                className={classNames('link', { 'link--active': this.state.activeBlock === PurchaseOrderHeaderBlocks.INVOICES })}
                                buttonType={ButtonType.TEXT}
                                dataId={createDataId(dataId, 'invoices-liet')}
                                onClick={() => this.setActiveBlock(PurchaseOrderHeaderBlocks.INVOICES)}
                            >
                                {t('view.PurchaseOrders.Tabs.Invoices')}
                            </Button>
                            <Button
                                className={classNames('link', { 'link--active': this.state.activeBlock === PurchaseOrderHeaderBlocks.HISTORY })}
                                buttonType={ButtonType.TEXT}
                                dataId={createDataId(dataId, 'basic-info')}
                                onClick={() => this.setActiveBlock(PurchaseOrderHeaderBlocks.HISTORY)}
                            >
                                {t('view.PurchaseOrders.Tabs.History')}
                            </Button>
                        </div>
                        {this.state.activeBlock && (
                            <div className="purchase-order-header__info-blocks-collapse">
                                <Button
                                    iconPlacement={ButtonIconPlacement.LEFT}
                                    onClick={() => this.setState({ open: !this.state.open })}
                                    dataId={createDataId(dataId, 'collapse')}
                                    icon={ICONS.CHEVRON_DOWN_24}
                                    className={classNames('icon-button', { 'icon-button--collapsed': this.state.open })}
                                    buttonType={ButtonType.ICON_TEXT}
                                >
                                    {t('view.PurchaseOrders.Tabs.Collapse')}
                                </Button>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className={'purchase-order-header__info-blocks--preloader'}>
                        <HeaderPlaceholder class={'header-tabs'} />
                    </div>
                )}
                <Collapsible
                    easing="ease-in-out"
                    open={this.state.open}
                    triggerDisabled={true}
                    triggerClassName={'toggle-content__trigger'}
                    triggerOpenedClassName={'toggle-content__trigger toggle-content__trigger--open'}
                    transitionTime={300}
                    trigger={null}
                >
                    {this.state.activeBlock === PurchaseOrderHeaderBlocks.INVOICES && <InvoicesListWrapper purchaseOrder={this.props.retrievePOLoadable.payload} />}
                    {this.state.activeBlock === PurchaseOrderHeaderBlocks.HISTORY && <HistoryListWrapper purchaseOrder={this.props.retrievePOLoadable.payload} />}
                </Collapsible>
            </ContentBlock>
        );
    }
}

export default Header;
