import { FieldArray, FieldArrayRenderProps, FormikProps } from 'formik';
import { groupBy, isEmpty } from 'lodash-es';
import React, { Component, ReactNode } from 'react';

import { createDataId } from '../../../../common/utils/dataId';
import { AutoTransactionsAddViewFields, TriggersViewFields } from '../../autoTransactionAddViewFields';

import { getAddableTriggers, getAllTriggers, TriggersSwitches, TriggerTypeSelect } from './helpers/triggersHelpers';
import Trigger from './Trigger';
import './Triggers.scss';

export interface TriggersProps {
    children?: ReactNode;
    triggersView: TriggersViewFields;
    t: any;
    formik: FormikProps<AutoTransactionsAddViewFields>;
    fieldNamePrefix: string;
}

export class Triggers extends Component<TriggersProps> {
    render() {
        const { triggersView, fieldNamePrefix, formik, t } = this.props;

        const addableTriggers = triggersView && getAddableTriggers(triggersView.triggers, getAllTriggers(t));
        const addableTriggerGroups = groupBy(addableTriggers, 'groupName');

        return (
            <FieldArray
                name={`${fieldNamePrefix}`}
                render={() => (
                    <div className={'triggers'} data-id={createDataId('triggerTab', 'container')}>
                        <TriggersSwitches fieldNamePrefix={fieldNamePrefix} t={t} formik={formik} />
                        <FieldArray
                            name={`${fieldNamePrefix}.triggers`}
                            render={(triggersProps: FieldArrayRenderProps) => {
                                return (
                                    <>
                                        {triggersView.triggers.map((trigger, index) => {
                                            return (
                                                <Trigger
                                                    t={t}
                                                    key={`trigger.${index}`}
                                                    fieldNamePrefix={`${fieldNamePrefix}.triggers[${index}]`}
                                                    index={index}
                                                    trigger={trigger}
                                                    triggers={addableTriggers}
                                                    remove={() => {
                                                        triggersProps.remove(index);
                                                    }}
                                                />
                                            );
                                        })}
                                        {!isEmpty(addableTriggers) && (
                                            <div className={'trigger-add'}>
                                                <div className="trigger-items">
                                                    <TriggerTypeSelect addableTriggers={addableTriggerGroups} cbFunc={triggersProps.push} t={t} buttonContent={null} triggerTypeName={null} />
                                                </div>
                                            </div>
                                        )}
                                    </>
                                );
                            }}
                        />
                    </div>
                )}
            />
        );
    }
}

export default Triggers;
