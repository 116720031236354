import { FieldArray, FieldArrayRenderProps, FormikProps } from 'formik';
import { cloneDeep, differenceBy, find, isEmpty, isString, size, sortBy, uniqBy } from 'lodash-es';
import * as React from 'react';
import { ConnectDragPreview, ConnectDragSource, ConnectDropTarget, DragSource, DragSourceConnector, DragSourceMonitor, DropTarget, DropTargetConnector, DropTargetMonitor, XYCoord } from 'react-dnd';
import { findDOMNode } from 'react-dom';
import { TFunction } from 'i18next';

import { createDataId } from '../../../../common/utils/dataId';
import { BaseStatefulComponent } from '../../../../components/BaseStatefulComponent';
import { Button, ButtonType } from '../../../../components/Buttons/Button';
import { DropdownMenu, DropdownMenuItem } from '../../../../components/DropdownMenu/DropdownMenu';
import Icon, { ICONS, IconSize } from '../../../../components/Icon/Icon';
import TabList, { TabListBorderPlacement, TabListJustify } from '../../../../components/TabList/TabList';
import TabListItem from '../../../../components/TabList/TabListItem';
import { ToggleContent } from '../../../../components/ToggleContent/ToggleContent';
import { AutoTransactionMeta, CombinationOptionType, ExtensionField, ExtensionType, CustomCostObjectiveFullDTO, MetaField, VatCodeDTO } from '../../../../services/types/ApiTypes';
import api from '../../../../services/ApiServices';
import { AutomationStepFields, AutoTransactionsAddViewFields, ConditionFields, TriggerFields, VatRateFields } from '../../autoTransactionAddViewFields';
import { AutomationStepCombineFieldItem, createAllocationFields, createAutomationStepFields, createVatRateFields, getAutomationStepCombineFieldLabel } from '../../AutoTransactionsAddViewHelper';
import { Allocations } from './Allocations';
import CombineFieldSelection from './components/CombineFieldSelection';
import Conditions, { stepHasCombineField } from './components/Conditions';
import CombinationOptionSelection from './components/CombinationOptionSelection';

import './VatRateList.scss';

export interface AutomationStepProps {
    userVatCodes: VatCodeDTO[];
    formik: FormikProps<AutoTransactionsAddViewFields>;
    fieldNamePrefix: string;
    customCostObjectives: CustomCostObjectiveFullDTO[];
    allCombineFields: AutomationStepCombineFieldItem[];
    automationStepIndex: number;
    automationStep: AutomationStepFields;
    t: TFunction;
    handleSplitAllocation: (rowIndex: number, vatRateIndex: number, allocationIndex: number, formik: FormikProps<AutoTransactionsAddViewFields>, count: number) => void;
    handleDeleteAutomationStep(index: number): void;
    defaultOpen?: boolean;
    id: any;
    index: number;
    moveStep: (dragIndex: number, hoverIndex: number) => void;
    handleIsDragging: (isDragging: boolean) => void;
    cloneStep: (automationStep: AutomationStepFields) => void;
    isDragging?: boolean;
    connectDragSource?: ConnectDragSource;
    connectDropTarget?: ConnectDropTarget;
    connectDragPreview?: ConnectDragPreview;
    companyVatRates: number[];
}

export interface AutomationStepState {
    activeVat: number | null;
    isChangeVatRateMenuVisible: boolean;
    metaInfos: AutoTransactionMeta[];
}

const metaFields = {
    [MetaField.None]: '',
    [MetaField.Id]: 'view.AutoTransactions.Details.AutomationStep.MetaFields.Id',
    [MetaField.Name]: 'view.AutoTransactions.Details.AutomationStep.MetaFields.Name',
};

export function getMetaFieldLabel(metaField?: MetaField): string {
    return metaFields[metaField];
}

export function getSortedVatRates(automationStep: AutomationStepFields) {
    return automationStep.vatRates ? automationStep.vatRates.sort((a, b) => b.vatRate - a.vatRate) : [];
}

export const getCombineFieldSelectionTriggerLabel = (condition: ConditionFields, t: TFunction, label?: string) => {
    /**
     * The value of combineField is stored in the form of "ItemReserve:::{MetaField.Field}:::{MetaField.Value}" or "MetaField.Value" if "MetaField.Field === 0"
     * We are splitting here by delimiter ':::' (same as on the BE side) to get the MetaField.Value for displaying which meta info value is selected
     */

    function isItemReserve(value: string) {
        return value.indexOf(':::') !== -1;
    }

    function parseItemReserve(value: string) {
        return `${t('component.AutoTransaction.Parameter.ItemReserve')}: ${value.split(':::')[2]}`;
    }

    /**
     * if the label is provided, use it
     */
    if (label) {
        if (isItemReserve(label)) {
            return parseItemReserve(label);
        }
        return label;
    }
    /**
     * if no label, but condition has ":::" in it then parse the correct label value from it
     */
    if (condition && condition.combineField && isItemReserve(condition.combineField)) {
        return parseItemReserve(condition.combineField);
    }
    /**
     * if conditions combineField does not have ":::" in it then use the value itself as the label
     */
    if (!isEmpty(condition.combineField)) {
        return t(getAutomationStepCombineFieldLabel(condition.combineField));
    }
    /**
     * return the default label
     */
    return t('component.AutoTransaction.PlaceHolder.ClickToSet');
};

export class AutomationStepInternal extends BaseStatefulComponent<AutomationStepProps, AutomationStepState> {
    constructor(props: AutomationStepProps) {
        super(props);

        const sortedVatRates = getSortedVatRates(this.props.automationStep);
        this.state = {
            activeVat: sortedVatRates.length > 0 ? sortedVatRates[0].vatRate : null,
            isChangeVatRateMenuVisible: false,
            metaInfos: [],
        };
    }

    showChangeVatRateMenu = (visible = false) => {
        this.setState({ isChangeVatRateMenuVisible: visible });
    };

    setActiveVat = (vatRate: VatRateFields) => {
        this.setState({
            activeVat: vatRate.vatRate,
        });
    };

    getActiveVat = () => {
        return this.state.activeVat;
    };

    setCombinationType = (automationStep: AutomationStepFields, newType: CombinationOptionType) => {
        const { formik } = this.props;
        const newStep = createAutomationStepFields(newType);
        newStep.orderNo = cloneDeep(automationStep.orderNo);
        if (
            [CombinationOptionType.CombineSearch, CombinationOptionType.ApplyToAllWhere].includes(automationStep.combinationOption) &&
            [CombinationOptionType.CombineSearch, CombinationOptionType.ApplyToAllWhere].includes(newType)
        ) {
            newStep.conditions = cloneDeep(automationStep.conditions);
        }
        if (newType !== CombinationOptionType.CombineBy) {
            newStep.vatRates = cloneDeep(automationStep.vatRates);
        }
        formik.setFieldValue(`${this.props.fieldNamePrefix}`, newStep);
        if (newType === CombinationOptionType.CombineBy) {
            this.getMetaInfos();
        }
        if (stepHasCombineField(automationStep)) {
            formik.setFieldValue(`${this.props.fieldNamePrefix}.combineField`, 'Description');
        }
    };

    getMetaInfos = () => {
        const { formik } = this.props;
        const companyTrigger = find(
            formik.values.triggersView.triggers,
            (trigger: TriggerFields) => trigger.extensionType === ExtensionType.SellerParty && trigger.extensionField === ExtensionField.Company,
        );
        api.autoTransaction.getLastMetaInfosBySupplier(companyTrigger && (isString(companyTrigger.value) ? Number(companyTrigger.value) : null), '').then((response) => {
            this.setState((prevState) => ({
                ...prevState,
                metaInfos: response.data,
            }));
        });
    };

    componentDidMount(): void {
        if (this.props.automationStep.combinationOption === CombinationOptionType.CombineBy) {
            this.getMetaInfos();
        }
    }

    componentDidUpdate(prevProps: Readonly<AutomationStepProps>) {
        if (prevProps.isDragging !== this.props.isDragging) {
            this.props.handleIsDragging(this.props.isDragging);
        }
    }

    getAddableVatRates = (vatCodes: VatCodeDTO[], automationStep: AutomationStepFields) => {
        return sortBy(
            uniqBy(
                differenceBy(
                    this.props.companyVatRates.map((vatRate: number): VatRateFields => createVatRateFields(vatRate)),
                    automationStep.vatRates,
                    'vatRate',
                ),
                (value) => value.vatRate,
            ),
            (val) => val.vatRate,
        );
    };

    addVatRate = (vatRate: VatRateFields, vatRates: VatRateFields[], callback: any) => {
        const newVatRate = {
            ...vatRate,
            allocations: [createAllocationFields(100)],
        };
        callback(newVatRate);
        this.setState({ activeVat: vatRate.vatRate });
    };

    handleChangeVatRate = (newVatRate: VatRateFields, oldVatRate: VatRateFields, index: number, callback: any) => {
        callback(index, { ...oldVatRate, vatRate: newVatRate.vatRate });
        this.showChangeVatRateMenu();
    };

    handleVatRateDelete = (vatRatesArrayProps: FieldArrayRenderProps, vatRates: VatRateFields[], index: number, activeVatIndex: number) => {
        let newActiveVatIndex = index;
        if (index === activeVatIndex) {
            if (activeVatIndex > 0) {
                newActiveVatIndex = activeVatIndex - 1;
            } else {
                newActiveVatIndex = -1;
            }
        }
        this.setState(
            {
                activeVat: newActiveVatIndex === -1 ? undefined : vatRates[newActiveVatIndex].vatRate,
            },
            () => {
                vatRatesArrayProps.remove(index);
            },
        );
    };

    automationStepHasInputs = (automationStep: AutomationStepFields) => {
        const automationStepsWithInputs = [CombinationOptionType.ApplyToAllWhere, CombinationOptionType.CombineSearch, CombinationOptionType.CombineBy];
        return !!automationStep && automationStepsWithInputs.includes(automationStep.combinationOption) && automationStep.conditions.length > 0;
    };

    /**
     * Only show the second condition adding option, if the step is of ApplyToAllWhere or CombineSearch type and already has one condition with all values filled
     */
    automationStepCanHaveSecondCondition = (automationStep: AutomationStepFields) => {
        return (
            (automationStep.combinationOption === CombinationOptionType.ApplyToAllWhere || automationStep.combinationOption === CombinationOptionType.CombineSearch) &&
            size(automationStep.conditions) === 1 &&
            !isEmpty(automationStep.conditions[0].rowSearchString)
        );
    };

    render() {
        const {
            t,
            allCombineFields,
            automationStep,
            userVatCodes,
            fieldNamePrefix,
            customCostObjectives,
            formik,
            automationStepIndex,
            handleSplitAllocation,
            handleDeleteAutomationStep,
            isDragging,
            connectDragSource,
            connectDropTarget,
            connectDragPreview,
        } = this.props;
        const addableVatRates = this.getAddableVatRates(userVatCodes, automationStep);
        const sortedVatRates = getSortedVatRates(automationStep);
        const activeVatIndex = sortedVatRates.findIndex((vatRate) => vatRate.vatRate === this.state.activeVat);
        const opacity = isDragging ? 0 : 1;
        const emptySecondCondition: ConditionFields = {
            combineMatchType: undefined,
            orderNo: 2,
            combineField: undefined,
            rowSearchString: '',
            combineBySelection: {
                text: '',
                value: {
                    field: undefined,
                    value: '',
                },
            },
        };
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return connectDropTarget!(
            <div style={{ opacity }}>
                <ToggleContent
                    className={'automation-step'}
                    disableTrigger={automationStep.combinationOption === CombinationOptionType.CombineBy}
                    defaultOpen={this.props.defaultOpen}
                    connectDragSource={connectDragSource}
                    connectDragPreview={connectDragPreview}
                    toggleContent={
                        <FieldArray
                            name={`${fieldNamePrefix}`}
                            render={() => (
                                <>
                                    <div key={`${fieldNamePrefix}.items`} className="automation-step-items">
                                        {/* automation step combination option */}
                                        <CombinationOptionSelection
                                            combinationType={automationStep.combinationOption}
                                            t={t}
                                            callback={(type: CombinationOptionType) => {
                                                this.setCombinationType(automationStep, type);
                                            }}
                                            fieldNamePrefix={fieldNamePrefix}
                                            icon={false}
                                        />
                                        {/* END automation step combination option*/}
                                        <FieldArray
                                            name={`${fieldNamePrefix}.conditions`}
                                            render={(conditionsArrayProps: FieldArrayRenderProps) => (
                                                <>
                                                    {/* AutomationStep Conditions */}
                                                    {this.automationStepHasInputs(automationStep) && (
                                                        <Conditions
                                                            allCombineFields={allCombineFields}
                                                            automationStep={automationStep}
                                                            fieldNamePrefix={fieldNamePrefix}
                                                            t={t}
                                                            formik={formik}
                                                            key={`${fieldNamePrefix}.conditions`}
                                                        />
                                                    )}
                                                    {/* AutomationStep addSecondCondition */}
                                                    {this.automationStepCanHaveSecondCondition(automationStep) && (
                                                        <CombineFieldSelection
                                                            key={`${fieldNamePrefix}.${automationStep.id}.addSecondCombineField`}
                                                            fieldNamePrefix={fieldNamePrefix}
                                                            formik={formik}
                                                            t={t}
                                                            allCombineFields={allCombineFields}
                                                            condition={emptySecondCondition}
                                                            callback={(item: AutomationStepCombineFieldItem) => {
                                                                conditionsArrayProps.push({ ...emptySecondCondition, combineField: item.value });
                                                            }}
                                                            label={t('view.AutoTransactions.Details.AutomationStep.Conditions.AddSecond')}
                                                        />
                                                    )}
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div className="action-wrap" key={`${fieldNamePrefix}.actions`}>
                                        <Button
                                            dataId={createDataId(`${fieldNamePrefix}`, '.clone')}
                                            buttonType={ButtonType.ICON_SQUARE}
                                            icon={ICONS.COPY_24}
                                            iconSize={IconSize.SM}
                                            onClick={() => this.props.cloneStep(automationStep)}
                                        />
                                        <Button
                                            dataId={createDataId(`${fieldNamePrefix}`, '.delete')}
                                            buttonType={ButtonType.ICON_SQUARE}
                                            icon={ICONS.DELETE_24}
                                            iconSize={IconSize.SM}
                                            onClick={() => handleDeleteAutomationStep(automationStepIndex)}
                                        />
                                    </div>
                                </>
                            )}
                        />
                    }
                >
                    {![CombinationOptionType.CombineBy].includes(automationStep.combinationOption) && (
                        <FieldArray
                            name={`${fieldNamePrefix}.vatRates`}
                            render={(vatRatesArrayProps: FieldArrayRenderProps) => (
                                <div className={'vat-rates-group'}>
                                    <TabList justifyTabs={TabListJustify.LEFT} borderPlacement={TabListBorderPlacement.TOP} className={'vat-rates-tabs'}>
                                        {!isEmpty(automationStep.vatRates) &&
                                            sortedVatRates.map((vatRate, index) => (
                                                <TabListItem
                                                    dataId={createDataId('vatRates', index, 'tabItem')}
                                                    className={'vat-rates-tabs__item'}
                                                    isActive={vatRate.vatRate === this.getActiveVat()}
                                                    key={vatRate.vatRate}
                                                    onClick={() => this.setActiveVat(vatRate)}
                                                >
                                                    <span className={'vat-rate-label'}>
                                                        <span className={'vat-rate-label__vat'}>{`${vatRate.vatRate}%${'\n'}`}</span>
                                                        <span className={'vat-rate-label__text'}>{t('component.AutoTransaction.VatRates.TabLabel')}</span>
                                                    </span>
                                                    <DropdownMenu
                                                        dataId={createDataId(`${fieldNamePrefix}`, '.vatRateOptionsDropdown')}
                                                        hideOnClick={false}
                                                        onHideCallback={() => this.showChangeVatRateMenu()}
                                                        preventButtonClickEventBubble={true}
                                                        items={
                                                            <>
                                                                <DropdownMenuItem isDisabled={addableVatRates.length === 0} dataId={createDataId('option', 'changeTo')}>
                                                                    {t('component.AutoTransaction.Menu.ChangeTo')}
                                                                </DropdownMenuItem>
                                                                {addableVatRates.length > 0 &&
                                                                    addableVatRates.map((changeVatRateItem, changeVatRateIndex) => (
                                                                        <DropdownMenuItem
                                                                            className={'menu-sub-item'}
                                                                            key={changeVatRateIndex}
                                                                            onClick={() => this.handleChangeVatRate(changeVatRateItem, vatRate, index, vatRatesArrayProps.replace)}
                                                                            dataId={createDataId('option', changeVatRateIndex)}
                                                                        >
                                                                            <span>
                                                                                <span className={'vat-rate-label__vat'}>{`${changeVatRateItem.vatRate}%${'\n'}`}</span>
                                                                                <span className={'vat-rate-label__text'}>{t('component.AutoTransaction.VatRates.TabLabel')}</span>
                                                                            </span>
                                                                        </DropdownMenuItem>
                                                                    ))}
                                                                <DropdownMenuItem
                                                                    onClick={() => {
                                                                        this.handleVatRateDelete(vatRatesArrayProps, sortedVatRates, index, activeVatIndex);
                                                                    }}
                                                                    dataId={createDataId('option', 'delete')}
                                                                >
                                                                    {t('component.AutoTransaction.Menu.Delete')}
                                                                </DropdownMenuItem>
                                                            </>
                                                        }
                                                    >
                                                        <Button
                                                            dataId={createDataId(`${fieldNamePrefix}.vatRateOptionsDropdown`, '.trigger')}
                                                            buttonType={ButtonType.ICON_SQUARE}
                                                            icon={ICONS.DOT_MENU}
                                                            iconSize={IconSize.SM}
                                                            iconRotation={90}
                                                        />
                                                    </DropdownMenu>
                                                </TabListItem>
                                            ))}
                                        {!isEmpty(addableVatRates) && (
                                            <TabListItem className={'vat-rates-tabs__item vat-rates-tabs__add'} isActive={false} dataId={createDataId('vatRates', 'tabItem', 'add')}>
                                                <DropdownMenu
                                                    dataId={createDataId(`${fieldNamePrefix}`, '.addVatRateDropdown')}
                                                    items={addableVatRates.map((vatRate, index) => (
                                                        <DropdownMenuItem
                                                            key={index}
                                                            onClick={() => {
                                                                this.addVatRate(vatRate, sortedVatRates, vatRatesArrayProps.push);
                                                            }}
                                                            dataId={createDataId('option', index)}
                                                        >
                                                            {vatRate.vatRate}
                                                        </DropdownMenuItem>
                                                    ))}
                                                >
                                                    <Button dataId={createDataId(`${fieldNamePrefix}.addVatRateDropdown`, '.trigger')} buttonType={ButtonType.TEXT}>
                                                        <Icon name={ICONS.PLUS} size={IconSize.SM} />{' '}
                                                        {sortedVatRates.length < 1 && <span>&nbsp; {t('component.AutoTransaction.Menu.ClickToAdd.VatRate')}</span>}
                                                    </Button>
                                                </DropdownMenu>
                                            </TabListItem>
                                        )}
                                    </TabList>
                                </div>
                            )}
                        />
                    )}
                    {!isEmpty(automationStep.vatRates) && (
                        <FieldArray
                            name={`${fieldNamePrefix}.vatRates`}
                            render={() => (
                                <>
                                    {activeVatIndex !== -1 && !isEmpty(automationStep.vatRates) && (
                                        <Allocations
                                            fieldNamePrefix={`${fieldNamePrefix}.vatRates[${activeVatIndex}].allocations`}
                                            unPostedAmountFieldKey={`${fieldNamePrefix}.vatRates[${activeVatIndex}].unPostedAmount`}
                                            formik={formik}
                                            t={t}
                                            handleSplitAllocation={(allocationIndex, count: number) => {
                                                handleSplitAllocation(automationStepIndex, activeVatIndex, allocationIndex, formik, count);
                                            }}
                                            customCostObjectives={customCostObjectives}
                                            allocations={automationStep.vatRates[activeVatIndex].allocations}
                                        />
                                    )}
                                </>
                            )}
                        />
                    )}
                </ToggleContent>
            </div>,
        );
    }
}

const dragSource = {
    beginDrag(props: AutomationStepProps) {
        return {
            id: props.id,
            index: props.index,
        };
    },
};

const dragTarget = {
    hover(props: AutomationStepProps, monitor: DropTargetMonitor, component: AutomationStepInternal | null): void {
        if (!component) {
            return null;
        }
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
            return;
        }

        // Determine rectangle on screen
        // eslint-disable-next-line react/no-find-dom-node
        const hoverBoundingRect = (findDOMNode(component) as Element).getBoundingClientRect();

        // Get vertical middle
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        // Determine mouse position
        const clientOffset = monitor.getClientOffset();

        // Get pixels to the top
        const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%

        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
            return;
        }

        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            return;
        }

        // Time to actually perform the action
        props.moveStep(dragIndex, hoverIndex);

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        monitor.getItem().index = hoverIndex;
    },
};

export default DropTarget('AT_STEP', dragTarget, (connect: DropTargetConnector) => ({
    connectDropTarget: connect.dropTarget(),
}))(
    DragSource('AT_STEP', dragSource, (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
        connectDragSource: connect.dragSource(),
        connectDragPreview: connect.dragPreview(),
        isDragging: monitor.isDragging(),
    }))(AutomationStepInternal),
);
