import classNames from 'classnames';
import { FormikProps } from 'formik';
import { get } from 'lodash-es';
import * as React from 'react';

import { addDataId, createDataId } from '../../../../common/utils/dataId';
import FormikField from '../../../../common/utils/FormikField';
import { DECIMAL_AUTO_TRANSACTIONS_ROWS_VAT_REGEXP } from '../../../../common/utils/validators';
import { BaseStatefulComponent } from '../../../../components/BaseStatefulComponent';
import { Button, ButtonType } from '../../../../components/Buttons/Button';
import { DropdownMenu, DropdownMenuItem } from '../../../../components/DropdownMenu/DropdownMenu';
import { IconSize, ICONS } from '../../../../components/Icon/Icon';
import { TextInputField } from '../../../../components/TextInput/TextInput';
import { ToggleContent, ToggleContentType } from '../../../../components/ToggleContent/ToggleContent';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import { CustomCostObjectiveFullDTO } from '../../../../services/types/ApiTypes';
import { AllocationFields, AutoTransactionsAddViewFields } from '../../autoTransactionAddViewFields';
import { getAllocationTypes } from '../../AutoTransactionsAddViewHelper';

import './Allocation.scss';
import { Dimensions } from './Dimensions';

export interface AllocationProps {
    children?: React.ReactNode;
    className?: string;
    t: any;
    fieldNamePrefix: string;
    formik: FormikProps<AutoTransactionsAddViewFields>;
    customCostObjectives: CustomCostObjectiveFullDTO[];
    allocation: AllocationFields;
    allocationIndex: number;
    handleDeleteAllocation: (allocationIndex: number) => void;
    handleSplitAllocation: (allocationIndex: number, count?: number) => void;
}

export interface AllocationState {
    allocationSplitCount: number;
    allocationTooltip: any;
}

export class Allocation extends BaseStatefulComponent<AllocationProps, AllocationState> {
    constructor(props: AllocationProps) {
        super(props);
        this.state = {
            allocationSplitCount: 2,
            allocationTooltip: null,
        };
    }

    render() {
        const { className, fieldNamePrefix, handleSplitAllocation, handleDeleteAllocation, customCostObjectives, allocation, t, allocationIndex, formik } = this.props;
        const classes = classNames('allocation', className);
        return (
            <ToggleContent
                className={classes}
                type={ToggleContentType.COMPACT}
                toggleContent={
                    <div className={'allocation-header'}>
                        <div className={'allocation-header__texts'}>
                            <FormikField
                                className={'allocation__description'}
                                component={TextInputField}
                                wrapperClass={'allocation-description-wrapper'}
                                key={`${fieldNamePrefix}.description`}
                                onlyChangeOnBlur={true}
                                dataId={addDataId(`${fieldNamePrefix}`, '.description')}
                                name={`${fieldNamePrefix}.description`}
                                placeholder={t('component.AutoTransaction.VatRates.Allocations.Description.Placeholder')}
                            />
                            <FormikField
                                className={'allocation__comment'}
                                component={TextInputField}
                                wrapperClass={'allocation-description-wrapper'}
                                key={`${fieldNamePrefix}.comment`}
                                onlyChangeOnBlur={true}
                                dataId={addDataId(`${fieldNamePrefix}`, '.comment')}
                                name={`${fieldNamePrefix}.comment`}
                                placeholder={t('component.AutoTransaction.VatRates.Allocations.Comment.Placeholder')}
                            />
                        </div>
                        <FormikField
                            className={'allocation__amount'}
                            wrapperClass={'allocation-amount-wrapper'}
                            component={TextInputField}
                            onlyChangeOnBlur={true}
                            dataId={addDataId(`${fieldNamePrefix}`, '.amount')}
                            blurOnEnter={true}
                            validCharacters={DECIMAL_AUTO_TRANSACTIONS_ROWS_VAT_REGEXP}
                            key={`${fieldNamePrefix}.amount`}
                            name={`${fieldNamePrefix}.amount`}
                            replaceComma={true}
                        />
                        {Object.keys(getAllocationTypes()).length > 1 ? (
                            <DropdownMenu
                                dataId={addDataId(`${fieldNamePrefix}.typeDropdown`, '')}
                                items={Object.keys(getAllocationTypes()).map((key, index) => (
                                    <DropdownMenuItem dataId={createDataId('option', index)} key={index} onClick={() => formik.setFieldValue(`${fieldNamePrefix}.type`, key)}>
                                        {getAllocationTypes()[key]}
                                    </DropdownMenuItem>
                                ))}
                            >
                                <Button dataId={addDataId(`${fieldNamePrefix}.typeDropdown`, '.trigger')} className={'allocation__type'} buttonType={ButtonType.TEXT}>
                                    {getAllocationTypes()[get(formik.values, `${fieldNamePrefix}.type`)]}
                                </Button>
                            </DropdownMenu>
                        ) : (
                            <span className={'allocation__type'}>{getAllocationTypes()[get(formik.values, `${fieldNamePrefix}.type`)]}</span>
                        )}
                        <div className={'action-wrap'}>
                            <Tooltip content={t('component.AutoTransaction.VatRates.Allocations.Split')}>
                                <Button
                                    dataId={addDataId(`${fieldNamePrefix}.split`, '')}
                                    buttonType={ButtonType.ICON_SQUARE}
                                    icon={ICONS.SPLIT}
                                    iconSize={IconSize.SM}
                                    onClick={() => {
                                        handleSplitAllocation(allocationIndex);
                                    }}
                                />
                            </Tooltip>
                            <Tooltip content={t('component.AutoTransaction.Menu.Delete')}>
                                <Button
                                    dataId={addDataId(`${fieldNamePrefix}.delete`, '')}
                                    buttonType={ButtonType.ICON_SQUARE}
                                    icon={ICONS.DELETE}
                                    iconSize={IconSize.SM}
                                    onClick={() => handleDeleteAllocation(allocationIndex)}
                                />
                            </Tooltip>
                        </div>
                    </div>
                }
            >
                <Dimensions
                    formik={formik}
                    fieldNamePrefix={`${fieldNamePrefix}.dimensions`}
                    className={'allocation__dimensions'}
                    dimensions={allocation.dimensions}
                    customCostObjectives={customCostObjectives}
                    t={t}
                    dataId={createDataId('allocation', 'dimensions')}
                />
            </ToggleContent>
        );
    }
}
