import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import ProgressBar, { ProgressBarProps } from '../../../../components/ProgressBar/ProgressBar';
import Icon, { ICONS } from '../../../../components/Icon/Icon';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import { PurchaseOrderExtraStatus, PurchaseOrderStatus } from '../../../../services/types/ApiTypes';
import { createDataId } from '../../../../common/utils/dataId';
import { isSystemSetting } from '../../../../common/user/userPermissionUtil';
import { getProgressbarProps } from './PoFulfilmentHelpers';
import { PoFulfilmentProps } from './PoFulfilmentTypes';

import './PoFulfilmentView.scss';

const baseDataId = createDataId('po-fulfilment');

export const poFulfilmentDataIds = {
    dataError: createDataId(baseDataId, 'data-error'),
    progressbar: createDataId(baseDataId, 'progressbar'),
    fulfilmentPlaceholder: createDataId(baseDataId, 'fulfilment-placeholder'),
};

const PoFulfilmentView: React.FC<PoFulfilmentProps> = (props) => {
    const { LinkedInvoicesSum, Total, LinkedInvoicesCount, OrderStatus, OrderExtraStatus, IsManualFulfilment } = props.purchaseOrder;
    const { t } = useTranslation();

    const progressBarProps: ProgressBarProps = useMemo(() => {
        const invoicesSum = LinkedInvoicesSum || 0;
        const grandTotal = Total || 0;
        return getProgressbarProps(invoicesSum, grandTotal);
    }, [LinkedInvoicesSum, Total]);

    const isOverfilled = progressBarProps.value > 100;
    const isDataError = LinkedInvoicesCount > 0 && LinkedInvoicesSum === null;
    const isShowPlaceholder = OrderStatus !== PurchaseOrderStatus.Confirmed;
    const isShowTextStatus = IsManualFulfilment || !isSystemSetting('isInvoiceRegisterModulActive');

    if (isShowPlaceholder) {
        return <span data-id={poFulfilmentDataIds.fulfilmentPlaceholder}>-</span>;
    }

    if (isDataError) {
        return (
            <Tooltip content={t('view.PurchaseOrders.Tooltip.FulfilmentDataError')}>
                <div className={'po-fulfilment__data-error'} data-id={poFulfilmentDataIds.dataError}>
                    <Icon iconName={ICONS.NOTIFICATION_WARNING_FILLED} />
                    <span>{t('view.PurchaseOrders.FulfilmentDataErrorMessage')}</span>
                </div>
            </Tooltip>
        );
    }

    if (isShowTextStatus) {
        return t(`view.PurchaseOrders.ExtraStatus.${PurchaseOrderExtraStatus[OrderExtraStatus]}`);
    }

    return (
        <Tooltip content={isOverfilled && t('view.PurchaseOrders.Tooltip.FulfilmentOverfulfiled')}>
            <ProgressBar {...progressBarProps} dataId={poFulfilmentDataIds.progressbar} />
        </Tooltip>
    );
};
export default PoFulfilmentView;
