import * as React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Dispatchable0 } from 'redux-dispatchers';

import { BaseComponent } from '../../components/BaseComponent';
import KeyboardFocuser from '../../components/KeyboardFocuser/KeyboardFocuser';
import WhitelabelHelmet from '../../components/WhitelabelHelmet/WhitelabelHelmet';
import AutoTransactionsAddViewWrapper from '../../views/auto-transactions-add/AutoTransactionsAddViewWrapper';
import AutoTransactionsViewWrapper from '../../views/auto-transactions/AutoTransactionsViewWrapper';
import PurchaseOrdersAddViewWrapper from '../../views/purchase-orders-add/PurchaseOrdersAddViewWrapper';
import PurchaseOrdersNewWrapper from '../../views/purchase-orders-new/PurchaseOrdersNewWrapper';
import PurchaseOrdersViewWrapper from '../../views/purchase-orders/PurchaseOrdersViewWrapper';
import UserAddViewWrapper from '../../views/user-add/UserAddViewWrapper';
import UsersListViewWrapper from '../../views/user-list/UserListViewWrapper';

import checkAccessToBO from '../../common/utils/backOfficeAccess';
import { BackOfficeUserDTO, BackOfficeUserRole } from '../../services/types/BoApiTypes';
import { LoadableData } from '../../common/utils/LoadableData';

import { paths } from './routePaths';
import RouterLoadable from './RouterLoadable';
import PrivateRoute from './PrivateRoute';

// dynamic component loading (for code splitting)
const AsyncDashboardViewWrapper = RouterLoadable({
    loader: () => import('../../views/dashboard/DashboardViewWrapper'),
});
const AsyncAccountingViewWrapper = RouterLoadable({
    loader: () => import('../../views/settings/accounting/AccountingViewWrapper'),
});
const CostObjectiveDetailsViewWrapper = RouterLoadable({
    loader: () => import('../../views/settings/cost-objective-details/CostObjectiveDetailsViewWrapper'),
});
const CompanySettingsViewWrapper = RouterLoadable({
    loader: () => import('../../views/settings/company-settings/CompanySettingsViewWrapper'),
});
const VatCodeDetailsViewWrapper = RouterLoadable({
    loader: () => import('../../views/settings/vat-code-details/VatCodeDetailsViewWrapper'),
});
const AccountDetailsViewWrapper = RouterLoadable({
    loader: () => import('../../views/settings/account-details/AccountDetailsViewWrapper'),
});
const ProductItemsViewWrapper = RouterLoadable({
    loader: () => import('../../views/settings/product-items/ProductItemsViewWrapper'),
});
const RelatedDimensionsViewWrapper = RouterLoadable({
    loader: () => import('../../views/automation/related-dimensions/RelatedDimensionsViewWrapper'),
});
const PaymentMethodsViewWrapper = RouterLoadable({
    loader: () => import('../../views/settings/payment-methods/PaymentMethodsViewWrapper'),
});
const HeaderExtensionsViewWrapper = RouterLoadable({
    loader: () => import('../../views/settings/header-extensions/HeaderExtensionsViewWrapper'),
});
const InvoiceTypesListWrapper = RouterLoadable({
    loader: () => import('../../views/settings/invoice-types/InvoiceTypesListWrapper'),
});
const WorkflowsListViewWrapper = RouterLoadable({
    loader: () => import('../../views/settings/workflows-list/WorkflowsListViewWrapper'),
});

const WorkflowDetailsViewWrapper = RouterLoadable({
    loader: () => import('../../views/settings/workflow-details/WorkflowDetailsViewWrapper'),
});

const SuppliersViewWrapper = RouterLoadable({
    loader: () => import('../../views/settings/suppliers/SuppliersViewWrapper'),
});

const BoUserListViewWrapper = RouterLoadable({
    loader: () => import('../../views/back-office/user-list/BOUserListViewWrapper'),
});

const BoReportingListViewWrapper = RouterLoadable({
    loader: () => import('../../views/back-office/reporting/BOReportingListViewWrapper'),
});

const BoIntegratorListViewWrapper = RouterLoadable({
    loader: () => import('../../views/back-office/integrators/BOIntegratorListViewWrapper'),
});

const BoDomainListViewWrapper = RouterLoadable({
    loader: () => import('../../views/back-office/domains/BODomainListViewWrapper'),
});

const BoResellerListViewWrapper = RouterLoadable({
    loader: () => import('../../views/back-office/reseller-list/BOResellerListViewWrapper'),
});

const BoOrganizationListViewWrapper = RouterLoadable({
    loader: () => import('../../views/back-office/organization-list/BOOrganizationListViewWrapper'),
});

const BoCompanyListViewWrapper = RouterLoadable({
    loader: () => import('../../views/back-office/company-list/BOCompanyListViewWrapper'),
});

const BoCompanyDetailsViewWrapper = RouterLoadable({
    loader: () => import('../../views/back-office/company-details/BOCompanyDetailsViewWrapper'),
});

const MailboxDetailsWrapper = RouterLoadable({
    loader: () => import('../../views/back-office/company-details/components/mailboxes/components/MailboxDetails/MailboxDetails'),
});

export interface StateProps {
    boCurrentUserLoadable: LoadableData<BackOfficeUserDTO>;
    currentUserLoaded: boolean;
}

export interface DispatchProps {
    getBoCurrentUser: Dispatchable0;
    getCurrencies: Dispatchable0;
}

type IRoutesProps = StateProps & DispatchProps & RouteComponentProps<any>;

class Routes extends BaseComponent<IRoutesProps> {
    constructor(props: IRoutesProps) {
        super(props);
    }

    componentDidMount() {
        if (!this.props.boCurrentUserLoadable.loaded) {
            this.props.getBoCurrentUser();
        }
        this.props.getCurrencies();
    }

    componentDidUpdate(prevProps: IRoutesProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            window.scrollTo(0, 0);
        }
    }

    handleNoReactRoute = () => {
        // render empty dom node for animation purposes
        return <div />;
    };

    render(): JSX.Element {
        const { location } = this.props;
        if (!this.props.currentUserLoaded) {
            return null;
        }
        const isReseller = this.props.boCurrentUserLoadable?.payload?.Role === BackOfficeUserRole.RE_SELLER;
        const defaultBOPath = isReseller ? paths.backOffice.companies : paths.backOffice.boUsers;
        const boCommonGuards = [
            {
                failCondition: isReseller,
                requestDone: true,
                onFail: defaultBOPath,
            },
        ];
        return (
            <>
                <TransitionGroup>
                    <CSSTransition key={location.pathname} classNames="fade-routes" timeout={250}>
                        <div className={'page-container'}>
                            <Switch location={location}>
                                <PrivateRoute
                                    guards={[
                                        {
                                            failCondition: this.props.boCurrentUserLoadable?.payload ? !checkAccessToBO(this.props.boCurrentUserLoadable.payload) : true,
                                            requestDone: true,
                                            onFail: paths.app.dashboard,
                                        },
                                    ]}
                                    path={paths.backOffice.default}
                                    component={() => (
                                        <Switch location={location}>
                                            <Route exact={true} path={paths.backOffice.default}>
                                                <Redirect to={defaultBOPath} />
                                            </Route>
                                            <PrivateRoute guards={boCommonGuards} exact path={paths.backOffice.boUsers} component={BoUserListViewWrapper} />
                                            <PrivateRoute guards={boCommonGuards} exact path={paths.backOffice.integrators} component={BoIntegratorListViewWrapper} />
                                            <PrivateRoute guards={boCommonGuards} exact path={paths.backOffice.domains} component={BoDomainListViewWrapper} />
                                            <PrivateRoute guards={boCommonGuards} exact path={paths.backOffice.reports} component={BoReportingListViewWrapper} />
                                            <PrivateRoute guards={boCommonGuards} exact path={paths.backOffice.resellers} component={BoResellerListViewWrapper} />
                                            <Route exact path={paths.backOffice.company} component={BoCompanyDetailsViewWrapper} />
                                            <Route exact path={paths.backOffice.mailboxDetails} component={MailboxDetailsWrapper} />
                                            <Route exact path={paths.backOffice.organizations} component={BoOrganizationListViewWrapper} />
                                            <Route exact path={paths.backOffice.companies} component={BoCompanyListViewWrapper} />
                                            <Route exact path={paths.backOffice.users} component={BoUserListViewWrapper} />
                                            {/*Redirect to default BO route if path not found*/}
                                            <Route path="*">
                                                <Redirect to={defaultBOPath} />
                                            </Route>
                                        </Switch>
                                    )}
                                />
                                <Route
                                    path={paths.public}
                                    component={() => (
                                        <>
                                            <Switch location={location}>
                                                {/*<Route exact={true} path={paths.public} component={AsyncPublicViewWrapper} />*/}
                                                <Route exact={true} path={paths.app.subpage} component={AsyncDashboardViewWrapper} />
                                                <Route exact={true} path={paths.app.listPurchaseOrders} component={PurchaseOrdersViewWrapper} />
                                                <Route exact={true} path={paths.app.addPurchaseOrder} component={PurchaseOrdersNewWrapper} />
                                                <Route exact={true} path={paths.app.purchaseOrderDetails} component={PurchaseOrdersAddViewWrapper} />
                                                <Route exact={true} path={paths.app.addAutoTransaction} component={AutoTransactionsAddViewWrapper} />
                                                <Route exact={true} path={paths.app.addAutoTransactionSnapshot} component={AutoTransactionsAddViewWrapper} />
                                                <Route exact={true} path={paths.app.autoTransactionDetails} component={AutoTransactionsAddViewWrapper} />
                                                <Route exact={true} path={paths.app.autoTransactions} component={AutoTransactionsViewWrapper} />
                                                <Route exact={true} path={paths.app.userManagement} component={UsersListViewWrapper} />
                                                <Route exact={true} path={paths.app.userDetails} component={UserAddViewWrapper} />
                                                {/* <Route exact={true} path={paths.app.addUser} component={UserAddViewWrapper} />  // depricated - new user is handled in a modal */}
                                                <Route exact={true} path={paths.app.accounting} component={AsyncAccountingViewWrapper} />
                                                <Route exact={true} path={paths.app.costObjectiveDetails} component={CostObjectiveDetailsViewWrapper} />
                                                <Route exact={true} path={paths.app.companySettings} component={CompanySettingsViewWrapper} />
                                                <Route exact={true} path={paths.app.vatCodeDetails} component={VatCodeDetailsViewWrapper} />
                                                <Route exact={true} path={paths.app.accountDetails} component={AccountDetailsViewWrapper} />
                                                <Route exact={true} path={paths.app.productItems} component={ProductItemsViewWrapper} />
                                                <Route exact={true} path={paths.app.relatedDimensions} component={RelatedDimensionsViewWrapper} />
                                                <Route exact={true} path={paths.app.paymentMethods} component={PaymentMethodsViewWrapper} />
                                                <Route exact={true} path={paths.app.headerExtensions} component={HeaderExtensionsViewWrapper} />
                                                <Route exact={true} path={paths.app.documentTypes} component={InvoiceTypesListWrapper} />
                                                <Route exact={true} path={paths.app.workflows} component={WorkflowsListViewWrapper} />
                                                <Route exact={true} path={paths.app.workflowsAdd} component={WorkflowDetailsViewWrapper} />
                                                <Route exact={true} path={paths.app.workflowDetails} component={WorkflowDetailsViewWrapper} />
                                                <Route exact={true} path={paths.app.suppliers} component={SuppliersViewWrapper} />
                                                {/*<Route exact={true} path={paths.app.roles} component={RolesListWrapper} />*/}
                                            </Switch>
                                        </>
                                    )}
                                />
                                <Route exact={true} path="/auth" render={null} />
                                <Route path="*" render={this.handleNoReactRoute} />
                            </Switch>
                        </div>
                    </CSSTransition>
                </TransitionGroup>
                <WhitelabelHelmet />
                <KeyboardFocuser />
            </>
        );
    }
}

export default Routes;
