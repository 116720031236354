import classNames from 'classnames';
import { FieldArray, FieldArrayRenderProps, FormikProps } from 'formik';
import { get, isEmpty } from 'lodash-es';
import { reduce } from 'lodash-es';
import * as React from 'react';

import { BaseComponent } from '../../../../components/BaseComponent';
import { CustomCostObjectiveFullDTO } from '../../../../services/types/ApiTypes';
import { AllocationFields, AutoTransactionsAddViewFields } from '../../autoTransactionAddViewFields';
import { createMandatoryDimensionsFields } from '../../AutoTransactionsAddViewHelper';

import { Allocation } from './Allocation';
import UnpostedAmountWarning from './UnpostedAmountWarning';

export interface AllocationsProps {
    children?: React.ReactNode;
    className?: string;
    t: any;
    formik: FormikProps<AutoTransactionsAddViewFields>;
    customCostObjectives: CustomCostObjectiveFullDTO[];
    allocations: AllocationFields[];
    handleSplitAllocation: (allocationIndex: number, count: number) => void;
    fieldNamePrefix: string;
    unPostedAmountFieldKey: string;
}

export class Allocations extends BaseComponent<AllocationsProps> {
    componentDidMount(): void {
        this.updateUnpostedFormikValue();
    }

    componentDidUpdate(prevProps: Readonly<AllocationsProps>): void {
        const { formik } = this.props;

        if (prevProps.formik.values !== formik.values) {
            this.updateUnpostedFormikValue();
        }
    }

    updateUnpostedFormikValue = () => {
        const { formik, allocations, unPostedAmountFieldKey } = this.props;
        const unpostedAmount = 100 - Number(reduce(allocations, (previousValue: number, currentValue: AllocationFields) => previousValue + Number(currentValue.amount), 0).toFixed(2));

        if (get(formik.values, unPostedAmountFieldKey) !== unpostedAmount) {
            formik.setFieldValue(unPostedAmountFieldKey, unpostedAmount);
        }
    };

    static addAsNewAllocation(amount: number, callback: any) {
        const newAllocation: AllocationFields = {
            amount: Number(amount.toFixed(2)),
            type: 1,
            description: '',
            comment: '',
            dimensions: createMandatoryDimensionsFields(),
        };
        callback(newAllocation);
    }
    addToLargestAllocation(amount: number, allocations: AllocationFields[], replace: any) {
        let largestIndex = 0;
        let largestAmount = 0;
        allocations.forEach((alloc: AllocationFields, index: number) => {
            if (alloc.amount > largestAmount) {
                largestAmount = Number(alloc.amount?.toFixed(2));
                largestIndex = index;
            }
        });
        const largest = {
            ...allocations[largestIndex],
            amount: Number((Number(largestAmount) + amount).toFixed(2)),
        };
        replace(largestIndex, largest);
    }
    render() {
        const { children, className, t, customCostObjectives, allocations, formik, fieldNamePrefix, handleSplitAllocation, unPostedAmountFieldKey } = this.props;
        const classes = classNames('allocations', className);
        const unpostedAmount = get(formik.values, unPostedAmountFieldKey);
        const hasAllocations = !isEmpty(allocations);
        return (
            <div className={classes}>
                <FieldArray
                    name={`${fieldNamePrefix}`}
                    render={(allocationsArrayProps: FieldArrayRenderProps) => (
                        <>
                            {unpostedAmount ? (
                                <div className={'unposted-warning-container'}>
                                    <UnpostedAmountWarning
                                        amount={unpostedAmount}
                                        t={t}
                                        hasAllocations={hasAllocations}
                                        addAsNew={(amount: number) => Allocations.addAsNewAllocation(amount, allocationsArrayProps.push)}
                                        addToLargest={(amount: number) => this.addToLargestAllocation(amount, allocations, allocationsArrayProps.replace)}
                                        fieldNamePrefix={fieldNamePrefix}
                                    />
                                </div>
                            ) : null}
                            {hasAllocations
                                ? allocations.map((allocation: AllocationFields, allocationIndex: number) => (
                                      <Allocation
                                          allocationIndex={allocationIndex}
                                          t={t}
                                          formik={formik}
                                          fieldNamePrefix={`${fieldNamePrefix}[${allocationIndex}]`}
                                          key={`${fieldNamePrefix}[${allocationIndex}]`}
                                          allocation={allocation}
                                          customCostObjectives={customCostObjectives}
                                          handleDeleteAllocation={allocationsArrayProps.remove}
                                          handleSplitAllocation={handleSplitAllocation}
                                      />
                                  ))
                                : children}
                        </>
                    )}
                />
            </div>
        );
    }
}
