import classNames from 'classnames';
import React, { ReactNode, StatelessComponent } from 'react';

import './TabList.scss';

export interface TabListProps {
    children?: ReactNode;
    className?: string;
    justifyTabs?: TabListJustify;
    borderPlacement?: TabListBorderPlacement;
    type?: TabListType;
}

export enum TabListBorderPlacement {
    TOP = 'TOP',
    RIGHT = 'RIGHT',
    BOTTOM = 'BOTTOM',
    LEFT = 'LEFT',
}

export enum TabListJustify {
    LEFT = 0,
    CENTER = 1,
    RIGHT = 2,
    SPREAD = 3,
}

export enum TabListType {
    DEFAULT = 'DEFAULT',
    COMPACT = 'COMPACT',
}

const getTabsJustify = (justify: TabListJustify) => {
    const borderPlacement = {
        [TabListJustify.LEFT]: 'tab-list--justify-left',
        [TabListJustify.CENTER]: 'tab-list--justify-center',
        [TabListJustify.RIGHT]: 'tab-list--justify-right',
        [TabListJustify.SPREAD]: 'tab-list--justify-spread',
    };
    return borderPlacement[justify];
};

const getBorderPlacement = (placement: TabListBorderPlacement) => {
    const borderPlacement = {
        [TabListBorderPlacement.TOP]: 'tab-list--border-top',
        [TabListBorderPlacement.RIGHT]: 'tab-list--border-right',
        [TabListBorderPlacement.BOTTOM]: 'tab-list--border-bottom',
        [TabListBorderPlacement.LEFT]: 'tab-list--border-left',
    };
    return borderPlacement[placement];
};

const TabList: StatelessComponent<TabListProps> = (props) => {
    const { className, justifyTabs = TabListJustify.CENTER, borderPlacement = TabListBorderPlacement.BOTTOM } = props;
    const classes = classNames('tab-list', className, getTabsJustify(justifyTabs), getBorderPlacement(borderPlacement), `tab-list--${props.type.toLowerCase()}`);
    return <ul className={classes}>{props.children}</ul>;
};

TabList.defaultProps = {
    type: TabListType.DEFAULT,
};

export default TabList;
