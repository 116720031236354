import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getCurrentUser } from '../../common/user/UserSelectors';
import { GlobalState } from '../../rootReducer';

import AutoTransactionsView, { DispatchProps, Props } from './AutoTransactionsView';
import {
    deleteAutoTransaction,
    duplicateAutoTransaction,
    exportAutoTransactions,
    filterAutoTransactions,
    openAutoTransaction,
    searchAutoTransactions,
    setAutoTransactionsPagingOptions,
    sortAutoTransactions,
    toggleRuleActivity,
} from './AutoTransactionsViewActions';
import { selectAutoTransactionsLoadable, selectDeleteAutoTransactionLoadable, selectSearchParams } from './AutoTransactionsViewReducer';

const mapStateToProps = (state: GlobalState): Props => {
    return {
        autoTransactionsLoadable: selectAutoTransactionsLoadable(state),
        deleteAutoTransactionLoadable: selectDeleteAutoTransactionLoadable(state),
        searchParams: selectSearchParams(state),
        userData: getCurrentUser(state),
    };
};

const mapDispatchToProps: DispatchProps = {
    filterAutoTransactions,
    setAutoTransactionsPagingOptions,
    sortAutoTransactions,
    searchAutoTransactions,
    deleteAutoTransaction,
    openAutoTransaction,
    duplicateAutoTransaction,
    toggleRuleActivity,
    exportAutoTransactions,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AutoTransactionsView));
