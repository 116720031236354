import React, { useState, useEffect, useRef, ChangeEventHandler } from 'react';
import { assign, cloneDeep, debounce, findIndex, isEmpty } from 'lodash-es';
import { Form, Formik, FormikErrors, FormikProps, yupToFormErrors } from 'formik';
import { WithTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Dispatchable0, Dispatchable2 } from 'redux-dispatchers';

import { getUserSettingValue } from '../../../../common/user/userSettingUtil';
import { getEmptyUserCreation, getRoles, isRoleDisabled, toggleRole, hasRole } from '../../../../common/user/userUtils';
import { createDataId } from '../../../../common/utils/dataId';
import FormikField from '../../../../common/utils/FormikField';
import { DropdownMenu, DropdownMenuItem } from '../../../../components/DropdownMenu/DropdownMenu';
import { ICONS } from '../../../../components/Icon/Icon';
import { Button, ButtonContentAlign, ButtonIconPlacement, ButtonType } from '../../../../components/Buttons/Button';
import { ModalType, ModalScrollableFormWithFixedHeaderAndSubmit } from '../../../../components/Modal/Modal';
import { TextInputField, TextInputType } from '../../../../components/TextInput/TextInput';
import InputErrorMessage from '../../../../components/InputErrorMessage/InputErrorMessage';
import { GroupMemberDTO, MemberRoleDTO, UserCreationDTO, UserSetting, UserSettingName, UserDTO, SystemConfigurationDTO, Currency } from '../../../../services/types/ApiTypes';
import { getPersonalCodeTooltip, SUPPORTED_COUNTRY_IDS, validateEmailField, validateEmailFieldAllCompanies, validatePersonalCodeField } from './EditUserModalHelpers';
import { CalendarDatePickerField } from '../../../../components/CalendarDatePicker/CalendarDatePicker';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import { Select } from '../../../../components/Select/Select';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import { SelectOptionValue } from '../../../../components/Select/SelectOption';
import { DEFAULT_CURRENCY_SELECTION } from '../../../../common/constants/appConstants';
import CollapsibleInfoBlockWithHeader from '../../../../components/CollapsibleInfoBlockWithHeader/CollapsibleInfoBlockWithHeader';
import { getSupportedPersonalCodeCountriesWithSquareFlags, getSupportedCountriesWithSquareFlags } from '../../../login/loginHelper';
import { INTEGER_NATURAL_NUMBERS_REGEXP } from '../../../../common/utils/validators';

import './EditUserModal.scss';

export interface Props {
    isModalOpen: boolean;
    onClose: () => void;
    userToEdit?: GroupMemberDTO;

    currentCompanySettingsLoading: boolean;
    currentCompanySettings: SystemConfigurationDTO[];
    loadedCurrencies: Currency[];
}

export interface DispatchProps {
    getCurrentCompanySettings: Dispatchable0;
    saveNewUser: Dispatchable2<UserCreationDTO, boolean>;
    saveExistingUser: Dispatchable2<Partial<GroupMemberDTO>, boolean | undefined>;
    getCurrencies: Dispatchable0;
}

export interface EditUserModalFields {
    FirstName: string;
    LastName: string;
    Country: string;
    PersonalCode: string;
    Email: string;
    MembershipStart: Date;
    MembershipEnd: Date;
    MemberRoles: MemberRoleDTO[];
    MonetaryCurrency: string;
    MonetaryLimit: number;
}

export type EditUserModalProps = Props & DispatchProps & WithTranslation;

const editUserInitialValues: EditUserModalFields = {
    FirstName: undefined,
    LastName: undefined,
    Country: null,
    PersonalCode: undefined,
    Email: undefined,
    MembershipStart: new Date(),
    MembershipEnd: undefined,
    MemberRoles: [],
    MonetaryCurrency: undefined,
    MonetaryLimit: undefined,
};

export const EditUserModal = ({
    currentCompanySettingsLoading,
    currentCompanySettings,
    getCurrencies,
    getCurrentCompanySettings,
    isModalOpen,
    loadedCurrencies,
    onClose,
    t,
    saveExistingUser,
    saveNewUser,
    userToEdit,
}: EditUserModalProps) => {
    const [isMonetaryLimitUnlimited, setIsMonetaryLimitUnlimited] = useState<boolean>(true);
    const [isMonetaryLimitChanged, setIsMonetaryLimitChanged] = useState<boolean>(false);
    const [selectedMonetaryCurrency, setSelectedMonetaryCurrency] = useState<SelectOptionValue<string>>(DEFAULT_CURRENCY_SELECTION);
    const [companyDefaultCurrency, setCompanyDefaultCurrency] = useState<string>();
    const [userData, setUserData] = useState<EditUserModalFields | GroupMemberDTO>(null);
    // Country is handled separately, because UI shows translation text and flag icon representing country value
    const [userCountry, setUserCountry] = useState<SelectOptionValue<string & React.ReactElement>>({ value: '', text: '' });
    const [countryItems, setCountryItems] = useState<SelectOptionValue<string & React.ReactElement>[]>();
    const [inviteAnotherUser, setInviteAnotherUser] = useState(true);
    const firstInputRef = useRef<HTMLInputElement>(null);

    const initUserData = () => setUserData(userToEdit ? cloneDeep(userToEdit) : editUserInitialValues);

    const initCountrySelection = () => {
        // init country options for select
        const getCountriesWithFlags = userToEdit ? getSupportedCountriesWithSquareFlags : getSupportedPersonalCodeCountriesWithSquareFlags;
        const countryOptions = getCountriesWithFlags()
            .map((country) => ({
                ...country,
                text: t(country.text),
            }))
            .sort((c1, c2) => (c1.text > c2.text ? 1 : -1));
        countryOptions.push({ text: 'N/A', value: 'N/A' });
        setCountryItems(countryOptions);

        // init user country to show
        if (userToEdit?.User?.Country) {
            const countryToShow = countryOptions.find((c) => c.value === userToEdit.User.Country);
            setUserCountry(countryToShow);
        } else {
            setUserCountry({ text: 'N/A', value: 'N/A' });
        }
    };

    useEffect(() => {
        initUserData();
        initCountrySelection();
    }, [userToEdit]);

    const newUserValidationSchema = Yup.object<EditUserModalFields>().shape({
        FirstName: Yup.string()
            .ensure()
            .nullable(true)
            .required(t('view.Accounting.MandatoryField', { fieldName: t('views.groupMember.detail.First_Name') })),
        LastName: Yup.string()
            .ensure()
            .nullable(true)
            .required(t('view.Accounting.MandatoryField', { fieldName: t('views.groupMember.detail.Surname') })),
        Country: Yup.string().nullable(true),
        PersonalCode: Yup.string()
            .ensure()
            .nullable(true)
            .max(16, t('component.AutoTransaction.Error.Title.ValueTooLong'))
            .test('mandatory-personalCode', t('view.UserList.NewUserPopover.Error.EmailOrPersonalCode.MandatoryFields'), function(value) {
                if (!value && !this.parent.Email && userCountry.value !== 'N/A') {
                    return false;
                }
                return true;
            })
            .matches(/^[a-zA-Z0-9.\-/]*$/, t('views.groupMember.detail.personalCode.InvalidFormat')),
        Email: Yup.string()
            .test('mandatory-personalCode', t('view.UserList.NewUserPopover.Error.EmailOrPersonalCode.MandatoryFields'), function(value) {
                if (!value && !this.parent.PersonalCode) {
                    return false;
                }
                return true;
            })
            .email(t('views.company.detail.email.InvalidFormat')),
        MembershipStart: Yup.date().nullable(true),
        MembershipEnd: Yup.date()
            .nullable(true)
            .test('membership-end-cant-be-before-start-date', t('component.datePicker.startDateShouldBeBefore'), function(value) {
                if (value && new Date(this.parent?.MembershipStart).getTime() > new Date(value).getTime()) {
                    return false;
                }
                return true;
            }),
        MemberRoles: Yup.array<MemberRoleDTO>()
            .nullable(false)
            .required(t('view.UserList.NewUserPopover.Error.Roles.Empty')),
        MonetaryLimit: Yup.number()
            .nullable(true)
            .min(1)
            .max(999999999)
            .test('mandatory-MonetaryLimit', t('view.Accounting.MandatoryField', { fieldName: t('view.UserList.NewUserPopover.InputLabel.MonetaryLimit') }), function(value) {
                if (!isMonetaryLimitUnlimited && !value) {
                    return false;
                }
                return true;
            }),
    });

    const newUserValidationOnSubmitSchema = Yup.object<EditUserModalFields>().shape({
        PersonalCode: Yup.string().test('unique-personalCode', t('view.UserList.NewUserPopover.Error.PersonalCode.ExistsInCompany'), function(value) {
            if (!value) {
                return true;
            }
            return new Promise((resolve) => {
                validatePersonalCodeField(value, userCountry.value, resolve);
            });
        }),

        Email: Yup.string().test('unique-email', t('view.UserList.NewUserPopover.Error.Email.ExistsInCompany'), function(value) {
            if (!value) {
                return true;
            }
            return new Promise((resolve) => {
                validateEmailField(value, resolve);
            }).then((result) => {
                if (!result) {
                    return false;
                }
                if (!this.parent.PersonalCode) {
                    return true;
                }
                return new Promise((resolve) => {
                    validateEmailFieldAllCompanies(value, userCountry.value, this.parent.PersonalCode, resolve);
                }).then((r) => {
                    if (!r) {
                        return this.createError({
                            message: t('view.UserList.NewUserPopover.Error.Email.ExistsInAnotherCompany'),
                        });
                    }
                    return true;
                });
            });
        }),
    });

    const existingUserValidationSchema = Yup.object<GroupMemberDTO>().shape({
        // Country: Yup.string().nullable(true),
        MembershipStart: Yup.date().nullable(true),
        MembershipEnd: Yup.date()
            .nullable(true)
            .test('membership-end-cant-be-before-start-date', t('component.datePicker.startDateShouldBeBefore'), function(value) {
                if (value && new Date(this.parent?.MembershipStart).getTime() > new Date(value).getTime()) {
                    return false;
                }
                return true;
            }),
        MonetaryLimit: Yup.number()
            .nullable(true)
            .min(isMonetaryLimitChanged ? 1 : 0)
            .max(999999999)
            .test('mandatory-MonetaryLimit', t('view.Accounting.MandatoryField', { fieldName: t('view.UserList.NewUserPopover.InputLabel.MonetaryLimit') }), function(value) {
                if (!isMonetaryLimitUnlimited) {
                    return true;
                }
                return !(!isMonetaryLimitUnlimited && !value);
            }),
        User: Yup.object<Partial<UserDTO>>().shape(
            {
                FirstName: Yup.string()
                    .ensure()
                    .nullable(true)
                    .required(t('view.Accounting.MandatoryField', { fieldName: t('views.groupMember.detail.First_Name') })),
                LastName: Yup.string()
                    .ensure()
                    .nullable(true)
                    .required(t('view.Accounting.MandatoryField', { fieldName: t('views.groupMember.detail.Surname') })),
                // Email: Yup.string()
                //     .ensure()
                //     .nullable(true)
                //     .max(254, t('component.AutoTransaction.Error.Title.ValueTooLong'))
                //     .when('PersonalCode', {
                //         is: undefined || '',
                //         then: Yup.string()
                //             .required(t('view.Accounting.MandatoryField', { fieldName: t('views.groupMember.groupMember.E_Mail') }))
                //             .email(t('views.company.detail.email.InvalidFormat')),
                //         otherwise: Yup.string().notRequired(),
                //     }),
                // PersonalCode: Yup.string()
                //     .ensure()
                //     .nullable(true)
                //     .max(254, t('component.AutoTransaction.Error.Title.ValueTooLong'))
                //     .when('Email', {
                //         is: undefined || '' || '-',
                //         then: Yup.string().required(t('view.Accounting.MandatoryField', { fieldName: t('views.groupMember.groupMember.Personal_ID') })),
                //         otherwise: Yup.string().notRequired(),
                //     }),
            },
            // [['PersonalCode', 'Email']],
        ),
    });

    useEffect(() => {
        getCurrentCompanySettings();
    }, []);

    useEffect(() => {
        if (!currentCompanySettingsLoading && currentCompanySettings) {
            const companyCurrency = currentCompanySettings.find((s) => s.Name === 'DefaultCurrency')?.Value;
            setCompanyDefaultCurrency(companyCurrency);
            if (!companyCurrency) {
                getCurrencies();
            }
        }
    }, [currentCompanySettingsLoading, currentCompanySettings]);

    useEffect(() => {
        if (userToEdit?.MonetaryLimit) {
            setIsMonetaryLimitUnlimited(false);
        }
    }, [userToEdit]);

    useEffect(() => {
        if (loadedCurrencies && userToEdit?.MonetaryCurrency) {
            const currency = loadedCurrencies.find((c) => c.Code === userToEdit.MonetaryCurrency);
            currency && setSelectedMonetaryCurrency({ text: currency.Code, value: currency.Code });
        }
    }, [loadedCurrencies, userToEdit]);

    const closeModal = () => {
        initUserData();
        onClose();
    };

    const handleEditUserSave = (userData: EditUserModalFields) => {
        const newUser: UserCreationDTO | Partial<GroupMemberDTO> = {
            ...getEmptyUserCreation(),
            ...userData,
        };

        if (!isMonetaryLimitUnlimited) {
            newUser.MonetaryCurrency = companyDefaultCurrency || selectedMonetaryCurrency.value;
            newUser.MonetaryLimit = userData.MonetaryLimit || null;
        } else {
            newUser.MonetaryCurrency = null;
            newUser.MonetaryLimit = userData.MonetaryLimit;
        }

        if (userToEdit) {
            (newUser as GroupMemberDTO).User.Country = userCountry.value === 'N/A' ? null : userCountry.value || null;
            saveExistingUser(newUser, true);
        } else {
            const createdUser = newUser as UserCreationDTO;
            createdUser.Country = userCountry.value === 'N/A' ? null : userCountry.value || null;
            // avoid sending empty Email field
            if (isEmpty(createdUser.Email)) {
                createdUser.Email = null;
            }
            saveNewUser(createdUser, inviteAnotherUser);
        }
    };
    /* istanbul ignore next */
    const getPersonalCodeCountry = (formik: FormikProps<GroupMemberDTO>) => {
        const idCountry = getUserSettingValue(UserSettingName.ID_COUNTRY, formik.values);
        /**
         * If no userSettings does not contain Id.Country then assign estonian ID as default.
         */
        if (!idCountry) {
            return SUPPORTED_COUNTRY_IDS.EE;
        }
        return idCountry;
    };
    /* istanbul ignore next */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const getPersonalCodeAddon = (formik: FormikProps<GroupMemberDTO>) => (
        <DropdownMenu
            dataId={createDataId('dropdownMenu')}
            items={Object.keys(SUPPORTED_COUNTRY_IDS).map((country: string) => {
                return (
                    <DropdownMenuItem
                        dataId={createDataId('newUserPopover', 'personalCodeCountry', 'menuItem')}
                        key={country}
                        onClick={() => {
                            const idCountryIndex = findIndex(formik.values.UserSettings, (setting: UserSetting) => {
                                return setting.Name === UserSettingName.ID_COUNTRY;
                            });
                            const userSettings = [...formik.values.UserSettings];
                            userSettings.splice(idCountryIndex, 1, {
                                Name: UserSettingName.ID_COUNTRY,
                                Value: country,
                            });
                            formik.setFieldValue('UserSettings', userSettings, false);
                        }}
                    >
                        {country}
                    </DropdownMenuItem>
                );
            })}
        >
            <Button expand={true} contentAlign={ButtonContentAlign.CENTER} icon={ICONS.ARROW_DOWN_SMALL} iconPlacement={ButtonIconPlacement.RIGHT} buttonType={ButtonType.ICON_TEXT}>
                {getPersonalCodeCountry(formik)}
            </Button>
        </DropdownMenu>
    );

    const handleUnlimitedToggle = (formik: FormikProps<EditUserModalFields>) => {
        // init variable with default value
        let value = 1;
        if (!isMonetaryLimitUnlimited) {
            value = userData.MonetaryLimit === 0 ? 0 : null;
            setIsMonetaryLimitChanged(false);
        }
        formik.setFieldValue('MonetaryLimit', value);
        setIsMonetaryLimitUnlimited((prev) => !prev);
    };

    const handleCurrencyChange = (selectedOption: SelectOptionValue<string>) => {
        setSelectedMonetaryCurrency(selectedOption);
    };

    const handleChangeMonetaryLimitValue = (formik: FormikProps<EditUserModalFields>): ChangeEventHandler<HTMLInputElement> => (e): void => {
        const { name, value } = e.target;
        formik.setFieldValue(name, value);
        setIsMonetaryLimitChanged(formik.touched?.[name]);
    };

    const validationOnSubmit = (values: EditUserModalFields, setErrors: (errors: FormikErrors<unknown>) => void, resetForm: () => void) => {
        newUserValidationOnSubmitSchema
            .validate(values, { abortEarly: false })
            .then(() => {
                setErrors({});
                handleEditUserSave(values);
                if (!inviteAnotherUser) {
                    onClose();
                } else {
                    resetForm();
                    firstInputRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    firstInputRef.current.focus({ preventScroll: true });
                }
            })
            .catch((e) => {
                setErrors(yupToFormErrors(e));
            });
    };

    const validationOnSubmitDebounced = debounce(validationOnSubmit, 400);

    const inviteAnotherCheckBox = () => {
        return (
            <Checkbox
                className="edit-add-user__checkbox"
                data-id={createDataId('addInvoiceType', 'invoiceType', 'checkbox')}
                name="addUser.checkbox"
                value={inviteAnotherUser}
                onChange={() => {
                    setInviteAnotherUser(!inviteAnotherUser);
                }}
                label={t('view.UserList.NewUserPopover.InviteAnother')}
            />
        );
    };

    return (
        <ModalScrollableFormWithFixedHeaderAndSubmit
            isOpen={isModalOpen}
            type={ModalType.FIXED_RIGHT_MIDDLE_SCROLL}
            onClose={closeModal}
            headerText={userToEdit ? t('view.UserList.ManageUserPopover.Title') : t('view.UserList.NewUserPopover.InviteUser')}
            footerCheckBox={!userToEdit && inviteAnotherCheckBox()}
            footerButtonText={
                userToEdit ? t('view.UserList.NewUserPopover.EditUserSave') : inviteAnotherUser ? t('view.UserList.NewUserPopover.SaveAndInvite') : t('view.UserList.NewUserPopover.AddButton')
            }
            className="new-user-modal"
            dataId="newUserModal"
        >
            <Formik
                onSubmit={(values: EditUserModalFields, { setErrors, resetForm }) => {
                    if (userToEdit) {
                        handleEditUserSave(values);
                        onClose();
                    } else {
                        validationOnSubmitDebounced(values, setErrors, resetForm);
                    }
                }}
                initialValues={userData}
                enableReinitialize={true}
                validationSchema={userToEdit ? existingUserValidationSchema : newUserValidationSchema}
                validateOnBlur={true}
                validateOnChange={true}
            >
                {(formik: FormikProps<EditUserModalFields>) => (
                    <Form>
                        <div className="new-user-modal__form">
                            <CollapsibleInfoBlockWithHeader
                                isCollapsible={true}
                                dataId={createDataId('addUser', 'GeneralInfo')}
                                headerText={t('view.UserList.NewUserPopover.GroupLabel.generalInformation')}
                            >
                                <FormikField
                                    dataId={createDataId('addUser', 'firstName')}
                                    component={TextInputField}
                                    forwardRef={firstInputRef}
                                    name={userToEdit ? 'User.FirstName' : 'FirstName'}
                                    type={TextInputType.BORDERED}
                                    label={t('view.UserList.NewUserPopover.InputLabel.firstName')}
                                    onlyChangeOnBlur={true}
                                    maxLength={64}
                                />
                                <FormikField
                                    dataId={createDataId('addUser', 'lastName')}
                                    component={TextInputField}
                                    name={userToEdit ? 'User.LastName' : 'LastName'}
                                    type={TextInputType.BORDERED}
                                    label={t('view.UserList.NewUserPopover.InputLabel.lastName')}
                                    onlyChangeOnBlur={true}
                                    maxLength={64}
                                />
                            </CollapsibleInfoBlockWithHeader>

                            {/* Temporary deactivated for existing users as of https://tickets.unifiedpost.com/browse/EMR-6122 */}
                            {!userToEdit && (
                                <CollapsibleInfoBlockWithHeader
                                    isCollapsible={true}
                                    dataId={createDataId('addUser', 'LoginInfo')}
                                    headerText={t('view.UserList.NewUserPopover.GroupLabel.LoginInformation')}
                                >
                                    <div className="personal-country-code">
                                        <Select
                                            className="country-select"
                                            dataId={createDataId('addUser', 'country')}
                                            items={countryItems}
                                            value={userCountry}
                                            label={t('view.backOffice.companies.column.country')}
                                            triggerIcon={ICONS.ARROW_DOWN_SMALL}
                                            onChange={(country) => {
                                                setUserCountry(country);
                                                formik.setFieldTouched('Country', true);
                                            }}
                                            error={formik.errors['Country']}
                                        />
                                        <Tooltip content={t(getPersonalCodeTooltip(userCountry.value, userToEdit?.PersonalCodeLastValidated))} followCursor>
                                            <FormikField
                                                maxLength={16}
                                                dataId={createDataId('addUser', 'personalCode')}
                                                onlyChangeOnBlur={true}
                                                component={TextInputField}
                                                name={userToEdit ? 'User.PersonalCode' : 'PersonalCode'}
                                                type={TextInputType.BORDERED}
                                                label={t('views.global.UserProfileModal.personalCode')}
                                                disabled={(!userToEdit && userCountry.value === 'N/A') || !!userToEdit?.PersonalCodeLastValidated}
                                            />
                                        </Tooltip>
                                    </div>
                                    <FormikField
                                        dataId={createDataId('addUser', 'email')}
                                        component={TextInputField}
                                        name={userToEdit ? 'User.Email' : 'Email'}
                                        type={TextInputType.BORDERED}
                                        label={t('view.UserList.NewUserPopover.InputLabel.Email')}
                                        onlyChangeOnBlur={true}
                                        maxLength={254}
                                    />
                                </CollapsibleInfoBlockWithHeader>
                            )}
                            <CollapsibleInfoBlockWithHeader
                                isCollapsible={true}
                                dataId={createDataId('addUser', 'MembershipInformation')}
                                headerText={t('view.UserList.NewUserPopover.GroupLabel.MembershipInformation')}
                            >
                                <FormikField
                                    wrapperClass="mt-4"
                                    dataId={createDataId('addUser', 'membershipStart')}
                                    component={CalendarDatePickerField}
                                    name="MembershipStart"
                                    inputProps={assign({
                                        icon: ICONS.CALENDAR_24,
                                        isTooltipError: true,
                                        type: TextInputType.DATE_PICKER,
                                        label: t('views.groupMember.groupMember.MembershipStart'),
                                    })}
                                />
                                <FormikField
                                    wrapperClass="mt-4"
                                    dataId={createDataId('addUser', 'membershipEnd')}
                                    component={CalendarDatePickerField}
                                    name="MembershipEnd"
                                    inputProps={assign({
                                        icon: ICONS.CALENDAR_24,
                                        isTooltipError: true,
                                        type: TextInputType.DATE_PICKER,
                                        label: t('views.groupMember.groupMember.MembershipEnd'),
                                    })}
                                />
                            </CollapsibleInfoBlockWithHeader>
                            {/**
                             * Below was commented out for use in future release, because of un-implemented password reset functionality.
                             */}
                            {/*<Field*/}
                            {/*    component={TextInputField}*/}
                            {/*    name="user.personalCode"*/}
                            {/*    type={TextInputType.WITH_ADDON}*/}
                            {/*    validCharacters={/^([0-9]*)$/}*/}
                            {/*    autoComplete="new-password"*/}
                            {/*    maxLength={11}*/}
                            {/*    addonBefore={getPersonalCodeAddon(formik)}*/}
                            {/*    label={t('view.UserList.NewUserPopover.InputLabel.UserName')}*/}
                            {/*    onlyChangeOnBlur={true}*/}
                            {/*/>*/}
                        </div>
                        <CollapsibleInfoBlockWithHeader isCollapsible={true} dataId={createDataId('addUser', 'Roles')} headerText={t('view.UserList.NewUserPopover.InputLabel.Roles')}>
                            <section className="checkbox-list" data-id={createDataId('addUser', 'checkboxList')}>
                                <ul data-id={createDataId('addUser', 'checkboxListRoles')}>
                                    {getRoles().map((r, i) => (
                                        <li className="mt-2" key={i} data-id={createDataId('addUser', i, 'role')}>
                                            <Checkbox
                                                value={hasRole(r, formik.values.MemberRoles)}
                                                disabled={isRoleDisabled(r, formik.values.MemberRoles)}
                                                name={t(r.Name)}
                                                label={t(r.Name)}
                                                onChange={() => toggleRole(r, formik.values, formik.setValues)}
                                            />
                                        </li>
                                    ))}
                                </ul>
                            </section>
                            <InputErrorMessage dataId="addUser.inputError">{!!formik.touched.MemberRoles && formik.errors.MemberRoles}</InputErrorMessage>
                        </CollapsibleInfoBlockWithHeader>
                        <CollapsibleInfoBlockWithHeader isCollapsible={true} dataId={createDataId('addUser', 'MonetaryLimit')} headerText={t('view.UserList.NewUserPopover.InputLabel.MonetaryLimit')}>
                            <div className="monetary-limit">
                                <Checkbox
                                    className="monetary-limit__checkbox"
                                    data-id={createDataId('addUser', 'monetaryLimit', 'checkbox')}
                                    name="user.details.monetaryLimits.unlimited.checkbox"
                                    value={isMonetaryLimitUnlimited}
                                    onChange={() => {
                                        handleUnlimitedToggle(formik);
                                    }}
                                    label={t('views.groupMember.groupMember.monetaryLimit.unlimited')}
                                />
                            </div>
                            {!isMonetaryLimitUnlimited && (
                                <div className="monetary-limit__inputs">
                                    <FormikField
                                        dataId={createDataId('addUser', 'monetaryLimit', 'input')}
                                        wrapperClass="monetary-limit__value"
                                        component={TextInputField}
                                        addonAfter={companyDefaultCurrency || ''}
                                        type={TextInputType.CURRENCY}
                                        name="MonetaryLimit"
                                        placeholder={t('view.UserList.NewUserPopover.InputLabel.MonetaryLimit.Placeholder')}
                                        validCharacters={INTEGER_NATURAL_NUMBERS_REGEXP}
                                        maxLength={9}
                                        onChange={handleChangeMonetaryLimitValue(formik)}
                                    />
                                    {!companyDefaultCurrency && (
                                        <Select
                                            dataId={createDataId('addUser', 'monetaryLimit', 'select')}
                                            className="monetary-limit__currency"
                                            items={loadedCurrencies.map((c) => ({ value: c.Code, text: c.Code }))}
                                            menuOpenUp={true}
                                            value={selectedMonetaryCurrency}
                                            onChange={(selectedItem: SelectOptionValue<string>) => {
                                                formik.setFieldValue('MonetaryCurrency', selectedItem.value);
                                                handleCurrencyChange(selectedItem);
                                            }}
                                        />
                                    )}
                                </div>
                            )}
                        </CollapsibleInfoBlockWithHeader>
                    </Form>
                )}
            </Formik>
        </ModalScrollableFormWithFixedHeaderAndSubmit>
    );
};

export default EditUserModal;
